import * as React from "react";
import { Card, Row, Col, CardHeader, CardBody, Table } from "reactstrap";
import "./faqpage.css";
import {
  List,
  Menu,
  Grid,
  Home,
  Monitor,
  Search,
  Settings,
  Layers,
  ZoomIn,
  ZoomOut,
} from "react-feather";
import { Apps, Receipt } from "@material-ui/icons";
import TopHeader from "./TopHeader";
import { Helmet } from "react-helmet";

export default function AboutEpaper() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="canonical"
          href="https://epaper.timesgroup.com/maharashtratimes/about-epaper"
        />
      </Helmet>
      <TopHeader />
      <Row
        className=" landing-cotainer-scroll"
        style={{
          height:
            window.innerWidth > 1365
              ? window.innerHeight - 100
              : window.innerHeight - 60,
          overflowY: "auto",
          marginTop:
            window.innerWidth > 1132
              ? "110px"
              : localStorage.getItem("contentRec") === "false" &&
                window.innerWidth < 1133
              ? "60px"
              : localStorage.getItem("contentRec") === "true" &&
                window.innerWidth < 701
              ? "60px"
              : window.innerWidth < 701
              ? "60px"
              : "110px",
          fontSize: "0.9em",
          backgroundColor: "#e9ebed82",
          position: "absolute",
          fontColor: "dimgrey",
          width: "100%",
        }}
      >
        <Col md="2" xs="1"></Col>
        <Col md="8" xs="12" className="px-0 mt-3 bg-white">
          <Card className=" about-ep-card mt-2">
            <CardHeader
              className="abt-eppr-header py-1"
              style={{ maxHeight: "60px" }}
            >
              <h3> About ePaper</h3>
            </CardHeader>
            <hr />
            <CardBody style={{ fontColor: "dimgrey" }}>
              <p>
                {"The portal "}
                <a href="https://epaper.timesgroup.com">
                  https://epaper.timesgroup.com
                </a>{" "}
                is a replica of Print which gives you an access to read Times
                Group newspapers online. This is a Paid Subscription.
              </p>
              <ul className="font-weight-bold">Subscription Includes:</ul>
              <li>
                Access to read Times of India, Economic Times & Maharashtra
                Times Editions online.
              </li>

              <li>
                Articles can be shared through email, Facebook, WhatsApp &
                Twitter.
              </li>
              <li>
                It does not allow PDF of page/publication to be shared or
                downloaded.
              </li>
              <li>We do not send ePaper on email or mobile.</li>
              <li>
                Pages cannot be printed but you have an option to save
                individual articles.
              </li>
              <li>
                For any other ePaper related queries write to us on
                <a href="mailto:epaper.support@timesgroup.com">
                  {" " + " epaper.support@timesgroup.com"}
                </a>
              </li>
            </CardBody>
          </Card>
          <Card className=" about-ep-card">
            <CardBody>
              <ul className="font-weight-bold">Features available</ul>
              <li>Cross platform</li>
              <li>Search current and past editions.</li>
              <li>Share articles with friends on social media.</li>
              <li>Audio feature.</li>
              <br />
              <ul className="font-weight-bold">
                PUBLICATIONS AND EDITIONS AVAILABLE ON E-PAPER
              </ul>
              <b>TIMES OF INDIA</b> <br />
              <span class="dot_pub"></span>Ahmedabad{" "}
              <span class="dot_pub"></span> Delhi <span class="dot_pub"></span>
              Bangalore <span class="dot_pub"></span> Bhopal{" "}
              <span class="dot_pub"></span> Chandigarh{" "}
              <span class="dot_pub"></span>
              Chennai <span class="dot_pub"></span> Kochi{" "}
              <span class="dot_pub"></span> Kolkata{" "}
              <span class="dot_pub"></span> Lucknow{" "}
              <span class="dot_pub"></span> Mumbai <span class="dot_pub"></span>{" "}
              Pune
              <br />
              <b>THE ECONOMIC TIMES</b> <br />
              <span class="dot_pub"></span>
              Bangalore <span class="dot_pub"></span> Delhi{" "}
              <span class="dot_pub"></span> Kolkata
              <span class="dot_pub"></span>Mumbai
              <br />
              <b>MAHARSHTRA TIMES</b> <br />
              <span class="dot_pub"></span>
              Chhatrapati Sambhajinagar <span class="dot_pub"></span> Mumbai{" "}
              <span class="dot_pub"></span> Nagpur
              <span class="dot_pub"></span>Nashik <span class="dot_pub"></span>
              Pune
            </CardBody>
          </Card>
          <hr />
          <Card className="img-descr border-0 about-ep-card p-2 mb-1">
            <img
              width="95%"
              height="auto"
              src="/images/About_ePaper-desktop.png"
            />
          </Card>

          <Card className=" img-descr border-0 mb-3 ">
            <img width="95%" src="/images/About_ePaper-0mob.png" />
          </Card>

          <Card className=" img-descr border-0 ">
            <img width="95%" src="/images/ePaper Icons1.1.png" />
          </Card>
        </Col>
        <Col md="2" xs="1"></Col>
      </Row>
    </React.Fragment>
  );
}
