import React, { Component } from "react";

class AdsText extends Component {
  render() {
    return (
      <div className="m-2">
        <p>
          #Record Format-Exchange,Seller Account ID,Type of Account (Direct or
          Reseller),TAG ID (optional)
        </p>
        <p>#start TimesOfIndia</p>
        <p>#Updated 29/11/19</p>
        <p>#Ads.txt Times Internet Limited</p>
        <p>#Appnexus</p>
        <p>appnexus.com, 2539, DIRECT</p>
        <p>#Adex</p>
        <p>google.com, pub-2230723027927371, DIRECT, f08c47fec0942fa0</p>
        <p>#Adsense</p>
        <p>google.com, pub-2230723027927371, DIRECT</p>
        <p>#Admob</p>
        <p>google.com, pub-6132384124396613, DIRECT</p>
        <p>#BrealTime</p>
        <p>Appnexus.com, 1356, DIRECT, f5ab79cb980f11d1</p>
        <p>EMXDGT.com, 262, DIRECT, 1e1d41537f7cad7f</p>
        <p>#Index Exchange</p>
        <p>indexexchange.com, 185647, DIRECT</p>
        <p>indexexchange.com, 185648, DIRECT</p>
        <p>#Pubmatic</p>
        pubmatic.com, 23105, DIRECT
        <p>#Rubicon</p>
        rubiconproject.com, 4642, DIRECT, 0bfd66d529a55807
        <p>#Undertone</p>
        undertone.com, 1419, DIRECT appnexus.com, 2234, RESELLER openx.com,
        537153564, RESELLER, 6a698e2ec38604c6
        <p>google.com, pub-8048660860005691, DIRECT, f08c47fec0942fa0</p>
        <p>google.com, pub-4771941893327138, DIRECT, f08c47fec0942fa0</p>
        adtech.com, 11575, DIRECT
        <p>#Start Affinity</p>
        <p>#ads.txt</p>
        appnexus.com, 2007, RESELLER <br />
        contextweb.com, 561119, RESELLER
        <br />{" "}
        <p>
          google.com, pub-6314168058065736, RESELLER,
          <br /> f08c47fec0942fa0
        </p>
        adtech.com, 5359, RESELLER <br />
        aolcloud.net, 5359, RESELLER <br />
        advertising.com, 5359, RESELLER
        <br />{" "}
        <p>
          google.com, pub-2290755540215120, RESELLER,
          <br /> f08c47fec0942fa0
        </p>
        pubmatic.com, 32987, RESELLER <br />
        pubmatic.com, 158033, RESELLER,
        <br />
        5d62403b186f2ace advertising.com, 18201, RESELLER <br />
        ssp.smartyads.com, 7169, DIRECT
        <p>
          google.com, pub-5880401011681483, RESELLER,
          <br /> f08c47fec0942fa0
        </p>
        advertising.com, 25308, RESELLER <br />
        adtech.com, 10466, RESELLER
        <br /> spotxchange.com, 211738, RESELLER,
        <br /> 7842df1d2fe2db34 spotx.tv, 211738, RESELLER,
        <br /> 7842df1d2fe2db34 xapads.com, 1104, DIRECT contextweb.com, 561844,
        DIRECT, 89ff185a4c4e857c pubmatic.com, 157535, RESELLER
        <br /> pubmatic.com, 157704, RESELLER <br />
        mgid.com, 465309, DIRECT, d4c29acad76ce94f outbrain.com,
        00e277bc6d204dc8d7d69858fab20d99a8, DIRECT appnexus.com, 7597, RESELLER,
        <br /> f5ab79cb980f11d1 tremorhub.com, q017o-78mlk, RESELLER,
        <br /> 1a4e959a1b50034a teads.tv, 15429, RESELLER,
        <br />
        15a9c44f6d26cbe1 advertising.com, 26154, RESELLER <br />
        spotxchange.com, 225721, RESELLER <br />
        Freewheel.tv, 741650, RESELLER <br />
        rubiconproject.com, 17130, RESELLER,
        <br /> 0bfd66d529a55807 lkqd.net, 450, RESELLER,
        <br />
        59c49fa9598a0117 openx.com, 540393169, RESELLER,
        <br /> 6a698e2ec38604c6 spotx.tv, 238936, RESELLER,
        <br /> 7842df1d2fe2db34 spotxchange.com, 238936, RESELLER,
        <br /> 7842df1d2fe2db34 advertising.com, 28038, RESELLER
        <br /> rubiconproject.com, 19668, RESELLER,
        <br /> 0bfd66d529a55807 contextweb.com, 562145, RESELLER,
        <br /> 89ff185a4c4e857c indexexchange.com, 190856, RESELLER,
        <br />
        50b1c356f2c5c8fc pubmatic.com, 158615, RESELLER,
        <br /> 5d62403b186f2ace vidazoo.com, 1773068026, RESELLER,
        <br /> b6ada874b4d7d0b2
        <p>#End Affinity</p>
        <p>#ads.txt</p>
        appnexus.com, 2007, RESELLER <br />
        contextweb.com, 561119, RESELLER
        <br />{" "}
        <p>
          google.com, pub-6314168058065736, RESELLER,
          <br /> f08c47fec0942fa0
        </p>
        adtech.com, 5359, RESELLER <br />
        aolcloud.net, 5359, RESELLER <br />
        advertising.com, 5359, RESELLER <br />
        pubmatic.com, 32987, RESELLER <br />
        pubmatic.com, 158033, RESELLER,
        <br /> 5d62403b186f2ace advertising.com, 18201, RESELLER
        <br /> ssp.smartyads.com, 7169, DIRECT
        <p>
          google.com, pub-5880401011681483, RESELLER,
          <br /> f08c47fec0942fa0
        </p>
        <p>
          google.com, pub-8840552006567450, RESELLER,
          <br /> f08c47fec0942fa0
        </p>
        advertising.com, 25308, RESELLER <br />
        adtech.com, 10466, RESELLER
        <br /> spotxchange.com, 211738, RESELLER,
        <br /> 7842df1d2fe2db34 spotx.tv, 211738, RESELLER,
        <br /> 7842df1d2fe2db34 xapads.com, 1104, DIRECT contextweb.com, 561844,
        DIRECT, 89ff185a4c4e857c pubmatic.com, 157535, RESELLER
        <br /> pubmatic.com, 157704, RESELLER <br />
        motionspots.com, 93, DIRECT, f0220652b4aaebdc waardex.com, 498, DIRECT
        <p>#ads.txt</p>
        <p>#end TimesOfIndia</p>
      </div>
    );
  }
}

export default AdsText;
