import toastr from "toastr";
import jwtDecode from "jwt-decode";
import "toastr/build/toastr.min.css";
import JSCookie from "js-cookie";

const getCustomer = () => {
  if (!JSCookie.get("token")) return null;
  return jwtDecode(JSCookie.get("token"));
};

//Set the logged in user's SSO ticket ID in local storage
const setTicketId = (ticketId) => {
  if (typeof window !== "undefined") {
    localStorage.setItem("ssoTicketId", ticketId);
  }
};

//Get the logged in user's SSO ticket ID from local storage
const getTicketId = () => {
  if (typeof window !== "undefined") {
    if (!localStorage.getItem("ssoTicketId")) return null;
    return localStorage.getItem("ssoTicketId");
  }
};
export const displayToastr = (message, title, duration, type) => {
  toastr.options = {
    positionClass: "toast-top-right",
    timeOut: duration ?? 5000,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  };
  // if (type === "error") {
  //   toastr.warning(message, title);
  // } else {
  toastr[type](message, title);
  // }
};

export { getCustomer, getTicketId, setTicketId };
