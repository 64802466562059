import React, { useState, useContext } from "react";
import { XCircle, Grid } from "react-feather";
import { Modal, Row, Col, CardImg, Card } from "reactstrap";
import ReactModal from "react-modal";
import { AppContext } from "../contexts/AppContext";
import main_page from "./main_page.jpg";
import moment from "moment";
import defaultImg from "../assets/images/default_image.png";
import Tooltip from "@material-ui/core/Tooltip";
import "../components/datesModal.css";
import { Link, useHistory } from "react-router-dom";
import Archive from "./Archive";
import { dateArchiveEvent } from "../functions/cleverTap";

const DatesModal = (props) => {
  const history = useHistory();
  // global state
  const {
    s3Source,
    isOpenArchive,
    setIsOpenArchive,
    pageClick,
    handleDate,
    initialPublication,
    initialEdition,
    handlePublication,
    setLogoAndTheme,
    todaysDate,
    dateArray,
    dateIndexData,
    dayIndexData,
    defaultInitialPubCode,
    publicationsData,
    editionData,
    currentPage,
    currentPublication,

    handleEdition,
    logo,
    themeColor,
    mostShared,
  } = useContext(AppContext);

  let today = new Date();
  let dd = today.getDate();
  let desktopView = window.innerWidth > 700;

  let mm = today.getMonth() + 1;
  let yyyy = today.getFullYear();
  const [modal, setModal] = useState(false);
  const [isOpenDate, setisOpenDate] = useState(false);

  let minLengthDesktop = window.innerWidth > 700 && window.innerWidth < 1025;

  function toggleModalOldNewspaper() {
    setisOpenDate(!isOpenDate);
  }
  function toggleArchiveModal() {
    setIsOpenArchive(!isOpenArchive);
  }

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "May",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  let monthName = months[todaysDate?.substring(3, 5) - 1];
  return (
    <React.Fragment>
      <Tooltip title="Back issues">
        {/* {window.innerWidth > 1365 ? (
          <div className="desktop-only cursor-pointer">
            <Grid
              onClick={() => {
                toggleModalOldNewspaper();
                dateArchiveEvent(
                  "date_archive",
                  "button_click",
                  "date_archive",
                  "header"
                );
              }}
            />
            <div className="desktop-icon-name small">दिनांक</div>
          </div>
        ) : ( */}
        <div className=" cursor-pointer">
          <time
            className="icon"
            onClick={() => {
              toggleModalOldNewspaper();
              dateArchiveEvent(
                "date_archive",
                "button_click",
                "date_archive",
                "header"
              );
            }}
          >
            <strong
              style={{
                backgroundColor: themeColor,
                borderBottom: themeColor,
                boxShadow: `0 2px 0 ${themeColor}`,
              }}
            >
              {monthName}
            </strong>

            <span>{todaysDate?.substring(0, 2)}</span>
          </time>
        </div>
        {/* )} */}
      </Tooltip>

      <Modal isOpen={isOpenDate} className="desktop-oldNewspaper-modal">
        <Col className="pt-5 mt-4">
          <Row className="dateModal ">
            <Col
              xs="10"
              sm="10"
              md="6"
              lg="6"
              xl="6"
              className="mt-2"
              style={{ fontSize: window.innerWidth > 700 ? "medium" : "small" }}
            >
              जुन्या अंकांसाठी{window.innerWidth <= 425 && <br />}
              <b
                className="click-archive pl-1"
                onClick={() => {
                  dateArchiveEvent(
                    "date_archive",
                    "button_go_to_archives",
                    "date_archive",
                    "date_archive"
                  );
                  toggleModalOldNewspaper();
                  window.open("/maharashtratimes/archives");
                }}
                style={{
                  fontSize: window.innerWidth > 700 ? "medium" : "small",
                }}
              >
                अर्काइव्हला
              </b>
            </Col>
            <Col
              xs="2"
              sm="2"
              md="6"
              lg="6"
              xl="6"
              className="text-align-right pr-1 mt-2"
            >
              <XCircle
                className="close-date-modal"
                fontSize={window.innerWidth > 700 ? "large" : "medium"}
                onClick={toggleModalOldNewspaper}
              />
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" xl="12">
              <Col className=" py-1 px-0">
                <Row className="no-border  " style={{ border: "none" }}>
                  <h5 className="pl-3 font-weight-bold">आधीचे अंक</h5>
                  <Col
                    className="cursor-pointer"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  ></Col>
                </Row>
              </Col>
            </Col>
            <Col>
              <Row>
                {dateIndexData &&
                  dateIndexData.length > 0 &&
                  dateIndexData.map((item, index) => {
                    return (
                      <Col
                        md="4"
                        xl="2"
                        lg="3"
                        sm="4"
                        xs="6"
                        className="px-2 pb-2 cursor-pointer "
                        key={index + item?.Timestamp}
                        onClick={() => handleDate(item.Date)}
                      >
                        <Card
                          className="date-modal-card"
                          onClick={toggleModalOldNewspaper}
                          style={{
                            //handles maxHeight only for mirror publication
                            maxHeight:
                              initialPublication.publicationname === "Mirror" &&
                              window.innerWidth < 1200
                                ? "194px"
                                : initialPublication.publicationname ===
                                    "Mirror" && window.innerWidth > 2000
                                ? "330px"
                                : initialPublication.publicationname ===
                                    "Mirror" && window.innerWidth > 1800
                                ? "240px"
                                : initialPublication.publicationname ===
                                    "Mirror" && window.innerWidth > 1600
                                ? "220px"
                                : initialPublication.publicationname ===
                                    "Mirror" && window.innerWidth > 1200
                                ? "180px"
                                : "auto",
                          }}
                        >
                          <CardImg
                            className="date-modal-ie"
                            onError={(e) => (e.target.src = defaultImg)}
                            src={`${s3Source}/PublicationData/${
                              initialPublication?.publicationcode
                            }/${initialEdition?.editionid}/${item.Date?.split(
                              "-"
                            )
                              .reverse()
                              .join("/")}/TodaysPage/todaysthumbimage_${
                              initialEdition?.editionid
                            }.jpg?v=${item?.Timestamp}`}
                            alt={"image" + item}
                          />
                          <div
                            className=" text-white text-center"
                            style={{
                              borderRadius: "0px 0px 5px 5px",
                              position: "absolute",
                              bottom: "0",
                              left: "0",
                              width: "100%",
                              backgroundColor: themeColor,
                            }}
                          >
                            {item?.Date}
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Modal>
    </React.Fragment>
  );
};
export default DatesModal;
