import React, { useState, useContext, useEffect, useMemo } from "react";
import JSCookie from "js-cookie";
import "../../src/index.css";
import ArticleCard from "./ArticleCard";
import { AppContext } from "../contexts/AppContext";
import { useHistory } from "react-router-dom";
import axios from "axios";

import "./desktopsearchbar.css";
import moment from "moment";
import ReactArticleModal from "react-modal";
ReactArticleModal.setAppElement("#root");

export default function ArticleModal(props) {
  const history = useHistory();
  const {
    s3Source,
    defaultImg,
    imageUrl,
    setImageUrl,
    setActiveTabName,
    fit2page,
    isPopularDrawerOpen,
    isFitToHeight,
    width,
    setWidth,
    articles,
    setArticles,
    refactorHighlight,
    vw,
    setArticleViewData,
    setArticleViewName,
    setArticlesPage2,
    articlesPage2,
    widthPage2,
    setWidthPage2,
    refactorHighlightPage2,
    isPanoramaPage,
    setIsPanoramaPage,
    isPanoramaPage2,
    showLoader,
    setIsPanoramaPage2,
    mainPageLOader,
    setMainPageLoader,
    ratioMainPage,
    // currArticleIndex,
    // setCurrArticleIndex,
    updateInsight,
    setRelatedArticleData,
    setArticleViewDataLinked,
    setHasInfoZone,
    setLinkJsonURL,
    setArticleViewNameLinked,
    relatedArticleData,
    isEnableAds,
    filteredDate,
  } = useContext(AppContext);

  const [searchValue, setSearchValue] = useState({
    search: "",
    from: "",
    articleName: "",
  });
  const [advertisement, setAdvertisement] = React.useState("");
  const [fit2PageArticles, setFit2PageArticles] = useState([]);
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [isOpenArticleModal, setIsOpenArticleModal] = useState(false);
  const [currentPage, setCurrentPage] = useState({});
  const [currArticleIndex, setCurrArticleIndex] = useState(0);
  const [selectedValue, setSelectedValue] = React.useState("today");
  const [searchQuery, setSearchQuery] = useState("");
  const [posts, setPosts] = useState([]);
  const [lastSearch, setLastSearch] = useState({});
  const [dayIndexData, setDayIndexData] = useState([]);

  let desktopView = window.innerWidth > 700;
  useEffect(() => {
    setAdvertisement(props.advertisement);
  }, [props.advertisement]);
  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    setSearchValue(props.searchValue);
  }, [props.searchValue]);
  useEffect(() => {
    if (props.currentArticle && props.currentArticle.length > 0) {
      openArticleView(props.currentArticle);
      toggleArticleViewModal();
    }
    setAdvertisement(props.advertisement);
  }, [props.currentArticle]);
  // useEffect(() => {
  //   if (props.dateArray.reverse().join("-") !== JSCookie.get("mteddate")) {
  //     let url = `${s3Source}/PublicationData/${
  //       props.initialPublication.publicationcode
  //     }/${props.defaultInitialEditionName.editionid}/${props.dateArray
  //       .reverse()
  //       .join("/")}/DayIndex/${props.dateArray.join("_")}_${
  //       props.defaultInitialEditionName.editionid
  //     }.json`;
  //     axios
  //       .get(url)
  //       .then((dayIndexRes) => {
  //         console.log("dayIndexRes: ", dayIndexRes.data);
  //         setDayIndexData(dayIndexRes.data.DayIndex);
  //       })
  //       .catch((err) => {
  //         console.log("getDatesApiData err: ", err);
  //       });
  //   }
  // });
  const twoPageImage1Name = useMemo(() => {
    if (fit2page && props.currentPage.pageNumber === 1) {
      return props.currentPage.PageName;
    } else if (fit2page) {
      return props?.dayIndexData[
        props?.currentPage.pageNumber + (props.currentPage.pageNumber - 3)
      ]?.PageName;
    }
  }, [props.dayIndexData, props?.currentPage, fit2page]);

  const twoPageImage2Name = useMemo(() => {
    if (fit2page && props.currentPage.pageNumber === 1) {
      return props?.dayIndexData[props?.currentPage.pageNumber]?.PageName;
    } else if (fit2page) {
      return props?.dayIndexData[
        props?.currentPage.pageNumber + (props.currentPage.pageNumber - 3) + 1
      ]?.PageName;
    }
  }, [props.dayIndexData, props?.currentPage, fit2page]);

  useEffect(() => {
    const fetchPublications = () => {
      let path = "";
      let path_prefix = `${s3Source}/PublicationData/${props?.initialPublication.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray?.[0]}/${props?.dateArray?.[1]}/${props?.dateArray?.[2]}/PageJson/`;

      if (!fit2page) {
        path = path_prefix + props?.currentPage?.PageName + ".json";
      } else {
        path = path_prefix + twoPageImage1Name + ".json";
      }
      if (!path.includes("undefined")) {
        axios
          .get(path)
          .then((result) => {
            setTimeout(() => {
              setMainPageLoader(false);
            }, 2000);

            if (result?.data?.PageWidth > 1100) {
              setIsPanoramaPage(true);
            } else setIsPanoramaPage(false);

            setArticles(result?.data);
            setWidth(result?.data?.PageWidth);
            if (isFitToHeight === 0 || fit2page) {
              setWidth((currState) => {
                let newWidth = null;
                if (fit2page) {
                  newWidth =
                    result?.data?.PageWidth > 1100
                      ? window.innerWidth - vw(0.7)
                      : window.innerWidth / 2;
                } else {
                  let sidebarWidth = null;
                  if (window.innerWidth > 2000) {
                    sidebarWidth = isPopularDrawerOpen ? vw(18.4) : vw(0.7);
                    newWidth = window.innerWidth - sidebarWidth;
                  } else if (window.innerWidth > 1800) {
                    sidebarWidth = isPopularDrawerOpen ? vw(18.2) : vw(0.7);
                    newWidth = window.innerWidth - sidebarWidth;
                  } else if (window.innerWidth < 1366) {
                    newWidth = window.innerWidth;
                  } else if (window.innerWidth > 1365) {
                    const sidebarWidth = isPopularDrawerOpen ? 340 : vw(0.7);
                    newWidth = window.innerWidth - sidebarWidth;
                  }
                }

                refactorHighlight(newWidth, currState);

                return newWidth;
              });
            } else if (isFitToHeight !== 0) {
              setWidth((currState) => {
                // const newWidth = window.innerWidth - vw(69);
                const targetHeight = window.innerHeight - 200;

                const newWidth = ratioMainPage * targetHeight;

                refactorHighlight(newWidth, currState);
                return newWidth;
              });
            }
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      }
    };
    // fetchPublications();
  }, [props.dayIndexData]);
  const [size, setSize] = useState([0, 0]);
  const searchLink = async (path, zone) => {
    let response = [];
    await axios.get(path).then((res) => {
      if (res?.data?.linkJson?.length > 0) {
        response = res?.data?.linkJson.filter(
          (v) => v.FromZoneID === zone.ZoneId
        );
      }
    });
    return response;
  };
  useEffect(() => {
    const fetchPublicationsPage2 = () => {
      let path = "";
      let path_prefix = `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray?.[0]}/${props?.dateArray?.[1]}/${props?.dateArray?.[2]}/PageJson/`;
      path = `${path_prefix}${twoPageImage2Name}.json`;

      axios
        .get(path)
        .then((result) => {
          if (result?.data?.PageWidth > 1100) {
            setIsPanoramaPage2(true);
          } else setIsPanoramaPage2(false);

          setArticlesPage2(result?.data);
          setWidthPage2(result?.data?.PageWidth);
          if (isFitToHeight === 0 || fit2page) {
            setWidthPage2((currState) => {
              let newWidth = null;
              if (fit2page) {
                // const sidebarWidth = isPopularDrawerOpen ? vw(6) : vw(0);

                newWidth = window.innerWidth / 2;
              } else {
                const sidebarWidth = isPopularDrawerOpen ? vw(12) : vw(0.7);
                newWidth = window.innerWidth - sidebarWidth;
              }
              refactorHighlightPage2(newWidth, currState);

              return newWidth;
            });
          } else if (isFitToHeight !== 0) {
            setWidthPage2((currState) => {
              // const newWidth = window.innerWidth - vw(69);
              const targetHeight = window.innerHeight - 200;

              const newWidth = ratioMainPage * targetHeight;

              refactorHighlightPage2(newWidth, currState);
              return newWidth;
            });
          }
        })
        .catch(() => {
          setIsPanoramaPage(true);
        });
    };

    if (fit2page) fetchPublicationsPage2();
  }, [props.dayIndexData, fit2page]);

  useEffect(() => {
    if (!JSCookie.get("mtpubindex") || !JSCookie.get("mtedindex")) {
      history.push("/maharashtratimes/home");
    }
    window.addEventListener("resize", () => {
      setSize([window.innerWidth, window.innerHeight]);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setSize([window.innerWidth, window.innerHeight]);
      });
    };
  }, []);

  useEffect(() => {
    setWidth((currState) => {
      if (isFitToHeight === 0) {
        let newWidth = null;
        if (fit2page) {
          // const sidebarWidth = isPopularDrawerOpen ? vw(12) : vw(0.7);
          newWidth = !isPanoramaPage
            ? window.innerWidth / 2
            : window.innerWidth - vw(0.7);

          // newWidth = (window.innerWidth - sidebarWidth) / 2;
        } else {
          let sidebarWidth = null;
          if (window.innerWidth > 2000) {
            sidebarWidth = isPopularDrawerOpen ? vw(18.4) : vw(0.7);
            newWidth = window.innerWidth - sidebarWidth;
          } else if (window.innerWidth > 1800) {
            sidebarWidth = isPopularDrawerOpen ? vw(18.2) : vw(0.7);
            newWidth = window.innerWidth - sidebarWidth;
          } else if (window.innerWidth < 1366) {
            newWidth = window.innerWidth;
          } else if (window.innerWidth > 1365) {
            const sidebarWidth = isPopularDrawerOpen ? 340 : vw(0.7);
            newWidth = window.innerWidth - sidebarWidth;
          }
        }

        refactorHighlight(newWidth, currState);

        return newWidth;
      }
      return currState;
    });
    if (fit2page) {
      setWidthPage2((currState) => {
        if (isFitToHeight === 0) {
          let newWidth = null;
          newWidth = window.innerWidth / 2;

          refactorHighlightPage2(newWidth, currState);

          return newWidth;
        }
        return currState;
      });
    }
  }, [size, isPopularDrawerOpen, isFitToHeight, isPanoramaPage, fit2page]);
  const [isSecondPageFit2page, setIsSecondPageFit2page] = useState(false);

  const openArticle2PageView = async (
    articleName,
    isSecondPage,
    linkJsonPath,
    zone
  ) => {
    if (zone?.TagName === "Information") setHasInfoZone(true);
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${props?.initialPublication.publicationcode}/${props?.defaultInitialEditionName.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/ArticleZoneJson/`;
    setLinkJsonURL(
      linkJsonPath ??
        `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`
    );
    let pageNumber = articleName.split("_")?.[3];
    let searched = await getContinuedArticles(
      linkJsonPath ??
        `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`,
      articleName,
      path_prefix
    );
    let interchange = false;
    if (searched && Object.keys(searched).length > 0) {
      if (
        searched?.pageNumber &&
        parseInt(searched.pageNumber) < parseInt(pageNumber)
      ) {
        interchange = true;
      }
    }
    let pgname = "";
    let article = articleName.split("_");
    let last_ele = article[5];
    article.pop();
    article.pop();
    article = article.join("_") + "_" + last_ele;

    if (article === twoPageImage2Name) {
      pageNumber = twoPageImage2Name.slice(11, 14);
      pgname = twoPageImage2Name;

      setIsSecondPageFit2page(true);
    } else {
      pageNumber = twoPageImage1Name.slice(11, 14);
      pgname = twoPageImage1Name;
    }

    const dayindex = props?.dayIndexData.filter((v) => v.PageName === pgname);
    const articleIndex = dayindex[0].Articles?.findIndex(
      (article) => article.ArticleName === articleName
    );
    if (articleIndex !== -1) setCurrArticleIndex(articleIndex);
    setFit2PageArticles(dayindex[0]);

    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json`;
    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }

        response.data = array;
        const finalArticle = await getFinalArticleArray(
          response?.data,
          articleName,
          searched,
          interchange,
          true
        );
        // let location = await findLocation(response.data?.ArticleBody);
        // if (location && location.length > 0) {
        //   response.data.ArticleLocation = location.join(" ");
        //   response.data.ArticleBody = response.data?.ArticleBody.slice(
        //     location.join(" ")?.length
        //   );
        // }
        // setArticleViewData(response.data);
        // setTimeout(() => {
        //   updateInsight("view", response.data);
        // }, 1200);
      })
      .catch((err) => {
        setArticleViewData(null);
      });
  };
  const getContinuedArticles = async (path, articleName, articleJsonPath) => {
    let result = false;
    let isLinkTo = false;
    if (path) {
      await axios.get(path).then(async (res) => {
        if (res?.data?.linkJson && res?.data?.linkJson?.length > 0) {
          let filter = res.data.linkJson.filter(
            (v) => v.FromLinkArticle === articleName
          );
          if (filter.length === 0) {
            filter = res.data.linkJson.filter(
              (v) => v.ToLinkArticle === articleName
            );
            if (filter.length > 0) {
              isLinkTo = true;
            }
          }
          if (filter && filter.length > 0) {
            let findArticleLink = filter.filter(
              (v) => v.LinkType === "articleLink"
            );
            let selectedArticleLink = [];
            if (findArticleLink.length > 0) {
              selectedArticleLink = [
                findArticleLink[findArticleLink.length - 1],
              ];
            }
            // if (filter[0].LinkType === "articleLink") {
            if (selectedArticleLink?.length > 0) {
              let pageNumber =
                selectedArticleLink[0]?.ArticleLink.split("_")?.[3];

              setArticleViewNameLinked(selectedArticleLink[0]?.ArticleLink);
              await axios
                .get(
                  `${articleJsonPath}${pageNumber}/${selectedArticleLink[0]?.ArticleLink}.json`
                )
                .then(async (response) => {
                  let array = [];
                  for (let i = 0; i < response.data.length; i++) {
                    const ele = response.data[i];
                    let obj = { ...ele, SortOrder: i + 1 };
                    array.push(obj);
                  }

                  response.data = array;
                  const finalArticle = await getFinalArticleArray(
                    response?.data,
                    selectedArticleLink[0]?.ArticleLink
                  );
                  result = {
                    articleName: selectedArticleLink[0]?.ArticleLink,
                    pageNumber,
                    articleData: finalArticle,
                    isLinkTo,
                  };

                  setArticleViewDataLinked({
                    ...finalArticle.articleData[0],
                    ArticlePhotographs: finalArticle.photographs ?? [],
                  });
                  setTimeout(() => {
                    updateInsight("view", finalArticle.articleData[0]);
                  }, 1200);
                })
                .catch((err) => {
                  setArticleViewDataLinked(null);
                });
            }
          }
        }
      });
    }
    return result;
  };
  const openArticleView = async (
    articleName,
    isSecondPage = false,
    linkJsonPath,
    zone
  ) => {
    let pageNumber = articleName.split("_")?.[3];
    if (zone?.TagName === "Information") setHasInfoZone(true);
    setLinkJsonURL(
      linkJsonPath ??
        `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`
    );
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${props?.initialPublication.publicationcode}/${props?.defaultInitialEditionName.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/ArticleZoneJson/`;
    let searched = await getContinuedArticles(
      linkJsonPath ??
        `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`,
      articleName,
      path_prefix
    );
    let interchange = false;
    if (searched && Object.keys(searched).length > 0) {
      interchange = searched?.isLinkTo;
      // if (
      //   searched?.pageNumber &&
      //   parseInt(searched.pageNumber) < parseInt(pageNumber)
      // ) {
      //   interchange = true;
      // }
    }
    if (fit2page) {
      openArticle2PageView(articleName, isSecondPageFit2page);
      return;
    } else {
      const articleIndex = props?.propsArticles?.findIndex(
        (article) => article.ArticleName === articleName
      );
      if (articleIndex !== -1) setCurrArticleIndex(articleIndex);
      setIsSecondPageFit2page(false);
    }
    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json`;
    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }

        response.data = array;

        const finalArticle = await getFinalArticleArray(
          response.data,
          articleName,
          searched,
          interchange,
          true
        );
      })

      .catch((err) => {
        setArticleViewData(null);
      });
  };

  const getFinalArticleArray = async (
    data,
    articleName,
    searched,
    interchange,
    isNotFromContinued
  ) => {
    const articles = [];
    let photographs = data.filter((v) => v.TagName === "Photographs");

    if (photographs.length > 0) {
      photographs = photographs.map((ele, ind) => {
        return {
          ...ele,
          Photograph: ele?.ZoneID,
          ImageCaption: data?.filter(
            (v) =>
              v.TagName === "ImageCaption" && v.SortOrder == ele?.SortOrder + 1
          )?.[0]?.ZoneText,
        };
      });
    }
    const titles = data.filter((v) => v.TagName === "ArticleTitle");
    for (let j = 0; j < titles.length; j++) {
      const ele = titles[j];
      const sliceNum = titles.filter((v, i, a) => {
        if (i !== 0 && i === j + 1) {
          return a[i];
        }
      });
      let sliced_list = [];
      if (sliceNum.length > 0) {
        sliced_list = data.slice(ele.SortOrder - 1, sliceNum[0].SortOrder - 1);
      } else {
        sliced_list = data.slice(ele.SortOrder - 1);
      }
      articles.push(sliced_list);
    }

    if (articles.length > 0) {
      let finalArticleArray = [];
      let allowedTags = ["ArticleTitle", "ArticleBody"];
      for (let i = 0; i < articles.length; i++) {
        const sub_article = articles[i];
        let tts = "";
        let obj = {};

        for (let j = 0; j < sub_article.length; j++) {
          const ele = sub_article[j];
          if (allowedTags.includes(ele.TagName)) {
            tts += ele?.ZoneText?.split("")
              .join(" ")
              .split("<br>")
              .join(" ")
              .trim();
          }
          if (ele.TagName === "LinkTo") {
            let zoneid = ele.ZoneID.split("_");
            zoneid.pop();
            let linkedZone = await searchLink(
              `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`,
              { ZoneId: zoneid.join("_") }
            );
            const span_link = `<p id='${zoneid.join("_")}' class='${
              linkedZone?.[0]?.PageLink
            }' style='cursor:pointer;color:#0000ee;text-decoration:underline;font-weight:bold;font-family:LibreBaskerville'>${
              (!ele.ZoneText || ele.ZoneText === " ") &&
              linkedZone[0].LinkType === "pageLink"
                ? `P${Number(linkedZone[0].PageLink.split("_")[3])}`
                : ele.ZoneText?.split("").join("›")
            }</p>`;
            if (obj.ArticleBody) {
              obj["ArticleBody"] = obj?.["ArticleBody"] + `${span_link}`;
            } else {
              obj["ArticleBody"] = span_link;
            }
          } else {
            obj[ele.TagName] = obj[ele.TagName]
              ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›").trim()
              : ele?.ZoneText?.split("").join("›").trim();
          }
        }
        finalArticleArray.push({ ...obj, ArticleName: articleName, tts });
      }
      let finalArticle = {
        photographs,
        articleData: finalArticleArray,
      };

      if (isNotFromContinued) {
        if (interchange) {
          if (
            searched &&
            Object.keys(searched).length > 0 &&
            searched?.articleData?.articleData?.length > 1
          ) {
            let related = [
              ...searched?.articleData?.articleData.slice(1),
              ...finalArticle.articleData.slice(1),
            ];
            setRelatedArticleData(related);
          }
          setArticleViewName(searched?.articleName);
          let location = await findLocation(
            searched?.articleData?.articleData[0]?.ArticleBody
          );
          if (location && location.length > 0) {
            searched.articleData.articleData[0].ArticleLocation =
              location.join(" ");
            searched.articleData.articleData[0].ArticleBody =
              searched?.articleData?.articleData[0]?.ArticleBody.slice(
                location.join(" ")?.length
              );
          }

          setArticleViewData(searched?.articleData?.articleData[0]);
          setArticleViewDataLinked({
            ...finalArticle?.articleData[0],
            ArticlePhotographs: finalArticle?.photographs ?? [],
          });
          setArticleViewNameLinked(articleName);
        } else {
          setRelatedArticleData(finalArticle.articleData.slice(1));
          setArticleViewName(articleName);
          setArticleViewNameLinked(searched?.articleName);
          let location = await findLocation(
            finalArticle.articleData[0]?.ArticleBody
          );

          if (location && location.length > 0) {
            finalArticle.articleData[0].ArticleLocation = location.join(" ");

            finalArticle.articleData[0].ArticleBody =
              finalArticle.articleData[0]?.ArticleBody.slice(
                location.join(" ")?.length
              );
          }
          setArticleViewData({
            ...finalArticle.articleData[0],
            ArticlePhotographs: finalArticle?.photographs ?? [],
          });
        }
        setTimeout(() => {
          updateInsight("view", {
            ...finalArticle.articleData[0],
            ArticlePhotographs: finalArticle?.photographs ?? [],
          });
        }, 1200);
      }
      return finalArticle;
    }
    return { photographs, articleData: [] };
  };
  const findLocation = (data) => {
    const iterated = [];
    if (data && data.length > 0) {
      let split = data.split(" ").slice(0, 4);

      for (let i = 0; i < split.length; i++) {
        const ele = split[i];

        if (ele.search(":") !== -1) {
          split[i] = ele.split(":")[0] + ":";
          for (let s = 0; s < i + 1; s++) {
            const element = split[s];
            iterated.push(element);
          }
        } else {
        }
      }
    }
    return iterated;
  };
  //updated
  const handleNextArticle = async (articleName) => {
    setRelatedArticleData([]);
    setArticleViewDataLinked({});
    setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${props?.initialPublication.publicationcode}/${props?.defaultInitialEditionName.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/ArticleZoneJson/`;

    let length = fit2page
      ? fit2PageArticles.Articles.length - 1
      : props.propsArticles?.length - 1;

    if (currArticleIndex < length) {
      let array = props?.propsArticles;

      let searched = await getContinuedArticles(
        `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`,
        array[currArticleIndex + 1].ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
        // if (
        //   searched?.pageNumber &&
        //   parseInt(searched.pageNumber) < parseInt(pageNumber)
        // ) {
        //   interchange = true;
        // }
      }
      if (fit2page) {
        array = fit2PageArticles.Articles;
      }

      path = `${path_prefix}${pageNumber}/${
        array[currArticleIndex + 1].ArticleName
      }.json`;
      setArticleViewName(array[currArticleIndex + 1].ArticleName);

      setCurrArticleIndex(currArticleIndex + 1);

      axios
        .get(path)
        .then(async (response) => {
          let array = [];
          for (let i = 0; i < response.data.length; i++) {
            const ele = response.data[i];
            let obj = { ...ele, SortOrder: i + 1 };
            array.push(obj);
          }

          response.data = array;

          const finalArticle = await getFinalArticleArray(
            response.data,
            array[currArticleIndex + 1].ArticleName,
            searched,
            interchange,
            true
          );
        })
        .catch((err) => {
          console.log("handleNextArticle err: ", err);
        });
    }
  };

  const handlePrevArticle = async (articleName) => {
    setArticleViewDataLinked({});
    setRelatedArticleData([]);
    setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${props?.initialPublication.publicationcode}/${props?.defaultInitialEditionName.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/ArticleZoneJson/`;

    // let pageNumber = props.currentPage.PageName.slice(11, 14);
    let array = props.propsArticles;

    if (fit2page) {
      array = fit2PageArticles.Articles;
    }
    if (currArticleIndex > 0) {
      let searched = await getContinuedArticles(
        `${s3Source}/PublicationData/${props?.initialPublication?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`,
        array?.[currArticleIndex - 1]?.ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
        // if (
        //   searched?.pageNumber &&
        //   parseInt(searched.pageNumber) < parseInt(pageNumber)
        // ) {
        //   interchange = true;
        // }
      }

      path = `${path_prefix}${pageNumber}/${
        array[currArticleIndex - 1].ArticleName
      }.json`;
      setCurrArticleIndex(currArticleIndex - 1);
      setArticleViewName(array[currArticleIndex - 1].ArticleName);

      axios
        .get(path)
        .then(async (response) => {
          let array = [];
          for (let i = 0; i < response.data.length; i++) {
            const ele = response.data[i];
            let obj = { ...ele, SortOrder: i + 1 };
            array.push(obj);
          }

          response.data = array;
          const finalArticle = await getFinalArticleArray(
            response.data,
            array?.[currArticleIndex - 1]?.ArticleName,
            searched,
            interchange,
            true
          );
        })
        .catch((err) => {
          console.log("handlePrevArticle err: ", err);
        });
    }
  };

  useEffect(() => {
    if (props.displayArticle && props.displayArticle.length > 0) {
      openArticleView(props.displayArticle);
      toggleArticleViewModal();
      props.removeDisplayArticle();
    }
  }, [props.displayArticle]);

  const toggleArticleViewModal = () => {
    if (isOpenArticleModal) {
      setArticleViewData(null);
      props.setCurrentArticle();
    }
    setIsOpenArticleModal(!isOpenArticleModal);
  };
  useEffect(() => {
    if (!JSCookie.get("mtpubindex") || !JSCookie.get("mtedindex")) {
      history.push("/maharashtratimes/home");
    }
  }, []);
  return (
    <div>
      {desktopView ? (
        isOpenArticleModal && (
          <ReactArticleModal
            currentPage={currentPage}
            onRequestClose={() => {
              props.setAd();
              setAdvertisement("");
              toggleArticleViewModal();
            }}
            isOpen={isOpenArticleModal}
            className="mymodal"
            overlayClassName="myoverlay"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            id={`react-modal-desktop`}
          >
            <ArticleCard
              updateDayIndex={(date) => {
                toggleArticleViewModal();
                props.updateDayIndex(date);
              }}
              currArticleIndex={currArticleIndex}
              showViewOnPage={true}
              toggleArticleViewModal={toggleArticleViewModal}
              isSearch={searchValue}
              propsArticles={currentPage?.Articles ?? []}
              themeColor={props?.themeColor}
              twoPageImage1Name={twoPageImage1Name}
              twoPageImage2Name={twoPageImage2Name}
              isSecondPageFit2page={isSecondPageFit2page}
              defaultInitialPubCode={props.initialPublication}
              defaultInitialEditionName={props.defaultInitialEditionName}
              dateArray={props.dateArray}
              currentPage={currentPage}
              isOpenMobArticle={isOpenMobArticle}
              handleNextArticle={handleNextArticle}
              handlePrevArticle={handlePrevArticle}
              openArticleView={openArticleView}
              setIsOpenMobArticle={setIsOpenMobArticle}
              advertisement={advertisement && { ZoneId: advertisement }}
              setAd={() => {
                setAdvertisement("");
                let modal = document.getElementById("react-modal-desktop");
                modal.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              pageClick={() => {
                props.pageClick(
                  `${s3Source}/PublicationData/${
                    props?.initialPublication?.publicationcode
                  }/${props?.defaultInitialEditionName}/${props.dateArray.join(
                    "/"
                  )}/PageThumb/${props.currentArticle}.jpg?v=${
                    filteredDate?.Timestamp
                  }`,
                  props.currentPage.pageNumber
                );
                toggleArticleViewModal();
              }}
              dayIndexData={props.dayIndexData}
              mostShared={props.mostShared}
              closeModals={() => {
                toggleArticleViewModal();
                props.closeModals();
              }}
            />
          </ReactArticleModal>
        )
      ) : (
        <ReactArticleModal
          isOpen={isOpenArticleModal}
          className="mymodal-mob "
          overlayClassName={
            localStorage.getItem("contentRec") === "true"
              ? "myoverlay-mob"
              : "myoverlay-mob-noad"
          }
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          onRequestClose={() => {
            props.setAd();
            setAdvertisement("");
            toggleArticleViewModal();
          }}
          id={"react-modal-mobile"}
        >
          <ArticleCard
            updateDayIndex={(date) => {
              toggleArticleViewModal();
              props.updateDayIndex(date);
            }}
            currArticleIndex={currArticleIndex}
            showViewOnPage={true}
            isSearch={searchValue}
            propsArticles={[]}
            themeColor={props?.themeColor}
            toggleArticleViewModal={toggleArticleViewModal}
            twoPageImage1Name={twoPageImage1Name}
            twoPageImage2Name={twoPageImage2Name}
            defaultInitialPubCode={props.initialPublication}
            defaultInitialEditionName={props.defaultInitialEditionName}
            dateArray={props.dateArray}
            currentPage={currentPage}
            isOpenMobArticle={isOpenMobArticle}
            handleNextArticle={handleNextArticle}
            handlePrevArticle={handlePrevArticle}
            openArticleView={openArticleView}
            setIsOpenMobArticle={setIsOpenMobArticle}
            advertisement={advertisement && { ZoneId: advertisement }}
            setAd={() => {
              setAdvertisement("");
              let modal = document.getElementById("react-modal-mobile");
              modal.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            dayIndexData={props.dayIndexData}
            pageClick={() => {
              props.pageClick(
                `${s3Source}/PublicationData/${
                  props?.initialPublication?.publicationcode
                }/${props?.defaultInitialEditionName}/${props.dateArray.join(
                  "/"
                )}/PageThumb/${props.currentArticle}.jpg?v=${
                  filteredDate?.Timestamp
                }`,
                props.currentPage.pageNumber
              );
              toggleArticleViewModal();
            }}
            mostShared={props.mostShared}
            closeModals={() => {
              toggleArticleViewModal();
              props.closeModals();
            }}
          />
        </ReactArticleModal>
      )}
    </div>
  );
}
