import React, { useState, useContext } from "react";
// context
import { AppContext } from "../contexts/AppContext";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation-safe";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardImg,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  Button,
} from "reactstrap";
export default function MailModal() {
  // global state
  const { isOpenContactModal, setIsOpenContactModal } = useContext(AppContext);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [formData, setFormData] = useState({
    to: "epaper.support@timesgroup.com",
    cc: "",
    subject: "",
    body: "",
  });
  const captchaHandler = () => {
    return setVerifiedCaptcha(true);
  };

  const handleSubmitContact = (e) => {
    e.preventDefault();
    const mailData = {
      to: formData.to,
      cc: formData.cc,
      subject: formData.subject,
      body: formData.body,
    };
    if (verifiedCaptcha) {
    }
    axios
      .post(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/send-email`,
        mailData
      )

      .then((response) => {
        console.log(response.status);
        console.log(response.data.token);
      });
  };
  return (
    <AvForm onValidSubmit={handleSubmitContact}>
      <ModalHeader toggle={() => setIsOpenContactModal(!isOpenContactModal)}>
        Contact Us
      </ModalHeader>
      <ModalBody>
        <Row className="d-flex align-items-center">
          <Col md="12">
            {" "}
            <AvField
              type="email"
              className="mb-0"
              // value={}
              name="from"
              placeholder="Your Email"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  cc: e.target.value,
                });
              }}
              label="From: "
              grid={{ lg: 10, md: 10, sm: 12 }}
            />
          </Col>
        </Row>
        {/* <Row className="d-flex align-items-center">
          <Col md="3">
            <Label>
              <b>To: </b>
            </Label>
          </Col>
          <Col md="9">
            <AvField
              disabled={true}
              // type="email"
              className="mb-0"
              value={formData?.to}
              name="to"
              // onChange={(e) => {
              //   setFormData({
              //     ...formData,
              //     to: e.target.value,
              //   });
              // }}
              label="To: "
              grid={{ lg: 10, md: 10, sm: 12 }}
            />
          </Col>
        </Row> */}
        <Row className="d-flex align-items-center">
          <Col md="12">
            <AvField
              type="text"
              className="mb-0"
              name="subject"
              placeholder="Type subject"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  subject: e.target.value,
                });
              }}
              label="Subject: "
              grid={{ lg: 10, md: 10, sm: 12 }}
            />
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <AvField
              type="textarea"
              className="mb-0"
              // value={}
              rows="5"
              name="body"
              placeholder="Body goes here."
              onChange={(e) => {
                setFormData({
                  ...formData,
                  body: e.target.value,
                });
              }}
              label="Message Body: "
            />
          </Col>
        </Row>
        <Row className="my-3 mx-0">
          <ReCAPTCHA
            sitekey="6Lc8MoEeAAAAAP1OR6JsStwiB3HlCDVrVVlvuzGY"
            onChange={() => {
              captchaHandler();
            }}
          />
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <div style={{ width: "100%" }}>
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-end align-items-center"
              style={{ flexWrap: "wrap" }}
            >
              <div>
                <Button
                  color="danger"
                  onClick={() => setIsOpenContactModal(!isOpenContactModal)}
                  className="mx-2 px-4"
                >
                  Cancel
                </Button>
                <Button
                  disabled={!verifiedCaptcha ? true : false}
                  onClick={() => setIsOpenContactModal(!isOpenContactModal)}
                  color="danger"
                  type="submit"
                  className="px-4"
                >
                  Send
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </ModalFooter>
    </AvForm>
  );
}
