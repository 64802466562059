import React, { useState, useContext, useEffect, useRef } from "react";
import JSCookie from "js-cookie";
import axios from "axios";
import moment from "moment";
import "./landing-page.css";
import defaultImg from "../assets/images/default_image.png";
import { Link, Redirect, useHistory } from "react-router-dom";
import publicationJson from "../config/Publications.json";
import CookiesChoice from "./CookiesChoice";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//context
import { AppContext } from "../contexts/AppContext";
import { CardImg, Row, Col, Card } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    left: "48%",
    bottom: "31%",
    width: "fit-content",
    zIndex: 99999,
    color: "black",
    backgroundColor: "rgb(255 255 255 / 0%) !important",
  },
}));

const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
      // googletag.pubads().addEventListener("slotOnload", function (event) {
      //   var slot = event.slot;
      //   console.log(
      //     "Creative iframe for slot",
      //     slot.getSlotElementId(),
      //     "has loaded."
      //   );
      // });
      setInterval(() => {
        googletag.pubads().refresh();
      }, 30000);
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});

export default function HomePage(props) {
  const history = useHistory();
  // global state
  const {
    mainPageLOader,
    setMainPageLoader,
    getPublicationsData,
    s3Source,
    isEnableAds,
  } = useContext(AppContext);

  const classes = useStyles();

  const [isOpenPub, setIsOpenPub] = React.useState(false);
  const [isOpenEdition, setIsOpenEdition] = React.useState(false);

  const togglePublication = () => setIsOpenPub((prevState) => !prevState);
  const toggleEdition = () => setIsOpenEdition((prevState) => !prevState);

  const [publicationsData, setPublicationsData] = useState([]);

  const [initialPublication, setInitialPublication] = useState({});
  const [currentPublication, setCurrentPublication] = useState({});
  const [dateIndexData, setDateIndexData] = useState([]);
  const [todaysDate, setTodaysDate] = useState(null);
  const [dateArray, setDateArray] = useState([]);
  const [displayTodaysImage, setDisplayTodaysImage] = useState(true);
  const [editionData, setEditionsData] = useState([]);
  const [logo, setLogo] = useState();

  const [themeColor, setThemeColor] = useState();
  const [initialEdition, setInitialEdition] = useState({});
  const [dayIndexData, setDayIndexData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [favIcon, setfavIcon] = useState();
  const [todays_date, set_todays_date] = useState();
  const loading = useRef(true);

  const getData = async (pubindex, edindex) => {
    try {
      if (
        JSCookie.get("mtpubindex") !== pubindex ||
        JSCookie.get("mtedindex") !== edindex ||
        !currentPublication ||
        !initialEdition ||
        dateIndexData.length === 0
      ) {
        await setDisplayTodaysImage(false);
        var publication = 0,
          edition = 0;

        // key-value
        if (JSCookie.get("mtpubindex")) {
          publication = await JSCookie.get("mtpubindex");
        } else {
          await JSCookie.set("mtpubindex", publication, { expires: 3 });
        }
        if (JSCookie.get("mtedindex")) {
          edition = await JSCookie.get("mtedindex");
        } else {
          await JSCookie.set("mtedindex", edition, { expires: 3 });
        }
        let tempInitialPub = "";
        let tempInitialEdition = "";

        const favicon = await document.getElementById("favicon");

        tempInitialPub = publicationJson.Publications[Number(publication)];

        await setInitialPublication({ ...tempInitialPub, index: publication });
        await setCurrentPublication({ ...tempInitialPub, index: publication });

        tempInitialEdition = await publicationJson.Publications[
          Number(publication)
        ].editions[Number(edition)];
        await setInitialEdition({ ...tempInitialEdition, index: edition });

        await setPublicationsData(publicationJson.Publications); // array of publications

        await setEditionsData(
          publicationJson.Publications[Number(publication)].editions
        ); // array of editions of the await set publication
        document.title =
          publicationJson.Publications[Number(publication)]?.publicationname +
          " | e-Edition";
        await setThemeColor(
          publicationJson.Publications[Number(publication)]?.theme_color
        );
        await setLogo(tempInitialPub.logo);

        // await favicon.setAttribute("href", tempInitialPub.favicon);

        if (
          Object.keys(tempInitialPub).length > 0 &&
          Object.keys(tempInitialEdition).length > 0
        ) {
          let json = "DateIndex";
          if (process.env.REACT_APP_ENV === "PROD") {
            json = "datekey";
          }
          const result = await getDates(
            tempInitialPub,
            tempInitialEdition,
            Number(publication),
            Number(edition),
            json
          );

          let newDateIndex = [];
          if (process.env.REACT_APP_ENV !== "PROD") {
            for (let i = 0; i < result.DateIndex.length; i++) {
              const ele = result.DateIndex[i];
              newDateIndex.push({
                Date: ele,
                Timestamp: new Date().getTime(),
              });
            }
          } else {
            if (result && result.length > 0) {
              newDateIndex = result;
            }
          }

          //sort to get latest date first
          const dateIndexSorted = await newDateIndex.sort((a, b) =>
            new Date(a.Date.split("-").reverse().join("-")) <
            new Date(b.Date.split("-").reverse().join("-"))
              ? 1
              : -1
          );

          await set_todays_date(
            dateIndexSorted[0].Date.split("-").reverse().join("/")
          );
          await JSCookie.set("mteddate", dateIndexSorted[0].Date);
          await setDateIndexData(dateIndexSorted);
          await setDisplayTodaysImage(true);
        }
      }
    } catch (error) {
      console.log("error: ", error);
    }
  };
  //get dates with timestamp
  const getDates = async (
    tempInitialPub,
    tempInitialEdition,
    publication,
    edition,
    json
  ) => {
    let result = [];
    await setDisplayTodaysImage(false);

    const url = `${
      publicationJson[process.env.REACT_APP_ENV]
    }/PublicationData/${
      tempInitialPub.publicationcode ??
      publicationJson?.Publications?.[publication]?.publicationcode
    }/${
      tempInitialEdition.editionid ??
      publicationJson?.Publications?.[publication]?.editions[edition]?.editionid
    }/${json}.json`;

    await axios
      .get(url)
      .then(async (res) => {
        result = await res.data;
      })
      .catch((err) => {
        console.log("err: ", err);
      });
    return result;
  };

  useEffect(async () => {
    await getData(JSCookie.get("mtpubindex"), JSCookie.get("mtedindex"));
  }, []);

  const handlePublication = async (publication, index) => {
    // await setCurrentPublication(publication);
    await JSCookie.set("mtpubindex", index, { expires: 3 });
    await JSCookie.set("mtedindex", 0, { expires: 3 });
    await getData(index, 0);
  };

  const [slotsData] = useState([
    {
      path: `/1064661/HP_Top_Horizontal`,
      width: 728,
      height: 90,
      divId: `div-gpt-ad-1640339413339-0`,
    },
    {
      path: `/1064661/HP_1st_Right`,
      width: 300,
      height: 250,
      divId: `div-gpt-ad-1640339530434-0`,
    },
    {
      path: `/1064661/HP_2nd_Right`,
      width: 300,
      height: 250,
      divId: `div-gpt-ad-1640339614193-0`,
    },
    {
      path: `/1064661/HP_Bottom_Horizontal`,
      width: 728,
      height: 90,
      divId: `div-gpt-ad-1640339693661-0`,
    },
    {
      path: `/1064661/HP_Top_Horizontal_Mobile`,
      width: 300,
      height: 50,
      divId: `div-gpt-ad-1640855597282-0`,
    },
  ]);
  const handleEdition = async (edition, index) => {
    await JSCookie.set("mtedindex", index, { expires: 3 });
    await getData(currentPublication?.index, index);
  };

  const handleDate = async (date) => {
    await JSCookie.set("mteddate", date);
    await getData();
  };
  useEffect(() => {
    async function initializeGAds() {
      for (let i = 0; i < slotsData.length; i++) {
        const ele = slotsData[i];
        const slot = GPTAdsManager.createSlot(
          ele.path,
          ele.width,
          ele.height,
          ele.divId
        );
        await slot.display();
        // await slot.refresh();
      }
      GPTAdsManager.initializeAds(false, true);
      setTimeout(() => {
        if (window && window?.googletag?.pubads())
          window.googletag.pubads().refresh();
      }, 1000);
    }
    initializeGAds();
  }, []);

  useEffect(() => {
    if (dateIndexData && dateIndexData.length > 0) {
      set_todays_date(dateIndexData[0].Date.split("-").reverse().join("/"));
    }
  }, [dateIndexData]);

  return (
    <React.Fragment>
      {localStorage.getItem("continentCode") === "EU" &&
        (!localStorage.getItem("webAnalytics") ||
          !localStorage.getItem("contentRec")) && <CookiesChoice {...props} />}
      <div className="landing-page-main-div">
        <div>
          <div
            style={{
              position: "sticky",
              top: "0px",
              zIndex: "999",
            }}
          >
            <Card
              className="header-card-landing border-0 bg-white "
              style={{
                height: "90px",
              }}
            >
              <Row className="bg-white pb-1">
                <Col
                  xs="12"
                  sm="12"
                  md="3"
                  lg="3"
                  xl="3"
                  style={{
                    marginTop: "5px",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <img
                    className=""
                    width="100px"
                    height="85px"
                    src="/logo/timesgroup.png"
                    alt="toi"
                  />
                </Col>

                <Col
                  className="top-ad-landing"
                  xs="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  {localStorage.getItem("contentRec") === "true" && (
                    <div>
                      <div id="div-gpt-ad-1640339413339-0" />
                    </div>
                  )}
                </Col>
                <Col md="3" lg="3" xl="3"></Col>
              </Row>
            </Card>
            <div className="border-landing"></div>
          </div>
          <div
            className="landing-cotainer-scroll"
            style={{ height: window.innerHeight - 100 }}
          >
            {/* ad only for screen less than 700px */}
            {localStorage.getItem("contentRec") === "true" && (
              <Row className="mob-ad-only  justify-content-center">
                <Col style={{ display: "flex", justifyContent: "center" }}>
                  <Card className="border-0">
                    <div>
                      <div id="div-gpt-ad-1640855597282-0" />
                    </div>
                  </Card>
                </Col>
              </Row>
            )}
            <Row className=" px-4 centre-ipad justify-content-center">
              <Col
                className="ipad-1024"
                xs="0"
                sm="0"
                md="1"
                lg="2"
                xl="2"
              ></Col>

              <Col xs="12" sm="12" md="8" lg="8" xl="8">
                <Row id="phone-view-wrap" className="justify-content-center">
                  <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                    className="landing-pub-col px-3"
                  >
                    <div className="form-group">
                      <label
                        className=" toi-label-span"
                        // for="exampleFormControlSelect1"
                      >
                        Publication
                      </label>
                      <Dropdown isOpen={isOpenPub} toggle={togglePublication}>
                        <DropdownToggle
                          className="chaoice-inner d-flex justify-content-between align-items-center"
                          caret
                        >
                          {currentPublication
                            ? currentPublication.publicationname
                            : " The Times Of India"}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: (data) => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: "180px",
                                    padding: "0.25rem 0.2rem",
                                    fontSize: "14px",
                                  },
                                };
                              },
                            },
                          }}
                        >
                          {publicationsData.map((publication, index) => (
                            <DropdownItem
                              key={publication.id}
                              className="dropdown-landing"
                              value={publication?.publicationname}
                              // className="dropdown-item"
                              onClick={async () => {
                                await handlePublication(publication, index);
                              }}
                            >
                              {publication.publicationname}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>

                      <label className=" toi-label-span mt-2">
                        Edition/City
                      </label>
                      <Dropdown
                        className="btn_drop_down"
                        isOpen={isOpenEdition}
                        toggle={toggleEdition}
                      >
                        <DropdownToggle
                          caret
                          className="chaoice-inner d-flex justify-content-between align-items-center"
                        >
                          {initialEdition?.editionname ??
                            currentPublication?.editions?.[
                              Number(JSCookie.get("mtedindex"))
                            ]?.editionname}
                        </DropdownToggle>
                        <DropdownMenu
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: (data) => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: "auto",
                                    maxHeight: "180px",
                                    padding: "0.25rem 0.2rem",
                                    fontSize: "14px",
                                  },
                                };
                              },
                            },
                          }}
                        >
                          {editionData.map((item, index) => (
                            <DropdownItem
                              key={item.editionname + index}
                              // value={item.editionname}
                              className="dropdown-landing"
                              onClick={() => {
                                setInitialEdition(item);
                                handleEdition(item, index);
                              }}
                            >
                              {item.editionname}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>

                    <div
                      className="toi-submit-control btn pl-0"
                      onClick={async () => {
                        await JSCookie.remove("fromArchive");
                        await getPublicationsData(true);
                        window.location.assign(
                          `${
                            window.location.origin
                          }/maharashtratimes/${initialPublication?.publicationname
                            ?.toLowerCase()
                            .split(" ")
                            .join("-")}/${initialEdition?.editionname
                            ?.toLowerCase()
                            .split(" ")
                            .join("-")}`
                        );
                        // history.push(
                        //   `/${initialPublication?.publicationname
                        //     ?.toLowerCase()
                        //     .split(" ")
                        //     .join("-")}/${initialEdition?.editionname
                        //     ?.toLowerCase()
                        //     .split(" ")
                        //     .join("-")}`
                        // );
                      }}
                    >
                      <div className="toi-submit-anchor toi-anchor">Submit</div>
                    </div>
                  </Col>
                  <Col xs="8" sm="5" md="5" lg="5" xl="4" className="pr-0 pl-4">
                    <div>
                      {displayTodaysImage && (
                        <div
                          onClick={async () => {
                            await JSCookie.remove("fromArchive");
                            await getPublicationsData(true);
                            window.location.assign(
                              `${
                                window.location.origin
                              }/maharashtratimes/${initialPublication?.publicationname
                                ?.toLowerCase()
                                .split(" ")
                                .join("-")}/${initialEdition?.editionname
                                ?.toLowerCase()
                                .split(" ")
                                .join("-")}`
                            );
                            // history.push(
                            //   `/${initialPublication?.publicationname
                            //     ?.toLowerCase()
                            //     .split(" ")
                            //     .join("-")}/${initialEdition?.editionname
                            //     ?.toLowerCase()
                            //     .split(" ")
                            //     .join("-")}`
                            // );
                          }}
                        >
                          <img
                            onError={(e) => (e.target.src = defaultImg)}
                            className="ext-image"
                            src={
                              displayTodaysImage &&
                              dateIndexData?.length > 0 &&
                              initialPublication &&
                              initialEdition &&
                              `${s3Source}/PublicationData/${
                                initialPublication?.publicationcode
                              }/${
                                initialEdition?.editionid ??
                                publicationJson?.Publications?.[
                                  Number(JSCookie.get("mtpubindex"))
                                ]?.editions[JSCookie.get("mtedindex")]
                                  ?.editionid
                              }/${
                                JSCookie.get("mteddate")
                                  ? JSCookie.get("mteddate")
                                      ?.split("-")
                                      ?.reverse()
                                      .join("/")
                                  : todays_date
                              }/TodaysPage/todaysthumbimage_${
                                initialEdition?.editionid ??
                                publicationJson?.Publications?.[
                                  Number(JSCookie.get("mtpubindex"))
                                ]?.editions[JSCookie.get("mtedindex")]
                                  ?.editionid
                              }.jpg?v=${dateIndexData[0]?.Timestamp}`
                            }
                            alt={
                              "image" + dateIndexData?.length > 0
                                ? dateIndexData[0].Date.split("-")
                                    .reverse()
                                    .join("/")
                                : todays_date
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col
                    className="pl-4 not-ipad"
                    md="4"
                    lg="4"
                    xl="4"
                    style={{ position: "inherit" }}
                  >
                    {localStorage.getItem("contentRec") === "true" && (
                      <div className=" landing-ad desktop-only">
                        <div id="div-gpt-ad-1640339530434-0" />
                      </div>
                    )}

                    {localStorage.getItem("contentRec") === "true" && (
                      <div className="pt-1 landing-ad desktop-only ">
                        <div
                          // path={`/1064661/HP_2nd_Right`}
                          // width={300}
                          // height={250}
                          id="div-gpt-ad-1640339614193-0"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <footer className="footer">
              <div className="toi-links">
                <Link
                  to="/maharashtratimes/about"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  About Us
                </Link>
                <span className="toi-sep px-1"></span>
                <Link
                  to="/advertise"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  Advertise with Us
                </Link>
                <span className="toi-sep px-1"></span>
                <Link
                  to="/maharashtratimes/terms"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  Terms of Use
                </Link>
                <span className="toi-sep px-1"></span>
                <Link
                  to="/maharashtratimes/privacy"
                  title="The Times of India — Mumbai"
                  target="_self"
                >
                  Privacy and Cookie Policy
                </Link>
              </div>
              <div className="toi-copyright">
                Copyright © 2020 Bennett Coleman &amp; Co. Ltd. • All rights
                reserved.
              </div>
            </footer>
            {localStorage.getItem("contentRec") === "true" && (
              <Row className="justify-content-center landing-bottom-ad ">
                <div className=" desktop-only ">
                  <div
                    // path={`/1064661/HP_Bottom_Horizontal`}
                    // width={728}
                    // height={90}
                    id="div-gpt-ad-1640339693661-0"
                  />
                </div>
              </Row>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
