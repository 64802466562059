import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ReceiptOutlinedIcon from "@material-ui/icons/ReceiptOutlined";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { Link } from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import "../components/sidebar.css";
import JSCookie from "js-cookie";
import { makeStyles } from "@material-ui/core/styles";
import { AlignJustify, Layout } from "react-feather";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "22px",
    justifyContent: "center",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function ToggleMenu3(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <div>
        <Layout
          className="feather-29"
          color="white" // for dark mode
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />
        <Popper
          open={props.open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={props.handleClose}>
                  <MenuList
                    autoFocusItem={props.open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    style={{ marginTop: "21px" }}
                  >
                    <Link
                      to={`/${props.publicationsData[
                        JSCookie.get("mtpubindex")
                      ]?.publicationname
                        ?.split(" ")
                        .join("-")
                        .toLowerCase()}/${props.publicationsData[
                        JSCookie.get("mtpubindex")
                      ]?.editions[JSCookie.get("mtedindex")]?.editionname
                        ?.split(" ")
                        .join("-")
                        .toLowerCase()}`}
                    >
                      <MenuItem style={{ fontSize: "13px", fontWeight: "500" }}>
                        <ReceiptOutlinedIcon className="text-icon-color " />
                        <p className="menu-name"> PRINT REPLICA</p>
                      </MenuItem>
                    </Link>
                    <Link to={`/gallery-view`}>
                      <MenuItem style={{ fontSize: "13px", fontWeight: "500" }}>
                        <PhotoLibraryIcon
                          size="small"
                          className="text-icon-color"
                        />

                        <p className="menu-name"> GALLERY</p>
                      </MenuItem>
                    </Link>
                    <Link to={`/digest-view`}>
                      <MenuItem style={{ fontSize: "13px", fontWeight: "500" }}>
                        <AlignJustify color="#2b78a2" />
                        <p className="menu-name"> DIGEST</p>
                      </MenuItem>
                    </Link>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
