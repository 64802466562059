import React, { useState, useContext } from "react";
// context
import { AppContext } from "../contexts/AppContext";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation-safe";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardImg,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardTitle,
  Button,
} from "reactstrap";
export default function MailModalFeedBack(props) {
  // global state
  const { isOpenFeedBackModal, setIsOpenFeedBackModal } =
    useContext(AppContext);
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [formData, setFormData] = useState({
    to: props?.to,
    cc: "",
    subject: props?.subject,
    body: "",
  });
  const captchaHandler = () => {
    return setVerifiedCaptcha(true);
  };
  const submitHandler = () => {
    if (verifiedCaptcha) {
      console.log("verified");
      console.log("formData", formData);
    } else {
      console.log("fill form");
    }
  };

  const handleSubmitContact = (e) => {
    e.preventDefault();
    const mailData = {
      to: formData.to,
      cc: formData.cc,
      subject: formData.subject,
      body: formData.body,
    };
    if (verifiedCaptcha) {
    }
    axios
      .post(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/epaper/send-email`,
        mailData
      )
      .then((response) => {
        console.log(response.status);
        console.log(response.data.token);
      });
  };
  return (
    <AvForm onValidSubmit={handleSubmitContact}>
      <ModalHeader toggle={() => setIsOpenFeedBackModal(!isOpenFeedBackModal)}>
        Editorial Feedback
      </ModalHeader>
      <ModalBody className="py-1">
        <Row className="d-flex align-items-center">
          <Col md="3">
            <Label>
              <b>From: </b>
            </Label>
          </Col>
          <Col md="9">
            <AvField
              type="email"
              className="mb-0"
              // value={}
              name="from"
              placeholder="From Email"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  cc: e.target.value,
                });
              }}
            />
          </Col>
        </Row>

        <Row className="d-flex align-items-center">
          <Col md="3">
            <Label>
              <b>Subject: </b>
            </Label>
          </Col>
          <Col md="9">
            <AvField
              disabled={true}
              type="text"
              className="mb-0"
              value={props?.subject}
              name="subject"
              placeholder="Type subject"
              // onChange={(e) => {
              //   setFormData({
              //     ...formData,
              //     subject: e.target.value,
              //   });
              // }}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md="4">
            <Label>
              <b>Message Body: </b>
            </Label>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <AvField
              type="textarea"
              className="mb-0"
              // value={}
              rows="5"
              name="body"
              placeholder="Body goes here."
              onChange={(e) => {
                setFormData({
                  ...formData,
                  body: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row className="my-3 mx-0">
          <ReCAPTCHA
            // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // for testing only
            sitekey="6Lc8MoEeAAAAAP1OR6JsStwiB3HlCDVrVVlvuzGY"
            onChange={() => {
              captchaHandler();
            }}
          />
        </Row>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-start">
        <div style={{ width: "100%" }}>
          <Row>
            <Col
              md="12"
              className="d-flex justify-content-end align-items-center"
              style={{ flexWrap: "wrap" }}
            >
              <div>
                <Button
                  color="danger"
                  onClick={() => {
                    setIsOpenFeedBackModal(false);
                  }}
                  className="mx-2 px-4"
                >
                  Cancel
                </Button>
                <Button
                  disabled={!verifiedCaptcha ? true : false}
                  onClick={() => {
                    setIsOpenFeedBackModal(false);
                  }}
                  color="danger"
                  type="submit"
                  className="px-4"
                >
                  Send
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </ModalFooter>
    </AvForm>
  );
}
