import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import defaultImg from "../assets/images/default_image.png";
import publicationJson from "../config/Publications.json";
import "./content-modal.css";
import Backdrop from "@material-ui/core/Backdrop";
import times_group_loader from "../assets/images/the_times_group_loader.png";
import {
  Row,
  Container,
  Col,
  Card,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import axios from "axios";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Modal from "react-modal";
import "../Fonts/LibreBaskerville-Regular.ttf";
import ReactModal from "react-modal";
import { ZoomOut, ZoomIn, X } from "react-feather";
// context
import { AppContext } from "../contexts/AppContext";
import Loader from "./LoaderScreen";
import ArticleCard from "./ArticleCard";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ReactDOM from "react-dom";
import ReactSwipe from "react-swipe";
import { useSwipeable } from "react-swipeable";
import JSCookie from "js-cookie";
import ArrowKeysReact from "arrow-keys-react";
import { isIOS, isAndroid } from "react-device-detect";
import topBannerLp from "../assets/images/1000x200Banner1.jpeg";
import topBannerLpMob from "../assets/images/300x50Banner1.jpeg";
import {
  articleEvents,
  bpnSubscribe,
  pageEvents,
} from "../functions/cleverTap";
import { pushNotifications } from "../functions/BPN";
import Message from "./Message";
ReactModal.setAppElement("#root");

export default function ContentModal(props) {
  // global state
  const {
    isPopularDrawerOpen,
    isFitToHeight,
    width,
    setWidth,
    articles,
    setArticles,
    refactorHighlight,
    vw,
    toggleArticleViewModal,
    isOpenArticleModal,
    setArticleViewData,
    setRelatedArticleData,
    setArticleViewName,
    fit2page,
    setArticlesPage2,
    articlesPage2,
    widthPage2,
    setWidthPage2,
    refactorHighlightPage2,
    isPanoramaPage,
    setIsPanoramaPage,
    isPanoramaPage2,
    setIsPanoramaPage2,
    mainPageLOader,
    setMainPageLoader,
    s3Source,
    currArticleIndex,
    setCurrArticleIndex,
    updateInsight,
    setArticleViewDataLinked,
    setArticleViewNameLinked,
    setLinkJsonURL,
    setHasInfoZone,
    hasInfoZone,
    ratioMainPage,
    setRatioMainPage,
    setFit2pagesArray,
    loading,
    publicationsData,
    initialPublication,
    initialEdition,
    dateArray,
    dateIndexData,
    dayIndexData,
    displayArticle,
    setDisplayArticle,
    logo,
    isSearch,
    themeColor,
    propsArticles,
    setPropsArticles,
    mostShared,
    setIsLandingPage,
    pageClick,
    currentPage,
    filteredDate,
    linkJsonData,
    setLoading,
    setIsPopularDrawerOpen,
    setZoom,
    zoom,
    updateCookies,
    checkHeight,
    setCheckHeight,
    nextPage,
    isEnableAds,
    isOpenUpdate,
    setIsOpenUpdate,
    isMessageVisible,
    setIsMessageVisible,
    messageTop,
    setMessageTop,
    messageOpacity,
    setMessageOpacity,
    ft1Duration,
  } = useContext(AppContext);

  let desktopView = window.innerWidth > 700;
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: 999,
      backgroundColor: "rgba(255,255,255,0.5)",
      position: "fixed",
      top: desktopView ? 150 : 50,
      left: 0,
      backdropFilter: "blur(1px)",
      bottom: desktopView ? 0 : 48,
      right: window.innerWidth > 1365 ? (isPopularDrawerOpen ? 325 : 30) : 0,
    },
    backdropMainPage: {
      zIndex: 999999999,
      color: "black",
      backgroundColor: "white",
    },
  }));

  const classes = useStyles();
  const [advertisement, setAdvertisement] = React.useState("");
  const [size, setSize] = useState([0, 0]);
  // const [currentPage, setCurrentPage] = useState({});
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [isImageLoaded, setImageLoaded] = useState(false);
  const [isImageLoadedPage2, setImageLoadedPage2] = useState(false);
  const [isSecondPageFit2page, setIsSecondPageFit2page] = useState(false);
  const [fit2PageArticles, setFit2PageArticles] = useState([]);
  const [isScrolling, setIsScrolling] = useState(false);
  const [infoZoneText, setInfoZoneText] = useState("");
  const [isInfo, setIsInfo] = useState(false);

  useEffect(() => {
    setImageLoaded(false);
  }, [currentPage]);

  useEffect(() => {
    if (isImageLoaded && Object.keys(nextPage)?.length > 0) {
      if (
        initialPublication?.publicationcode &&
        initialEdition?.editionid &&
        dateArray?.length > 0 &&
        nextPage?.PageName
      ) {
        axios
          .get(
            `${s3Source}/PublicationData/${
              initialPublication?.publicationcode
            }/${initialEdition?.editionid}/${dateArray?.join("/")}/Page/${
              nextPage?.PageName
            }.jpg?v=${filteredDate?.Timestamp ?? new Date().getTime()}`
          )
          .then((res) => {
            console.log("next page loaded");
          });
      }
    }
  }, [isImageLoaded]);

  useEffect(async () => {
    if (window.innerWidth > 700 && window.innerWidth < 1336) {
      setIsPopularDrawerOpen(false);
    }
    await updateCookies();
    props.history.push(
      `/maharashtratimes/${publicationJson?.Publications?.[
        JSCookie.get("mtpubindex")
      ]?.publicationname
        ?.split(" ")
        .join("-")
        .toLowerCase()}/${publicationJson?.Publications?.[
        JSCookie.get("mtpubindex")
      ]?.editions[JSCookie.get("mtedindex")]?.editionname
        ?.split(" ")
        .join("-")
        .toLowerCase()}`
    );
  }, []);
  // useEffect(() => {
  //   let parsed = JSON.parse(JSON.stringify(currentPage));

  //   setCurrentPage(parsed);
  // }, [currentPage]);

  const twoPageImage1Name = useMemo(() => {
    if (fit2page && currentPage.pageNumber === 1) {
      return currentPage.PageName;
    } else if (fit2page) {
      return dayIndexData[currentPage.pageNumber + (currentPage.pageNumber - 3)]
        ?.PageName;
    }
  }, [dayIndexData, currentPage, fit2page]);
  useEffect(() => {
    const getPairedPages = (dayindex) => {
      let array = [];
      for (let i = 1; i < dayindex.length; i++) {
        const ele = dayindex[i];
        if (i % 2 !== 0) {
          array[array.length] = { first: ele };
        } else {
          array[array.length - 1] = { ...array[array.length - 1], second: ele };
        }
      }
      array.unshift({ first: dayindex[0] });

      setFit2pagesArray(array);
    };
    if (dayIndexData && dayIndexData?.length > 1) {
      getPairedPages(dayIndexData);
    }
  }, [dayIndexData]);

  const twoPageImage2Name = useMemo(() => {
    if (fit2page && currentPage.pageNumber === 1) {
      return dayIndexData[currentPage.pageNumber]?.PageName;
    } else if (fit2page) {
      return dayIndexData[
        currentPage.pageNumber + (currentPage.pageNumber - 3) + 1
      ]?.PageName;
    }
  }, [dayIndexData, currentPage, fit2page]);

  useEffect(() => {
    const fetchPublications = () => {
      setArticles({});
      setImagePath("");
      let path = "";
      let path_prefix = `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${dateArray?.[0]}/${dateArray?.[1]}/${dateArray?.[2]}/PageJson/`;

      let pagename = currentPage?.PageName
        ? Number(currentPage.PageName.split("_")[3]) == 1 &&
          dayIndexData?.length > 0
          ? dayIndexData.filter((v) =>
              v.PageName.includes(`${currentPage.PageName.slice(0, 10)}_001`)
            )?.[0]?.PageName
          : currentPage?.PageName
        : dayIndexData?.[0]?.PageName;
      if (!fit2page) {
        path = path_prefix + pagename + ".json?v=" + filteredDate?.Timestamp;
      } else {
        path =
          path_prefix +
          twoPageImage1Name +
          ".json?v=" +
          filteredDate?.Timestamp;
      }
      if (!path.includes("undefined")) {
        axios
          .get(path)
          .then((result) => {
            getPage(fit2page ? twoPageImage1Name : currentPage?.PageName);
            setTimeout(() => {
              setMainPageLoader(false);
            }, 2000);
            setRatioMainPage(
              Number(result?.data?.PageWidth) / Number(result?.data?.PageHeight)
            );
            if (result?.data?.PageWidth > 1100) {
              setIsPanoramaPage(true);
            } else setIsPanoramaPage(false);

            setArticles(result?.data);
            setWidth(result?.data?.PageWidth);
            setPropsArticles(result?.data?.PageContent);
            if (isFitToHeight === 0 || fit2page) {
              setWidth((currState) => {
                let newWidth = null;
                if (fit2page) {
                  newWidth =
                    result?.data?.PageWidth > 1100
                      ? window.innerWidth - vw(0.7)
                      : window.innerWidth / 2;
                } else {
                  let sidebarWidth = null;
                  if (window.innerWidth > 2000) {
                    sidebarWidth = isPopularDrawerOpen ? vw(18.4) : vw(0.7);
                    newWidth = window.innerWidth - sidebarWidth;
                  } else if (window.innerWidth > 1800) {
                    sidebarWidth = isPopularDrawerOpen ? vw(18.2) : vw(0.7);
                    newWidth = window.innerWidth - sidebarWidth;
                  } else if (window.innerWidth < 1366) {
                    newWidth = window.innerWidth;
                  } else if (window.innerWidth > 1365) {
                    const sidebarWidth = isPopularDrawerOpen ? 340 : vw(0.7);
                    newWidth = window.innerWidth - sidebarWidth;
                  }
                }

                refactorHighlight(newWidth, currState);

                return newWidth;
              });
            } else if (isFitToHeight !== 0) {
              setWidth((currState) => {
                // const newWidth = window.innerWidth - vw(69);
                const targetHeight = window.innerHeight - 200;

                const newWidth = ratioMainPage * targetHeight;

                refactorHighlight(newWidth, currState);
                return newWidth;
              });
            }
            setImageLoaded(true);
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          })
          .catch((err) => {
            setLoading(false);
            console.log("err: ", err);
          });
      }
    };
    fetchPublications();
  }, [currentPage.pageNumber, dayIndexData]);

  useEffect(() => {
    const fetchPublicationsPage2 = () => {
      setImage2Path("");
      let path = "";
      let path_prefix = `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition?.editionid}/${dateArray?.[0]}/${dateArray?.[1]}/${dateArray?.[2]}/PageJson/`;
      path = `${path_prefix}${twoPageImage2Name}.json?v=${filteredDate?.Timestamp}`;
      if (!path.includes("undefined")) {
        axios
          .get(path)
          .then(async (result) => {
            getPage(twoPageImage2Name, true);
            if (result?.data?.PageWidth > 1100) {
              setIsPanoramaPage2(true);
            } else setIsPanoramaPage2(false);
            setArticlesPage2(result?.data);
            setWidthPage2(result?.data?.PageWidth);
            setPropsArticles(result?.data?.PageContent);
            if (isFitToHeight === 0 || fit2page) {
              setWidthPage2(async (currState) => {
                let newWidth = null;
                if (fit2page) {
                  // const sidebarWidth = isPopularDrawerOpen ? vw(6) : vw(0);

                  newWidth = window.innerWidth / 2;
                } else {
                  const sidebarWidth = isPopularDrawerOpen ? vw(12) : vw(0.7);
                  newWidth = window.innerWidth - sidebarWidth;
                }
                refactorHighlightPage2(newWidth, currState);

                return newWidth;
              });
            } else if (isFitToHeight !== 0) {
              setWidthPage2(async (currState) => {
                // const newWidth = window.innerWidth - vw(69);
                const targetHeight = window.innerHeight - 200;

                const newWidth = ratioMainPage * targetHeight;

                refactorHighlightPage2(newWidth, currState);
                return newWidth;
              });
            }
            setTimeout(() => {
              setLoading(false);
            }, 1500);
          })
          .catch(() => {
            setLoading(false);
            setIsPanoramaPage(true);
          });
      }
    };

    if (fit2page) fetchPublicationsPage2();
  }, [dayIndexData, fit2page, currentPage?.pageNumber]);

  useEffect(() => {
    if (!JSCookie.get("mtpubindex") || !JSCookie.get("mtedindex")) {
      props.history.push("/maharashtratimes/home");
    }

    window.addEventListener("resize", () => {
      setSize([window.innerWidth, window.innerHeight]);
    });
    setIsLandingPage(false);
    JSCookie.set("isLanding", false);

    return () => {
      window.removeEventListener("resize", () => {
        setSize([window.innerWidth, window.innerHeight]);
      });
    };
  }, []);
  // useEffect(() => {
  //   const loadGAScript = () => {
  //     if (
  //       window &&
  //       document &&
  //       Object.keys(initialPublication)?.length > 0 &&
  //       Object.keys(initialEdition)?.length > 0
  //     ) {
  //       // An array of IDs I want to load on the same page(s) at the same time
  //       var googleIds = [
  //         initialPublication?.trackingId,
  //         initialEdition?.trackingId,
  //       ];

  //       // Setting dataLayer & gtag to window because I'm using a custom code text field in a tag management system
  //       window.dataLayer = window.dataLayer || [];
  //       window.gtag =
  //         window.gtag ||
  //         function () {
  //           window.dataLayer.push(arguments);
  //         };
  //       window.gtag("js", new Date());

  //       // Flag used to ensure script only set with first ID, and rest of IDs are pushed to dataLayer
  //       var gtagScriptExists = false;

  //       // ID validation regex. Only tested with AW-*, but DC & UA are also valid prefixes
  //       var validIdStructure = new RegExp(/(AW|DC|UA)-[0-9]{8,}(-[0-9]{1,})?/);

  //       // Push IDs into dataLayer and set initial gtag/js?id= script to page using first ID in googleIds array
  //       for (let i = 0; i < googleIds.length; i++) {
  //         var gtagId = googleIds[i];

  //         // Validate that the ID being passed isn't a big weirdo
  //         var idIsValid = true;
  //         // typeof gtagId === "string" && gtagId.match(validIdStructure);

  //         if (idIsValid) {
  //           window.gtag("config", gtagId, {
  //             page_title:
  //               i === 0
  //                 ? initialPublication?.publicationcode
  //                 : initialEdition?.editionid,
  //             page_path: `/${initialPublication.publicationname
  //               .toLowerCase()
  //               .split(" ")
  //               .join("-")}/${initialEdition?.editionname
  //               .toLowerCase()
  //               .split(" ")
  //               .join("-")}`,
  //             send_page_view: false,
  //             page_location: window.location.origin,
  //           });
  //           // if (localStorage.getItem("webAnalytics") === "true") {
  //           //   // //pageview
  //           //   window.gtag("event", "page_view", {
  //           //     page_title:
  //           //       i === 0
  //           //         ? initialPublication?.publicationcode
  //           //         : initialEdition?.editionid,
  //           //     page_location: window.location.origin,
  //           //     page_path: `/${initialPublication.publicationname
  //           //       .toLowerCase()
  //           //       .split(" ")
  //           //       .join("-")}/${initialEdition?.editionname
  //           //       .toLowerCase()
  //           //       .split(" ")
  //           //       .join("-")}`,
  //           //     send_to: gtagId,
  //           //   });
  //           // }
  //           // NOTE: gtag script only needs to be set to page once, but each gtag('config', <ID>) that's pushed to the dataLayer will add subsequent gtag/js?id=<ID> scripts to the page
  //           // if (!gtagScriptExists) {
  //           //   // Set initial gtag/js?id=<first ID> script to <head>
  //           //   var script = document.createElement("script");
  //           //   script.type = "text/javascript";
  //           //   script.async = true;
  //           //   if (i == 0) script.src = "//www.googletagmanager.com/gtag/js"; //?id=" + gtagId;
  //           //   document.getElementsByTagName("head")[0].appendChild(script);

  //           //   // Update gtag/js?id= script status flag so this initialization script is only set for the first ID, and not all the IDs in the array
  //           //   // gtagScriptExists = true;
  //           // }
  //         }
  //       }
  //     }
  //   };
  //   loadGAScript();
  // }, [initialPublication, initialEdition]);
  useEffect(() => {
    setWidth((currState) => {
      if (isFitToHeight === 0) {
        let newWidth = null;
        if (fit2page) {
          // const sidebarWidth = isPopularDrawerOpen ? vw(12) : vw(0.7);
          newWidth = !isPanoramaPage
            ? window.innerWidth / 2
            : window.innerWidth - vw(0.7);

          // newWidth = (window.innerWidth - sidebarWidth) / 2;
        } else {
          let sidebarWidth = null;
          if (window.innerWidth > 2000) {
            sidebarWidth = isPopularDrawerOpen ? vw(18.4) : vw(0.7);
            newWidth = window.innerWidth - sidebarWidth;
          } else if (window.innerWidth > 1800) {
            sidebarWidth = isPopularDrawerOpen ? vw(18.2) : vw(0.7);
            newWidth = window.innerWidth - sidebarWidth;
          } else if (window.innerWidth < 1366) {
            newWidth = window.innerWidth;
          } else if (window.innerWidth > 1365) {
            const sidebarWidth = isPopularDrawerOpen ? 340 : vw(0.7);
            newWidth = window.innerWidth - sidebarWidth;
          }
        }

        refactorHighlight(newWidth, currState);

        return newWidth;
      } else if (isFitToHeight > 0) {
        const targetHeight = window.innerHeight - 200;

        const newWidth = ratioMainPage * targetHeight;
        refactorHighlight(newWidth, currState);
        return newWidth;
      }
      return currState;
    });
    if (fit2page) {
      setWidthPage2((currState) => {
        if (isFitToHeight === 0) {
          let newWidth = null;
          newWidth = window.innerWidth / 2;

          refactorHighlightPage2(newWidth, currState);

          return newWidth;
        }
        return currState;
      });
    }
  }, [
    size,
    isPopularDrawerOpen,
    isFitToHeight,
    isPanoramaPage,
    fit2page,
    ratioMainPage,
  ]);

  const openArticle2PageView = async (
    articleName,
    isSecondPage,
    linkJsonPath,
    zone
  ) => {
    if (zone?.TagName === "Information") setHasInfoZone(true);
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication?.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;
    setLinkJsonURL(
      linkJsonPath ??
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray?.join("/")}/LinkJson/${dateArray[2]}_${dateArray[1]}_${
          dateArray[0]
        }_${initialEdition?.editionid}.json?v=${filteredDate?.Timestamp}`
    );
    let pageNumber = articleName.split("_")?.[3];
    let searched = await getContinuedArticles(articleName, path_prefix);
    let interchange = false;
    if (searched && Object.keys(searched).length > 0) {
      if (
        searched?.pageNumber &&
        parseInt(searched.pageNumber) < parseInt(pageNumber)
      ) {
        interchange = true;
      }
    }
    let pgname = "";
    let article = articleName.split("_");
    let last_ele = article[5];
    article.pop();
    article.pop();
    article = article.join("_") + "_" + last_ele;

    if (article === twoPageImage2Name) {
      pageNumber = twoPageImage2Name.slice(11, 14);
      pgname = twoPageImage2Name;

      setIsSecondPageFit2page(true);
    } else {
      pageNumber = twoPageImage1Name.slice(11, 14);
      pgname = twoPageImage1Name;
    }

    const dayindex = dayIndexData.filter((v) => v.PageName === pgname);
    const articleIndex = dayindex[0].Articles?.findIndex(
      (article) => article.ArticleName === articleName
    );
    if (articleIndex !== -1) setCurrArticleIndex(articleIndex);
    setFit2PageArticles(dayindex[0]);

    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json?v=${filteredDate?.Timestamp}`;
    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }

        response.data = array;
        const finalArticle = await getFinalArticleArray(
          response?.data,
          articleName,
          searched,
          interchange,
          true
        );
        // let location = await findLocation(response.data?.ArticleBody);
        // if (location && location.length > 0) {
        //   response.data.ArticleLocation = location.join(" ");
        //   response.data.ArticleBody = response.data?.ArticleBody.slice(
        //     location.join(" ")?.length
        //   );
        // }
        // setArticleViewData(response.data);
        // setTimeout(() => {
        //   updateInsight("view", response.data);
        // }, 1200);
      })
      .catch((err) => {
        setArticleViewData(null);
      });
  };
  const getContinuedArticles = async (articleName, articleJsonPath) => {
    let result = false;
    let isLinkTo = false;
    if (linkJsonData?.linkJson) {
      if (linkJsonData?.linkJson && linkJsonData?.linkJson?.length > 0) {
        let filter = linkJsonData.linkJson.filter(
          (v) => v.FromLinkArticle === articleName
        );
        if (filter.length === 0) {
          filter = linkJsonData.linkJson.filter(
            (v) => v.ToLinkArticle === articleName
          );
          if (filter.length > 0) {
            isLinkTo = true;
          }
        }
        if (filter && filter.length > 0) {
          let findArticleLink = filter.filter(
            (v) => v.LinkType === "articleLink"
          );
          let selectedArticleLink = [];
          if (findArticleLink.length > 0) {
            selectedArticleLink = [findArticleLink[findArticleLink.length - 1]];
          }
          // if (filter[0].LinkType === "articleLink") {
          if (selectedArticleLink?.length > 0) {
            let pageNumber =
              selectedArticleLink[0]?.ArticleLink.split("_")?.[3];

            setArticleViewNameLinked(selectedArticleLink[0]?.ArticleLink);
            await axios
              .get(
                `${articleJsonPath}${pageNumber}/${selectedArticleLink[0]?.ArticleLink}.json?v=${filteredDate?.Timestamp}`
              )
              .then(async (response) => {
                let array = [];
                for (let i = 0; i < response.data.length; i++) {
                  const ele = response.data[i];
                  let obj = { ...ele, SortOrder: i + 1 };
                  array.push(obj);
                }

                response.data = array;

                const finalArticle = await getFinalArticleArray(
                  response?.data,
                  selectedArticleLink[0]?.ArticleLink
                );
                result = {
                  articleName: selectedArticleLink[0]?.ArticleLink,
                  pageNumber,
                  articleData: finalArticle,
                  isLinkTo,
                };
                setArticleViewDataLinked({
                  ...finalArticle.articleData[0],
                  ArticlePhotographs: finalArticle.photographs ?? [],
                });
                setTimeout(() => {
                  updateInsight("view", finalArticle.articleData[0]);
                }, 1200);
              })
              .catch((err) => {
                setArticleViewDataLinked(null);
              });
          }
        }
      }
    }
    return result;
  };
  const openArticleView = async (
    articleName,
    isSecondPage = false,
    linkJsonPath,
    zone
  ) => {
    let pageNumber = articleName.split("_")?.[3];
    if (zone?.TagName === "Information") setHasInfoZone(true);
    setLinkJsonURL(
      linkJsonPath ??
        `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${
          initialEdition?.editionid
        }/${dateArray?.join("/")}/LinkJson/${dateArray[2]}_${dateArray[1]}_${
          dateArray[0]
        }_${initialEdition?.editionid}.json?v=${filteredDate?.Timestamp}`
    );
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication?.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;
    let searched = await getContinuedArticles(articleName, path_prefix);

    let interchange = false;
    if (searched && Object.keys(searched).length > 0) {
      interchange = searched?.isLinkTo;
    }
    if (fit2page) {
      openArticle2PageView(articleName, isSecondPageFit2page);
      return;
    } else {
      let arrayToUse = articles.PageContent;

      if (!articles.PageContent) {
        arrayToUse = propsArticles;
      }
      const articleIndex = arrayToUse?.findIndex(
        (article) => article.ArticleName === articleName
      );
      if (articleIndex !== -1) setCurrArticleIndex(articleIndex);
      setIsSecondPageFit2page(false);
    }
    setArticleViewName(articleName);

    path = `${path_prefix}${pageNumber}/${articleName}.json?v=${filteredDate?.Timestamp}`;

    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }

        response.data = array;
        // console.log("array: ", array);
        // const filterZoneUrl = array.filter((word) => word.TagName === "URL");
        // console.log("filterZoneUrl: ", filterZoneUrl);
        // setInfoZoneText(filterZoneUrl[0].ZoneText);
        // console.log("array: ", array);
        const finalArticle = await getFinalArticleArray(
          response.data,
          articleName,
          searched,
          interchange,
          true
        );
      })

      .catch((err) => {
        setArticleViewData(null);
      });
  };
  const openInfoUrl = async (passedArticleName) => {
    let pageNumber = passedArticleName.split("_")?.[3];

    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication?.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;

    path = `${path_prefix}${pageNumber}/${passedArticleName}.json?v=${filteredDate?.Timestamp}`;

    axios
      .get(path)
      .then(async (response) => {
        let array = [];
        for (let i = 0; i < response.data.length; i++) {
          const ele = response.data[i];
          let obj = { ...ele, SortOrder: i + 1 };
          array.push(obj);
        }

        const filterZoneUrl = array.filter((word) => word.TagName === "URL");

        if (filterZoneUrl.length > 0)
          window.open(
            filterZoneUrl[0].ZoneText?.replace(/<\/?span[^>]*>/g, ""),
            "_blank"
          );
      })

      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const getFinalArticleArray = async (
    data,
    articleName,
    searched,
    interchange,
    isNotFromContinued
  ) => {
    try {
      const articles = [];
      let photographs = data.filter((v) => v.TagName === "Photographs");

      if (photographs.length > 0) {
        photographs = photographs.map((ele, ind) => {
          return {
            ...ele,
            Photograph: ele?.ZoneID,
            ImageCaption: data?.filter(
              (v) =>
                v.TagName === "ImageCaption" &&
                v.SortOrder == ele?.SortOrder + 1
            )?.[0]?.ZoneText,
          };
        });
      }
      let hasInformationTag = false;
      const titles = await data.filter((v) => v.TagName === "ArticleTitle");
      for (let j = 0; j < titles.length; j++) {
        const ele = titles[j];

        const sliceNum = titles.filter((v, i, a) => {
          if (i !== 0 && i === j + 1) {
            return a[i];
          }
        });
        let sliced_list = [];
        if (sliceNum.length > 0) {
          sliced_list = data.slice(
            ele.SortOrder - 1,
            sliceNum[0].SortOrder - 1
          );
        } else {
          sliced_list = data.slice(ele.SortOrder - 1);
        }
        let checkForBlurb = data.filter(
          (v) => v.SortOrder === ele.SortOrder - 1
        );
        if (
          checkForBlurb &&
          checkForBlurb.length > 0 &&
          checkForBlurb?.[0].TagName === "Blurb"
        ) {
          sliced_list.unshift(checkForBlurb?.[0]);
        }
        articles.push(sliced_list);
      }

      if (articles.length > 0) {
        let finalArticleArray = [];
        let allowedTags = ["ArticleTitle", "ArticleBody"];
        for (let i = 0; i < articles.length; i++) {
          const sub_article = articles[i];
          let tts = "";
          let obj = {};

          for (let j = 0; j < sub_article.length; j++) {
            const ele = sub_article[j];

            if (allowedTags.includes(ele.TagName)) {
              tts += ele?.ZoneText?.split("")
                .join(" ")
                .split("<br>")
                .join(" ")
                .split("<b>")
                .join(" ")
                .split("<p>")
                .join(" ")
                .split("</p>")
                .join(" ")
                .split("</b>")
                .join(" ")
                ?.split("<br>`<br>")
                .join("₹")
                .split("<br>`")
                .join("₹")
                .split("`<br>")
                .join("₹")
                .split("`")
                .join("₹")
                .split("<br>¤<br>")
                .join("€")
                .split("<br>¤")
                .join("€")
                .split("¤<br>")
                .join("€")
                .split("¤")
                .join("€")
                ?.split("<br>")
                ?.join("<br/>")
                .split("&lt")
                .join("<")
                .split("&gt;")
                .join(">")
                .split("")
                .join("›");
            }
            if (ele.TagName === "Information") {
              setHasInfoZone(true);
              hasInformationTag = true;
            }
            if (ele.TagName === "LinkTo") {
              let zoneid = ele.ZoneID.split("_");
              zoneid.pop();
              let linkedZone = await searchLink(
                `${s3Source}/PublicationData/${
                  initialPublication?.publicationcode
                }/${initialEdition?.editionid}/${dateArray?.join(
                  "/"
                )}/LinkJson/${dateArray[2]}_${dateArray[1]}_${dateArray[0]}_${
                  initialEdition?.editionid
                }.json?v=${filteredDate?.Timestamp}`,
                { ZoneId: zoneid.join("_") }
              );
              const span_link = `<p id='${zoneid.join("_")}' class='${
                linkedZone?.[0]?.PageLink
              }' style='cursor:pointer;color:#0000ee;text-decoration:underline;font-weight:bold;font-family:LibreBaskerville'>${
                (!ele.ZoneText || ele.ZoneText === " ") &&
                linkedZone[0].LinkType === "pageLink"
                  ? `P${Number(linkedZone[0].PageLink.split("_")[3])}`
                  : ele.ZoneText?.split("").join("›")
              }</p>`;
              if (obj.ArticleBody) {
                obj["ArticleBody"] = obj?.["ArticleBody"] + `${span_link}`;
              } else {
                obj["ArticleBody"] = span_link;
              }
            } else if (ele.TagName === "ArticleBody") {
              obj[ele.TagName] = obj[ele.TagName]
                ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›")
                : ele?.ZoneText?.split("").join("›") + "googleAdDivHere";
            } else {
              obj[ele.TagName] = obj[ele.TagName]
                ? obj[ele.TagName] + ele?.ZoneText?.split("").join("›").trim()
                : ele?.ZoneText?.split("").join("›").trim();
            }
          }

          finalArticleArray.push({ ...obj, ArticleName: articleName, tts });
        }
        let finalArticle = {
          photographs,
          articleData: finalArticleArray,
        };

        if (isNotFromContinued) {
          if (interchange) {
            if (
              searched &&
              Object.keys(searched).length > 0 &&
              searched?.articleData?.articleData?.length > 1
            ) {
              let related = [
                ...searched?.articleData?.articleData.slice(1),
                ...finalArticle.articleData.slice(1),
              ];
              setRelatedArticleData(related);
            }
            await setArticleViewName(searched?.articleName);
            if (
              !hasInformationTag &&
              searched?.articleData?.articleData[0]?.ArticleBody &&
              !searched?.articleData?.articleData[0]?.ArticleBody?.slice(
                0,
                7
              ) === "<p id='"
            ) {
              let location = await findLocation(
                searched?.articleData?.articleData[0]?.ArticleBody
              );
              if (location && location.length > 0) {
                searched.articleData.articleData[0].ArticleLocation =
                  location.join(" ");
                searched.articleData.articleData[0].ArticleBody =
                  searched?.articleData?.articleData[0]?.ArticleBody?.slice(
                    location.join(" ")?.length
                  );
              }
            }
            setArticleViewData(searched?.articleData?.articleData[0]);
            setArticleViewDataLinked({
              ...finalArticle?.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
            await setArticleViewNameLinked(articleName);
          } else {
            setRelatedArticleData(finalArticle.articleData.slice(1));
            await setArticleViewName(articleName);
            await setArticleViewNameLinked(searched?.articleName);

            if (
              !hasInformationTag &&
              finalArticle.articleData[0]?.ArticleBody &&
              !finalArticle.articleData[0]?.ArticleBody.slice(0, 7) ===
                "<p id='"
            ) {
              let location = await findLocation(
                finalArticle.articleData[0]?.ArticleBody
              );

              if (location && location.length > 0) {
                finalArticle.articleData[0].ArticleLocation =
                  location.join(" ");

                finalArticle.articleData[0].ArticleBody =
                  finalArticle.articleData[0]?.ArticleBody.slice(
                    location.join(" ")?.length
                  );
              }
            }
            setArticleViewData({
              ...finalArticle.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
          }
          setTimeout(() => {
            updateInsight("view", {
              ...finalArticle.articleData[0],
              ArticlePhotographs: finalArticle?.photographs ?? [],
            });
          }, 1200);
        }
        return finalArticle;
      }

      return { photographs, articleData: [] };
    } catch (error) {
      console.log("getFinalArticleArray error: ", error);
    }
  };
  const findLocation = (data) => {
    const iterated = [];
    if (data && data.length > 0) {
      let split = data.split(" ").slice(0, 4);

      for (let i = 0; i < split.length; i++) {
        const ele = split[i];

        if (ele.search(":") !== -1) {
          split[i] = ele.split(":")[0] + ":";
          for (let s = 0; s < i + 1; s++) {
            const element = split[s];
            iterated.push(element);
          }
        } else {
        }
      }
    }
    return iterated;
  };
  //updated
  const handleNextArticle = async (articleName) => {
    await setAdvertisement("");
    await setRelatedArticleData([]);
    await setArticleViewDataLinked({});
    await setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication?.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;

    let length = fit2page
      ? fit2PageArticles?.Articles?.length - 1
      : articles?.PageContent?.length - 1 ?? propsArticles?.length - 1;

    if (currArticleIndex < length) {
      let array = articles?.PageContent ?? propsArticles;

      let searched = await getContinuedArticles(
        array[currArticleIndex + 1].ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
      }
      if (fit2page) {
        array = fit2PageArticles.Articles;
      }

      path = `${path_prefix}${pageNumber}/${
        array[currArticleIndex + 1].ArticleName
      }.json?v=${filteredDate?.Timestamp}`;

      let newArticleName = array[currArticleIndex + 1].ArticleName;

      let isAdv = false;
      if (
        array?.[currArticleIndex + 1].Zones?.[0].TagName === "Advertisement"
      ) {
        isAdv = true;
        await setAdvertisement(array?.[currArticleIndex + 1].Zones?.[0]);
        await setRelatedArticleData([]);
        await setArticleViewDataLinked({});
        await setArticleViewData({});
      }
      setCurrArticleIndex(currArticleIndex + 1);
      if (!isAdv) {
        setArticleViewName(array[currArticleIndex + 1].ArticleName);

        if (!path.includes("undefined")) {
          axios
            .get(path)
            .then(async (response) => {
              let array = [];
              for (let i = 0; i < response.data.length; i++) {
                const ele = response.data[i];
                let obj = { ...ele, SortOrder: i + 1 };
                array.push(obj);
              }

              response.data = array;

              const finalArticle = await getFinalArticleArray(
                response.data,
                newArticleName,
                searched,
                interchange,
                true
              );
            })
            .catch((err) => {
              console.log("handleNextArticle err: ", err);
            });
        }
      }
    }
  };
  const handlePrevArticle = async (articleName) => {
    await setAdvertisement("");
    await setRelatedArticleData([]);
    await setArticleViewDataLinked({});
    await setHasInfoZone(false);
    let pageNumber = articleName.split("_")?.[3];
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${
      initialPublication?.publicationcode
    }/${initialEdition.editionid}/${dateArray?.join("/")}/ArticleZoneJson/`;

    // let pageNumber = currentPage.PageName.slice(11, 14);
    let array = propsArticles ?? articles?.PageContent;

    if (fit2page) {
      array = fit2PageArticles.Articles;
    }
    if (currArticleIndex > 0) {
      let searched = await getContinuedArticles(
        array?.[currArticleIndex - 1]?.ArticleName,
        path_prefix
      );
      let interchange = false;
      if (searched && Object.keys(searched).length > 0) {
        interchange = searched?.isLinkTo;
      }

      path = `${path_prefix}${pageNumber}/${
        array?.[currArticleIndex - 1]?.ArticleName
      }.json?v=${filteredDate?.Timestamp}`;

      let newArticleName = array?.[currArticleIndex - 1]?.ArticleName;

      let isAdv = false;
      if (
        array?.[currArticleIndex - 1].Zones?.[0].TagName === "Advertisement"
      ) {
        isAdv = true;
        await setAdvertisement(array?.[currArticleIndex - 1].Zones?.[0]);
        await setRelatedArticleData([]);
        await setArticleViewDataLinked({});
        await setArticleViewData({});
      }
      setCurrArticleIndex(currArticleIndex - 1);
      if (!isAdv) {
        setArticleViewName(newArticleName);

        if (!path.includes("undefined"))
          axios
            .get(path)
            .then(async (response) => {
              let array = [];
              for (let i = 0; i < response.data.length; i++) {
                const ele = response.data[i];
                let obj = { ...ele, SortOrder: i + 1 };
                array.push(obj);
              }

              response.data = array;
              const finalArticle = await getFinalArticleArray(
                response.data,
                newArticleName,
                searched,
                interchange,
                true
              );
            })
            .catch((err) => {
              console.log("handlePrevArticle err: ", err);
            });
      }
    }
  };

  const contextMenuZoomInOut = (zoomBy) => {
    setWidth((currState) => {
      const newWidth = currState + zoomBy;
      refactorHighlight(newWidth, currState);
      return newWidth;
    });
    if (fit2page) {
      setWidthPage2((currState) => {
        const newWidth2 = currState + zoomBy;
        refactorHighlightPage2(newWidth2, currState);
        return newWidth2;
      });
    }
  };
  useEffect(() => {
    if (displayArticle && displayArticle.length > 0) {
      if (fit2page) {
        openArticle2PageView(displayArticle, false);
      } else {
        openArticleView(displayArticle, false);
      }
      toggleArticleViewModal();
      setDisplayArticle("");
    }
  }, [displayArticle]);

  useEffect(() => {
    if (isOpenUpdate) {
      bpnSubscribe(
        "bpn_subscribe",
        "bottombanner_BPN_served",
        "bpn_subscribe",
        "bottombanner_BPN_served"
      );
    }
  }, [isOpenUpdate]);

  const searchLink = async (path, zone) => {
    let response = [];

    if (linkJsonData?.linkJson?.length > 0) {
      response = linkJsonData?.linkJson.filter(
        (v) => v.FromZoneID === zone.ZoneId
      );
    }

    return response;
  };
  const previousHovered = useRef("");
  const hoverCoordinates = useRef({});

  const onHover = (e, zone, article) => {
    let linkZone = document.getElementById(
      "zones_" + zone.Y + zone.X + zone.H + zone.W + "_2"
    );
    let ele = document.getElementById(
      "PageContent1" + article.ArticleName + "_1"
    ).children;
    previousHovered.current = ele;
    if (ele && ele.length > 0)
      for (let i = 0; i < ele.length; i++) {
        const element = ele[i];
        if (element.id !== linkZone.id)
          element.style.background = "transparent";
      }
  };
  const onRemoveHover = (e, zone, article) => {
    let ele = previousHovered.current;
    if (ele && ele.length > 0)
      for (let i = 0; i < ele.length; i++) {
        const element = ele[i];
        element.style.removeProperty("background");
      }
    previousHovered.current = "";
  };
  function nextMainPage() {
    window?.epaperZoom?.resetTransform();
    if (currentPage?.pageNumber !== props?.pages?.length && zoom !== true) {
      setCheckHeight(false);

      let filter = dayIndexData?.filter((v, i, a) => {
        return i === currentPage?.pageNumber;
      });
      if (filter && filter?.length > 0) {
        let path = `${s3Source}/PublicationData/${initialPublication}/${initialEdition}/${dateArray?.join(
          "/"
        )}/PageThumb/${filter[0].PageName}.jpg?v=${filteredDate?.Timestamp}`;

        pageClick(path, currentPage?.pageNumber);
      }
    }
  }
  function prevMainPage() {
    window?.epaperZoom?.resetTransform();
    if (currentPage?.pageNumber !== 1 && zoom !== true) {
      setCheckHeight(false);

      let filter = dayIndexData.filter((v, i, a) => {
        return i === currentPage?.pageNumber - 2;
      });
      if (filter && filter.length > 0) {
        let path = `${s3Source}/PublicationData/${initialPublication}/${initialEdition}/${dateArray?.join(
          "/"
        )}/PageThumb/${filter[0].PageName}.jpg?v=${filteredDate?.Timestamp}`;
        pageClick(path, currentPage?.pageNumber - 2);
      }
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      pageEvents("page_next", "page_next", "page_next", "page_next");
      nextMainPage();
    },
    onSwipedRight: () => {
      pageEvents(
        "page_previous",
        "page_previous",
        "page_previous",
        "page_previous"
      );
      prevMainPage();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  ArrowKeysReact.config({
    left: () => {
      prevMainPage();
    },
    right: () => {
      nextMainPage();
    },
  });

  const [imagePath, setImagePath] = React.useState("");
  const [image2Path, setImage2Path] = React.useState("");
  const getPage = async (pagename, image2) => {
    let path = `${s3Source}/PublicationData/${
      initialPublication?.publicationcode
    }/${initialEdition?.editionid}/${dateArray?.join(
      "/"
    )}/Page/${pagename}.jpg?v=${filteredDate?.Timestamp}`;
    if (image2) {
      setImage2Path(path);
    } else {
      setImagePath(path);
    }
  };

  return (
    <React.Fragment>
      {/* <Backdrop className={classes.backdropMainPage} open={mainPageLOader}>
        <Row className="justify-content-center">
          <img
            // onError={(e) => (e.target.src = defaultImg)}
            width="250px"
            // src={logo}
            src={times_group_loader}
            alt="loader"
          />
        </Row>
      </Backdrop> */}
      <Helmet>
        <title>{`Maharashtra Times ${initialEdition?.editionname} epaper: Read Maharashtra Times ${initialEdition?.editionname} Online Marathi Epaper | ${initialEdition?.editionnameMar} महाराष्ट्र टाइम्स ई-पेपर | ${initialEdition?.editionname} Marathi Newspaper Online`}</title>
        <meta
          name="description"
          content={`Maharashtra Times ${initialEdition?.editionname} ePaper: Read your Latest, Daily ${initialEdition?.editionname} ePaper News on Politics, Crime, Sports in Marathi, Entertainment updates and latest breaking news of ${initialEdition?.editionname} | Maharashtra Times Newspaper Online`}
        />
        <meta
          name="Keywords"
          content={`Maharashtra Times ${initialEdition?.editionname} Newspaper online, ${initialEdition?.editionname} Newspaper Online, Latest ${initialEdition?.editionname} Epaper, breaking news of ${initialEdition?.editionname}, Today's ${initialEdition?.editionname} news updates, ताज्या मराठी बातम्या, Maharashtra Times ${initialEdition?.editionname} online Marathi Epaper, ${initialEdition?.editionnameMar} महाराष्ट्र टाइम्स ई-पेपर, Maharashtra Times ${initialEdition?.editionname} Marathi News Paper, Maharashtra Times ${initialEdition?.editionname} Marathi ePaper, मराठी वृत्तपत्र, मराठी पेपर,  marathi batmya, mata epaper, mata news paper today`}
        />

        <link
          rel="canonical"
          href="https://epaper.timesgroup.com/maharashtratimes/"
        />
        <meta content={`The Times Group`} name="author" />
        <meta content={`The Times Group`} name="Publisher" />
        <meta
          content={new Date(`${dateArray}`)}
          property="article:published_time"
        />
        <meta
          property="og:site_name"
          content={`${initialPublication?.publicationcode} epaper`}
        />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:locale" content="en_IN" />
        <meta
          content={new Date(`${dateArray}`)}
          property="article:modified_time"
        />
        <meta
          property="og:title"
          content={`${initialPublication?.publicationname} epaper`}
        />
        <meta
          name="breadcrumbs"
          content={`Home>Maharashtratimes  ${initialEdition?.editionname} E-Paper`}
        />
        <meta property="og:locale" content={`hi_IN`} />
        {/* <meta property="og:description" content={dataRet?.synopsis} /> */}
        {/* <meta name="Keywords" content={keywords} /> */}

        {/* <meta name="news_keywords" content={keywords} /> */}
        <meta property="og:image" content={`/${logo}`} />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
      </Helmet>

      {/* <Backdrop className={classes.backdrop} open={loading}>
  <Loader />
</Backdrop> */}
      {window.innerWidth > 1400 && (
        <ContextMenu
          hideOnLeave={true}
          preventHideOnContextMenu={true}
          style={{ zIndex: 99 }}
          id="zoom-context-menu"
        >
          <Card className="context-menu-card desktop-only">
            <MenuItem
              className=" context-menu-item"
              onClick={() => contextMenuZoomInOut(100)}
            >
              <ZoomIn height="15px" />
              Zoom In
            </MenuItem>
            <MenuItem
              className="context-menu-item"
              onClick={() => contextMenuZoomInOut(-100)}
            >
              <ZoomOut height="15px" />
              Zoom Out
            </MenuItem>
          </Card>
        </ContextMenu>
      )}

      <Container fluid="true">
        <Message
          isVisible={isMessageVisible}
          opacity={messageOpacity}
          message={`आपका ${
            ft1Duration ?? 30
          } दिन का फ्री ट्रायल एक्टिवेट कर दिया गया है|`}
        />
        <TransformWrapper
          doubleClick={{ disabled: true }}
          // wheel={{ disabled: window.innerWidth < 800 ? false : true }}
          wheel={{ disabled: true }}
          initialScale={1}
          onZoom={(event) => {
            window.epaperZoom = event;
            if (event.state.previousScale < 1) {
              setZoom(false);
              // event.instance.setTransformState(1, 0, 0);
            } else {
              setZoom(true);
            }
          }}
          onZoomStart={(event) => {
            if (event.state.scale === 1) {
              setCheckHeight(false);
            }
            setCheckHeight(true);
          }}
          onPinching={(e) => {
            if (e.state.scale === 1) {
              setZoom(false);
              // e.instance.setTransformState(1, 0, 0);
            }
          }}
          onZoomStop={(e) => {
            if (e.state.previousScale < 1) {
              window.eventPinch = e;
              e.instance.setTransformState(1, 0, 0);
            }
          }}
          panning={{ disabled: zoom ? false : true }}
        >
          <TransformComponent
            wrapperClass="react-transform-wrapper-main"
            wrapperStyle={{
              height:
                checkHeight && window.innerWidth > 767
                  ? window.innerHeight - 70
                  : checkHeight && window.innerWidth < 767
                  ? window.innerHeight - 60
                  : "auto",
              overflow: "auto",
              // margin: zoom && "4rem 0rem",
            }}
          >
            <ContextMenuTrigger id="zoom-context-menu">
              <div
                {...handlers}
                {...ArrowKeysReact.events}
                className="content-modal "
                style={{
                  width: !fit2page
                    ? window.innerWidth
                    : isPopularDrawerOpen && window.innerWidth > 1365
                    ? window.innerWidth - 340
                    : window.innerWidth - vw(0.7),
                  // overflow: "auto",
                  height: isFitToHeight === 1 ? "calc(100vh - 220px)" : "auto",
                  overflowY: isFitToHeight === 1 ? "hidden" : "scroll",
                  overflowX: isFitToHeight === 1 ? "hidden" : "hidden",
                  marginTop:
                    window.innerWidth > 1132
                      ? "150px"
                      : localStorage.getItem("contentRec") === "false" &&
                        window.innerWidth < 1133
                      ? "60px"
                      : localStorage.getItem("contentRec") === "true" &&
                        window.innerWidth < 701
                      ? "97px"
                      : window.innerWidth < 701
                      ? "50px"
                      : "150px",
                }}
              >
                {!fit2page && (
                  <div
                    tabIndex="0"
                    className={
                      window.innerWidth > 1365 ? "img-container-parent" : ""
                    }
                    style={{
                      width:
                        isPopularDrawerOpen && window.innerWidth > 1365
                          ? window.innerWidth - 340
                          : window.innerWidth - vw(0.7),
                      height:
                        window.innerWidth > 1365
                          ? window.innerHeight - 160
                          : // : window.innerWidth < 1365
                            // ? window.innerHeight - 160
                            // : window.innerWidth < 701
                            // ? window.innerHeight - 60
                            "auto",
                    }}
                  >
                    <Row
                      className="img-container"
                      style={{
                        width: width,
                        margin: isFitToHeight > 0 && "auto",

                        transform:
                          isPopularDrawerOpen && isFitToHeight > 0
                            ? "translateX(-5vw)"
                            : "",
                        height:
                          window.innerWidth < 1365 && !isIOS && !isAndroid
                            ? window.innerHeight - 107
                            : "auto",
                        overflowY:
                          window.innerWidth < 1365 && !isIOS && !isAndroid
                            ? "auto"
                            : "auto",
                      }}
                      noGutters
                    >
                      {articles?.PageContent?.map((article, key) => {
                        return (
                          <React.Fragment
                            key={
                              "PageContent1" + article.ArticleName + key + "_1"
                            }
                          >
                            {isImageLoaded && !loading && (
                              <div
                                className={zoom ? "" : "highlightParent"}
                                key={
                                  "PageContent1" +
                                  article.ArticleName +
                                  key +
                                  "_1"
                                }
                                id={"PageContent1" + article.ArticleName + "_1"}
                              >
                                {article.Zones.map((zone, i) => {
                                  return (
                                    zone.TagName !== "URL" && (
                                      <div
                                        onClick={async () => {
                                          let path = `${s3Source}/PublicationData/${
                                            initialPublication?.publicationcode
                                          }/${
                                            initialEdition?.editionid
                                          }/${dateArray?.join("/")}/LinkJson/${
                                            dateArray[2]
                                          }_${dateArray[1]}_${dateArray[0]}_${
                                            initialEdition?.editionid
                                          }.json?v=${filteredDate?.Timestamp}`;

                                          if (zone.TagName === "Information") {
                                            setIsInfo(true);
                                            openInfoUrl(article.ArticleName);
                                            // window.open(
                                            //   infoZoneText?.replace(
                                            //     /<\/?span[^>]*>/g,
                                            //     ""
                                            //   ),
                                            //   "_blank"
                                            // );
                                            return;
                                          }
                                          if (
                                            zone.TagName === "Advertisement"
                                          ) {
                                            setAdvertisement(zone);
                                            articleEvents(
                                              "aricle_open",
                                              initialEdition?.editionname,
                                              "article_open",
                                              "ad",
                                              "page_view"
                                            );
                                          } else {
                                            setAdvertisement("");
                                          }
                                          if (zone.TagName !== "LinkTo") {
                                            if (
                                              zone.TagName !== "Advertisement"
                                            ) {
                                              if (fit2page) {
                                                articleEvents(
                                                  "aricle_open",
                                                  initialEdition?.editionname,
                                                  "article_open",
                                                  "article",
                                                  "page_view"
                                                );

                                                openArticle2PageView(
                                                  article.ArticleName,
                                                  false,
                                                  path,
                                                  zone
                                                );
                                              } else {
                                                articleEvents(
                                                  "aricle_open",
                                                  initialEdition?.editionname,
                                                  "article_open",
                                                  "article",
                                                  "page_view"
                                                );

                                                openArticleView(
                                                  article.ArticleName,
                                                  false,
                                                  path,
                                                  zone
                                                );
                                              }
                                            }
                                            toggleArticleViewModal();
                                          } else {
                                            let filtered_response =
                                              await searchLink(path, zone);

                                            if (
                                              filtered_response &&
                                              filtered_response.length > 0
                                            ) {
                                              let jumpTo =
                                                dayIndexData.findIndex(
                                                  (v) =>
                                                    v.PageName ===
                                                    filtered_response[0]
                                                      .PageLink
                                                );
                                              if (
                                                filtered_response[0]
                                                  .LinkType === "pageLink"
                                              ) {
                                                if (jumpTo !== -1)
                                                  pageClick(
                                                    `${s3Source}/PublicationData/${
                                                      initialPublication?.publicationcode
                                                    }/${
                                                      initialEdition?.editionid
                                                    }/${dateArray?.join(
                                                      "/"
                                                    )}/PageThumb/${
                                                      filtered_response[0]
                                                        .PageLink
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`,
                                                    parseInt(jumpTo)
                                                  );
                                              } else {
                                                if (jumpTo !== -1)
                                                  pageClick(
                                                    `${s3Source}/PublicationData/${initialPublication?.publicationcode}/${initialEdition}/${dateArray[2]}/${dateArray[1]}/${dateArray[0]}/PageThumb/${filtered_response[0].PageLink}.jpg?v=${filteredDate?.Timestamp}`,
                                                    parseInt(jumpTo),
                                                    filtered_response[0]
                                                      .ArticleLink
                                                  );
                                              }
                                            }
                                          }
                                        }}
                                        onMouseEnter={(e) => {
                                          // onZoneHover(zone, article);
                                          if (
                                            zone.TagName === "LinkTo" ||
                                            zone.TagName === "URL"
                                          ) {
                                            onHover(e, zone, article);
                                          }
                                        }}
                                        onMouseLeave={async (e) => {
                                          await onRemoveHover(e, zone, article);
                                        }}
                                        className={
                                          zone.TagName === "LinkTo"
                                            ? "highlight highlight-linkTo"
                                            : zone.TagName === "URL"
                                            ? "highlight highlight-linkTo"
                                            : "highlight"
                                        }
                                        style={{
                                          zIndex:
                                            zone.TagName === "LinkTo"
                                              ? 9999999
                                              : zone.TagName === "URL"
                                              ? 9999999
                                              : 1,
                                          position: "absolute",
                                          top: zone.Y,
                                          left: zone.X,
                                          height: zone.H,
                                          width: zone.W,
                                        }}
                                        key={
                                          "zones_" +
                                          zone.Y +
                                          zone.X +
                                          zone.H +
                                          zone.W +
                                          "_2"
                                        }
                                        id={
                                          "zones_" +
                                          zone.Y +
                                          zone.X +
                                          zone.H +
                                          zone.W +
                                          "_2"
                                        }
                                      ></div>
                                    )
                                  );
                                })}
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                      {articles?.PageContent?.length > 0 &&
                      currentPage?.PageName &&
                      dateArray?.length > 0 &&
                      initialPublication?.publicationcode &&
                      initialEdition?.editionid &&
                      currentPage?.PageName?.slice(
                        currentPage?.PageName?.length -
                          initialEdition?.editionid?.length
                      ) === initialEdition?.editionid ? (
                        <img
                          onError={(e) => {
                            e.target.src = defaultImg;
                          }}
                          // onLoad={() => setImageLoaded(true)}
                          crossOrigin="*"
                          alt="Page"
                          className={"card-img-top"}
                          src={`${s3Source}/PublicationData/${
                            initialPublication?.publicationcode
                          }/${initialEdition?.editionid}/${dateArray?.join(
                            "/"
                          )}/Page/${currentPage?.PageName}.jpg?v=${
                            filteredDate?.Timestamp
                          }`}
                        />
                      ) : (
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: desktopView ? "30%" : "35vh" }}
                        >
                          <h1 className="text-muted">
                            {currentPage?.PageTitle}
                          </h1>
                        </div>
                      )}
                    </Row>
                  </div>
                )}

                {fit2page && !loading && (
                  <Row
                    style={{
                      display: "flex",
                      width: !isPanoramaPage ? width + widthPage2 : width,
                      justifyContent:
                        currentPage.pageNumber === 1 ? "center" : "",
                    }}
                    className="img-container flex-nowrap overflow-auto"
                    noGutters
                  >
                    <Col
                      md="6"
                      sm="6"
                      lg="6"
                      xl="6"
                      style={{
                        width: width,
                      }}
                    >
                      {articles?.PageContent?.map((article, key) => {
                        return (
                          <React.Fragment
                            key={
                              "PageContent2" +
                              article.ArticleName +
                              key +
                              "_abc"
                            }
                          >
                            {isImageLoaded && (
                              <div
                                className="highlightParent"
                                key={
                                  "PageContent2" +
                                  article.ArticleName +
                                  key +
                                  "_abc"
                                }
                              >
                                {article.Zones.map((zone, i) => {
                                  return (
                                    zone.TagName !== "URL" && (
                                      <div
                                        key={
                                          "zones2_" +
                                          zone.Y +
                                          zone.X +
                                          zone.H +
                                          zone.W +
                                          "_"
                                        }
                                        onClick={() => {
                                          if (
                                            zone.TagName === "Advertisement"
                                          ) {
                                            setAdvertisement(zone);
                                          } else {
                                            setAdvertisement("");
                                            openArticleView(
                                              article.ArticleName
                                            );
                                          }

                                          toggleArticleViewModal();
                                        }}
                                        className="highlight"
                                        style={{
                                          position: "absolute",
                                          top: zone.Y,
                                          left: zone.X,
                                          height: zone.H,
                                          width: zone.W,
                                        }}
                                      ></div>
                                    )
                                  );
                                })}
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}

                      {twoPageImage1Name &&
                      dateArray?.length > 0 &&
                      initialPublication?.publicationcode &&
                      initialEdition?.editionid &&
                      currentPage?.PageName?.slice(
                        currentPage?.PageName?.length -
                          initialEdition?.editionid?.length
                      ) === initialEdition?.editionid ? (
                        <img
                          onError={(e) => {
                            e.target.src = defaultImg;
                          }}
                          // onLoad={() => setImageLoaded(true)}
                          style={{
                            width: width,
                          }}
                          crossOrigin="*"
                          alt="Page"
                          className="card-img-top"
                          src={`${s3Source}/PublicationData/${
                            initialPublication?.publicationcode
                          }/${initialEdition?.editionid}/${dateArray?.join(
                            "/"
                          )}/Page/${twoPageImage1Name}.jpg?v=${
                            filteredDate?.Timestamp
                          }`}
                        />
                      ) : (
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: desktopView ? "30%" : "35vh" }}
                        >
                          <h1 className="text-muted">Loading..</h1>
                        </div>
                      )}
                    </Col>
                    {(!isPanoramaPage || isPanoramaPage2) &&
                      currentPage?.pageNumber !== 1 && (
                        <Col
                          style={{
                            width: widthPage2,
                          }}
                          md="6"
                        >
                          {dayIndexData &&
                            dayIndexData.length > 0 &&
                            !(
                              dayIndexData.length === currentPage.pageNumber &&
                              dayIndexData.length % 2 !== 0
                            ) && (
                              <>
                                {articlesPage2?.PageContent?.map(
                                  (article, key) => {
                                    return (
                                      <React.Fragment
                                        key={
                                          article.ArticleName +
                                          "_article-" +
                                          key +
                                          "PageContent5"
                                        }
                                      >
                                        {isImageLoadedPage2 && (
                                          <div
                                            className="highlightParent"
                                            key={
                                              article.ArticleName +
                                              "_article-" +
                                              key +
                                              "PageContent5"
                                            }
                                          >
                                            {article.Zones.map((zone, i) => {
                                              return (
                                                zone.TagName !== "URL" && (
                                                  <div
                                                    key={
                                                      i +
                                                      "_article_" +
                                                      "zones3_" +
                                                      zone.Y +
                                                      zone.X +
                                                      zone.H +
                                                      zone.W
                                                    }
                                                    onClick={() => {
                                                      if (
                                                        zone.TagName ===
                                                        "Advertisement"
                                                      ) {
                                                        articleEvents(
                                                          "aricle_open",
                                                          initialEdition?.editionname,
                                                          "article_open",
                                                          "ad",
                                                          "page_view"
                                                        );

                                                        setAdvertisement(zone);
                                                      } else {
                                                        setAdvertisement("");
                                                        articleEvents(
                                                          "aricle_open",
                                                          initialEdition?.editionname,
                                                          "article_open",
                                                          "article",
                                                          "page_view"
                                                        );

                                                        openArticleView(
                                                          article.ArticleName,
                                                          true
                                                        );
                                                      }

                                                      toggleArticleViewModal();
                                                    }}
                                                    className="highlight"
                                                    style={{
                                                      position: "absolute",
                                                      top: zone.Y,
                                                      left: zone.X,
                                                      height: zone.H,
                                                      width: zone.W,
                                                    }}
                                                  ></div>
                                                )
                                              );
                                            })}
                                          </div>
                                        )}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                                {twoPageImage2Name &&
                                dateArray?.length > 0 &&
                                initialPublication?.publicationcode &&
                                initialEdition?.editionid &&
                                currentPage?.PageName?.slice(
                                  currentPage?.PageName?.length -
                                    initialEdition?.editionid?.length
                                ) === initialEdition?.editionid ? (
                                  <img
                                    onError={(e) => {
                                      e.target.src = defaultImg;
                                    }}
                                    onLoad={() => setImageLoadedPage2(true)}
                                    style={{
                                      width: widthPage2,
                                    }}
                                    crossOrigin="*"
                                    alt="Page"
                                    className={"card-img-top"}
                                    src={`${s3Source}/PublicationData/${
                                      initialPublication?.publicationcode
                                    }/${
                                      initialEdition?.editionid
                                    }/${dateArray?.join(
                                      "/"
                                    )}/Page/${twoPageImage2Name}.jpg?v=${
                                      filteredDate?.Timestamp
                                    }`}
                                  />
                                ) : (
                                  <div
                                    className="d-flex justify-content-center"
                                    style={{
                                      marginTop: desktopView ? "30%" : "35vh",
                                    }}
                                  >
                                    <h1 className="text-muted">Loading..</h1>
                                  </div>
                                )}
                              </>
                            )}
                        </Col>
                      )}
                  </Row>
                )}
              </div>
            </ContextMenuTrigger>
          </TransformComponent>
        </TransformWrapper>
        {desktopView ? (
          <ReactModal
            currentPage={currentPage}
            onRequestClose={() => {
              setAdvertisement("");
              toggleArticleViewModal();
            }}
            isOpen={isOpenArticleModal}
            className="mymodal"
            overlayClassName="myoverlay"
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            id={`react-modal-desktop`}
          >
            <ArticleCard
              toggleArticleViewModal={toggleArticleViewModal}
              isSearch={isSearch}
              propsArticles={
                fit2page ? fit2PageArticles.Articles : propsArticles
              }
              themeColor={themeColor}
              twoPageImage1Name={twoPageImage1Name}
              twoPageImage2Name={twoPageImage2Name}
              isSecondPageFit2page={isSecondPageFit2page}
              defaultInitialPubCode={initialPublication}
              defaultInitialEditionName={initialEdition}
              dateArray={dateArray}
              dayIndexData={dayIndexData}
              pageClick={pageClick}
              currentPage={currentPage}
              isOpenMobArticle={isOpenMobArticle}
              handleNextArticle={handleNextArticle}
              handlePrevArticle={handlePrevArticle}
              openArticleView={openArticleView}
              setIsOpenMobArticle={setIsOpenMobArticle}
              advertisement={advertisement}
              setAd={() => {
                setAdvertisement("");
                let modal = document.getElementById("react-modal-desktop");
                modal.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              mostShared={mostShared}
            />
          </ReactModal>
        ) : (
          <ReactModal
            isOpen={isOpenArticleModal}
            className="mymodal-mob "
            overlayClassName={
              localStorage.getItem("contentRec") === "true"
                ? "myoverlay-mob"
                : "myoverlay-mob-noad"
            }
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => {
              setAdvertisement("");
              toggleArticleViewModal();
            }}
            id={"react-modal-mobile"}
          >
            <ArticleCard
              isSearch={isSearch}
              propsArticles={
                fit2page ? fit2PageArticles.Articles : propsArticles
              }
              dayIndexData={dayIndexData}
              pageClick={pageClick}
              themeColor={themeColor}
              toggleArticleViewModal={toggleArticleViewModal}
              twoPageImage1Name={twoPageImage1Name}
              twoPageImage2Name={twoPageImage2Name}
              defaultInitialPubCode={initialPublication}
              defaultInitialEditionName={initialEdition}
              dateArray={dateArray}
              currentPage={currentPage}
              isOpenMobArticle={isOpenMobArticle}
              handleNextArticle={handleNextArticle}
              handlePrevArticle={handlePrevArticle}
              openArticleView={openArticleView}
              setIsOpenMobArticle={setIsOpenMobArticle}
              advertisement={advertisement}
              setAd={() => {
                setAdvertisement("");
                let modal = document.getElementById("react-modal-mobile");
                modal.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              mostShared={mostShared}
            />
          </ReactModal>
        )}

        <Modal
          isOpen={isOpenUpdate}
          size="sm"
          // className="mymodal-mob "
          overlayClassName={"myoverlay-update"}
          centered
          // toggle={function noRefCheck(){}}
        >
          {/* <ModalHeader>Modal title</ModalHeader> */}
          <ModalBody className="update-modal-card">
            <Col className="pr-1" style={{ textAlign: "end" }}>
              <X
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  setIsOpenUpdate(false);
                  bpnSubscribe(
                    "bpn_subscribe",
                    "bottombanner_cross",
                    "bottombanner_cross",
                    "bottombanner_cross"
                  );
                }}
              />
            </Col>
            <Row className="">
              <Col sm="3" className="mt-2" style={{ textAlign: "center" }}>
                <span>
                  <img
                    src="/maharashtratimes-assets/logo/mt.png"
                    width="auto"
                    height="36px"
                    alt="logo"
                  />
                </span>
              </Col>

              <Col sm="9">
                <div className="content">
                  <p>
                    Never miss an update, subscribe to our notifications now for
                    exclusive podcasts/stories.
                  </p>
                </div>
                <Row
                  style={{
                    justifyContent:
                      window.innerWidth > 700 ? "start" : "center",
                  }}
                >
                  <Button
                    className=" btn-update-not mr-2"
                    onClick={() => {
                      bpnSubscribe(
                        "bpn_subscribe",
                        "bottombanner_notnow",
                        "bpn_subscribe",
                        "bottombanner_notnow"
                      );
                      setIsOpenUpdate(false);
                    }}
                  >
                    Not Now
                  </Button>
                  <Button
                    className="btn-update-subs"
                    onClick={() => {
                      bpnSubscribe(
                        "bpn_subscribe",
                        "bottombanner_subscribe",
                        "bpn_subscribe",
                        "bottombanner_subscribe"
                      );
                      pushNotifications();
                      setIsOpenUpdate(false);
                    }}
                  >
                    Subscribe
                  </Button>
                </Row>
              </Col>
              {/* <i
            className="fa fa-times-circle d-flex flex-end"
            onClick={closeNotification}
          /> */}
            </Row>
          </ModalBody>
        </Modal>
      </Container>
    </React.Fragment>
  );
}
