import React, { useState, useEffect } from "react";
import { Row, Col, CardImg, Card } from "reactstrap";
import sampleImage from "../assets/images/image-highlight.jpg";
import Searchjson from "../config/SearchImage.json";

const Highlight = () => {
  const search = "stable crop protein searches";
  const [imageDimension, setImageDimension] = useState({
    height: 0,
    width: 0,
    top: 0,
    left: 0,
  });
  useEffect(() => {
    const fullImageDimension = Searchjson[0].Geometry.BoundingBox;
    setImageDimension({
      height: fullImageDimension.Height * 500,
      width: fullImageDimension.Width * 700,
      top: fullImageDimension.Top * 500,
      left: fullImageDimension.Left * 700,
    });
  }, []);

  const getOpacity = (article, searchQuery) => {
    if (article.BlockType === "PAGE") return 0;
    const searchWords = searchQuery.toLowerCase().split(" ");

    if (searchWords.includes(article.Text.toLowerCase())) return 0.3;
    else return 0;
  };
  return (
    <>
      <Row
        className=""
        style={{
          //   width: "1000px",
          position: "relative",
          //   transform:
          //     isPopularDrawerOpen && isFitToHeight > 0 ? "translateX(-5vw)" : "",
        }}
        noGutters
      >
        {Searchjson?.map((article, key) => {
          console.log("Searchjson: ", Searchjson);
          return (
            <>
              <div
                key={"articleName_" + article.Text}
                key={"ll" + key}
                className=""
                style={{
                  backgroundColor: "yellow",
                  opacity: getOpacity(article, search),
                  position: "absolute",
                  top: article.Geometry.BoundingBox.Top * 500,
                  left: article.Geometry.BoundingBox.Left * 700,
                  height: article.Geometry.BoundingBox.Height * 500,
                  width: article.Geometry.BoundingBox.Width * 700,
                }}
              ></div>
            </>
          );
        })}

        <img
          style={{ width: imageDimension.width, height: imageDimension.height }}
          //   onError={(e) => (e.target.src = defaultImg)}
          //   onLoad={() => setImageLoaded(true)}
          crossOrigin="*"
          alt="Page"
          //   className={"card-img-top"}
          src={sampleImage}
        />
      </Row>
    </>
  );
};
export default Highlight;
