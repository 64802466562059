import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Card, Row, Col, CardHeader, Table } from "reactstrap";
import "./faqpage.css";
import {
  Bookmark,
  List,
  Menu,
  Grid,
  Columns,
  Home,
  Maximize,
  Monitor,
  Search,
  Settings,
  Layers,
  ZoomIn,
  ZoomOut,
} from "react-feather";
import { Apps, Receipt } from "@material-ui/icons";
import TopHeader from "./TopHeader";
import { Helmet } from "react-helmet";

export default function FaqPage(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="canonical"
          href="https://epaper.timesgroup.com/maharashtratimes/faqpage"
        />
      </Helmet>
      <TopHeader />
      <Row
        className=" landing-cotainer-scroll"
        style={{
          height: window.innerHeight - 100,
          overflowY: "auto",
          marginTop:
            window.innerWidth > 1132
              ? "110px"
              : localStorage.getItem("contentRec") === "false" &&
                window.innerWidth < 1133
              ? "60px"
              : localStorage.getItem("contentRec") === "true" &&
                window.innerWidth < 701
              ? "60px"
              : window.innerWidth < 701
              ? "60px"
              : "110px",
        }}
      >
        <Col md="2"></Col>
        <Col md="8" className="px-4">
          <Card>
            <CardHeader className="faq-header">FAQ's</CardHeader>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Which web browsers are supported?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    The 2 most current versions of Apple Safari, Google Chrome,
                    Mozilla Firefox are recommended
                  </li>
                  <li className="faq-list">
                    Microsoft Edge and Internet Explorer 11 are supported
                  </li>
                  <li className="faq-list">
                    IE10 and unsupported previous versions of Safari on Windows,
                    and Opera will be directed to update the browser.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Where's my table of contents/section tabs? How do I jump to a
                  section?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    The “Sections” button, the top left Toolbar button, opens a
                    Table of Contents.
                  </li>
                  <li className="faq-list">
                    {" "}
                    Click any section and article to jump to it.
                  </li>
                  <li className="faq-list">
                    Additionally, in the bottom left corner of the window, there
                    is a breadcrumb menu showing you what section and story
                    you've selected. Hover the mouse pointer or Tap the Section
                    (left side) or Article in the Section (Right Side) on a
                    touchscreen device to display the list of sections and
                    articles. Click/Tap your desired section/article name from
                    the displayed list to select it. The e-paper will display
                    your selection.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I scroll/move the displayed page?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    Desktop: Use your mouse to click and drag, or use the mouse
                    scroll wheel to scroll the displayed page.
                  </li>
                  <li className="faq-list">
                    Mobile Device: Tap and drag to move the page.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I turn to the next page?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    Desktop: <br /> 1. Click the tabs with arrows on the left
                    and right side of the displayed page. <br />
                    Click anywhere on the page and drag left or right.
                  </li>
                  <li className="faq-list">
                    Mobile: <br /> 1. Tap the tabs with arrows on the left and
                    right side of the displayed page
                    <br />
                    2. Swipe left or righ
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I zoom/magnify the display for better reading?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    Desktop: Double-click/Double-tap anywhere on the page for a
                    single level Zoom In/Zoom Out.
                  </li>
                  <li className="faq-list">
                    Desktop: Click/Tap the Zoom In/Zoom Out buttons on the
                    toolbar 2. Swipe left or righ
                  </li>
                  <li className="faq-list">
                    Mobile: 2 finger spread and pinch zooms in and out
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I bring up the article/story view?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    A single tap/click will open the article/story view.
                  </li>
                  <li className="faq-list">
                    A double-tap/click will zoom in / zoom out one level.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I change the font size in the article view?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    Once in article view, you can increase or decrease font size
                    using the font size icons, or on tablets and phones you can
                    pinch/zoom to resize. In the replica view you can zoom in
                    and out by either single clicking or by using the zoom
                    controls in the main menu bar. On mobile devices you can
                    pinch/spread to zoom in and out
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I navigate between articles?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    Once in the article level view, you can click/tap the arrow
                    buttons on the left and right side of the window to see the
                    previous and next articles, respectively. Also, if you’re
                    reading on a widescreen monitor, you can also choose from a
                    list of stories in that section in the right-side panel.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I share stories?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    Open the story in Article/Story View. The Article View menu
                    has buttons that allow you to share via email, Facebook,
                    Whatsapp and Twitter.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel11bh-content"
                id="panel11bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  How do I print out my story or photo?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    First, single click on the article to open it in
                    Article/Story View, then select the "print" icon on the top
                    right corner.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel12bh-content"
                id="panel12bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  What is the right panel?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    The right panel displays an advertisement and may be used
                    for supplemental content.
                  </li>
                  <li className="faq-list">
                    If the screen size is small (such as on a phone or tablet in
                    profile view), the right panel is collapsed automatically.
                    When closed, the panel can be opened by clicking the
                    “Updates” tab on the right side.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChange("panel12")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel13bh-content"
                id="panel13bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Does the e-Edition update throughout the day?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    The e-Edition presents the daily edition newspaper as it was
                    printed. It is the exact replica of the print. It is not
                    updated once uploaded.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChange("panel13")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel14bh-content"
                id="panel14bh-header"
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  I don't like the newspaper replica view on my phone. Is there
                  another option?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <li className="faq-list">
                    There are two views optimized for mobile, the “Tiles” view
                    and the “Digest” view.
                  </li>
                  <li className="faq-list">
                    Tap the “Tiles” toolbar icon to display a grid view of the
                    articles by section. The sections are listed at the top of
                    the page and you can navigate to each section by tapping on
                    the section name.
                  </li>
                  <li className="faq-list">
                    Tap the "Digest" toolbar icon to display newsfeed view.
                  </li>
                  <li className="faq-list">
                    The left column shows a thumbnail of each page of the
                    newspaper
                  </li>
                  <li className="faq-list">
                    The right panel shows the headlines and preview text for the
                    stories on the page.
                  </li>
                  <li className="faq-list">
                    Click on any headline or article text to open the article in
                    Article/Story view with the other headlines in the section
                    in the right panel.
                  </li>
                  <li className="faq-list">
                    The article is "on top" of the headline list. Click the
                    “View on Page” link in the upper left corner to navigate to
                    the article in the replica view. Click the “X” at the upper
                    right corner to close Article view.
                  </li>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <div className="tool_bar_faq p-2">
              <h5>
                <b>What do all the buttons in the toolbar do?</b>
              </h5>
              <Row>
                <Col sm="12">
                  <div>
                    <Table bordered>
                      <thead>
                        <th>Toolbar Icon</th>
                        <th>Description</th>
                      </thead>
                      <tbody style={{ fontSize: "11px" }}>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <img
                              width="24px"
                              src="/maharashtratimes-assets/logo/mt.png"
                            />
                          </td>
                          <td>
                            <p className="mt-2">
                              Takes the user to the original start/landing page
                              http://epaper.timesgroup.com
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Menu color={"#d9252c"} />
                          </td>
                          <td>
                            <p className="mt-2">
                              Displays the publications Table of Contents by
                              Section and Article
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Grid color={"#d9252c"} />
                          </td>
                          <td>
                            <p className="mt-2">
                              Displays a list of available e-Edition back issues
                              by date with links to each one as well as provides
                              a link to the archive.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Search color={"#d9252c"} />
                          </td>
                          <td>
                            <p className="mt-2">
                              Allows the user to search the current and back
                              issues by keyword(s).
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Home color="#d9252c" />
                          </td>
                          <td>
                            <p className="mt-2">
                              Allows the user to select any of the four major
                              publications
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Layers color="#d9252c" />
                          </td>
                          <td>
                            <p className="mt-2">
                              Allows the user to select any of the local
                              editions within the selected major publication.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Grid color="#d9252c" />
                          </td>
                          <td>
                            <p className="mt-2">
                              Displays and closes the thumbnail display of the
                              editions pages at the bottom of the window.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Settings color="#d9252c" />
                          </td>
                          <td>
                            <p className="mb-0">
                              The user can choose to
                              <ul className="ml-3 mb-0">
                                <li>
                                  Display the help overlay which identifies the
                                  major areas of the application.
                                </li>
                                <li>
                                  {" "}
                                  Send an email to the publications contact
                                  email address.
                                </li>
                              </ul>
                            </p>
                          </td>
                        </tr>
                        {/* <tr>
                          <td className="d-flex justify-content-center">
                            <img src="https://epaper.timesgroup.com/toi/timesofindia/images/image011.png" />
                          </td>
                          <td>
                            <p className="mb-0">
                              Allows the user to
                              <ul className="ml-3 mb-0">
                                <li>
                                  choose the preferred settings for default
                                  display. The view selected takes effect on
                                  your next login
                                </li>
                                <li>
                                  set article alerts by keyword(s). When
                                  articles contain the desired keywords, an
                                  email is sent notifying the user of the
                                  articles
                                </li>
                              </ul>
                            </p>
                          </td>
                        </tr> */}
                        <tr>
                          <td className="d-flex justify-content-center">
                            <ZoomOut color="#d9252c" />
                          </td>
                          <td>
                            <p className="mt-2">
                              Zoom out - Shrinks the newspaper view so that more
                              of the page is visible.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <ZoomIn color="#d9252c" />
                          </td>
                          <td>
                            <p className="mt-2">
                              Zoom in - Enlarges the newspaper view for easier
                              reading/viewing.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Monitor color="#d9252c" />
                          </td>
                          <td>
                            <p className="mt-2">
                              Allows you to select how the page is displayed:
                              Fit to width, fit to height and 2-page view. This
                              is only available in Replica mode. The icon in the
                              toolbar reflects your current view.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Apps style={{ color: "#d9252c" }} />
                          </td>
                          <td>
                            <p className="mt-2">
                              Displays stories in a grid view optimized for
                              mobile. Each tile is a link that will display the
                              entire story.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <Receipt style={{ color: "#d9252c" }} />
                          </td>
                          <td>
                            <p className="mt-2">
                              Selected by default. Displays newspaper view.
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td className="d-flex justify-content-center">
                            <List color="#d9252c" />
                          </td>
                          <td>
                            <p className="mt-2">
                              Displays stories in a list view.
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md="2"></Col>
        <Col md="2"></Col>
        <Col md="8" className="d-flex justify-content-center px-4">
          <div
            className="footer mt-5 px-4"
            style={{
              background: "#ffffff",
              border: "3px solid #E5E5E5",
              marginTop: "5px",
              marginBottom: "160px",
              float: "left",
              width: "100%",
            }}
          >
            <div className="d-flex justify-content-center align-items-center py-2">
              <div className="copyrighttext">
                <a
                  href="https://epapers.timesgroup.com/about"
                  target="_blank"
                  rel="noreferrer"
                  className="bottomlinks"
                >
                  About Us{" "}
                </a>{" "}
                |{" "}
                <a
                  href="https://epapers.timesgroup.com/advertise"
                  className="bottomlinks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Advertise with Us
                </a>{" "}
                |{" "}
                <a
                  href="https://epapers.timesgroup.com/terms"
                  className="bottomlinks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>{" "}
                |{" "}
                <a
                  href="https://epapers.timesgroup.com/policy"
                  className="bottomlinks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy and Cookie Policy
                </a>
                <br /> Copyright © 2020 Bennett Coleman &amp; Co. Ltd. • All
                rights reserved.
              </div>
            </div>
          </div>
        </Col>
        <Col md="2"></Col>
      </Row>
    </React.Fragment>
  );
}
