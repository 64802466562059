import React, { useEffect, useContext } from "react";
import "./App.css";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
import NotFound from "./components/NotFound";
import jsonp from "jsonp";
import { setTicketId } from "./helper";
import { activeDaysEvents, loginEvents } from "./functions/cleverTap";
import dayjs from "dayjs";
import { AppContext } from "./contexts/AppContext";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

function App() {
  const { validateCustomer, initialPublication, initialEdition } =
    useContext(AppContext);
  useEffect(() => {
    if (initialPublication?.trackingId && initialEdition?.trackingId) {
      ReactGA.initialize([
        {
          trackingId: initialPublication?.trackingId,
        },
        {
          trackingId: initialEdition?.trackingId,
        },
      ]);
    }
  }, [initialPublication?.trackingId, initialEdition?.trackingId]);
  if (window.location.pathname === "/") {
    window.location.assign(`${window.location.origin}/maharashtratimes/home`);
  }

  //gtm and cleverTap

  useEffect(() => {
    // console.log = function () {};
    // console.warn = function () {};
    //👆 comment to show console.log
    const checkSSOTicketId = () => {
      jsonp(
        `https://jsso.indiatimes.com/sso/crossdomain/getTicket?channel=${
          process.env.REACT_APP_CHANNEL_SSO_NAME
        }&version=v1&t=${Date.now()}`,
        null,
        function async(err, data) {
          if (err) {
          } else {
            const temp_ticketId = localStorage.getItem("temp_ticketId");
            if ((data.status && data.ticketId) || temp_ticketId) {
              let ssoTicketId = data.ticketId || temp_ticketId;
              const url = `https://jsso.indiatimes.com/sso/crossdomain/v1validateTicket?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ticketId=${ssoTicketId}`;
              jsonp(url, null, function (err, data) {
                if (err) {
                  console.log(err);
                } else {
                  // gtm and cleverTap
                  // loginCompleteEvent(data);
                  setTicketId(ssoTicketId);
                  validateCustomer(ssoTicketId, data);
                }
              });

              let active_day = localStorage.getItem("active_day");
              let today = new Date();
              if (
                active_day == undefined ||
                dayjs(today).isAfter(dayjs(active_day), "day")
              ) {
                activeDaysEvents(
                  "active_days",
                  "active_days",
                  "active_days",
                  "active_days"
                );

                localStorage.setItem("active_day", new Date());
              }
            } else {
              let active_day = localStorage.getItem("active_day");
              let today = new Date();
              if (
                active_day == undefined ||
                dayjs(today).isAfter(dayjs(active_day), "day")
              ) {
                activeDaysEvents(
                  "active_days",
                  "active_days",
                  "active_days",
                  "active_days"
                );

                localStorage.setItem("active_day", new Date());
              }
            }
          }
        }
      );
    };
    checkSSOTicketId();
    if (document) document?.addEventListener("click", checkSSOTicketId);
  }, []);
  return (
    <>
      <Helmet>{/* <meta name="robots" content="noindex" /> */}</Helmet>

      <BrowserRouter>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.length > 0 &&
            authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          <Route component={(props) => <NotFound {...props} />} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
