import React, { useState, useContext, useMemo, useEffect, useRef } from "react";
import "./content-modal.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import JSCookie from "js-cookie";
import { DFPSlotsProvider, AdSlot, DFPManager } from "react-dfp";
import ReactGA from "react-ga4";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import {
  Row,
  Col,
  Card,
  CardBody,
  Collapse,
  ModalHeader,
  Container,
  Spinner,
} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import FileSaver from "file-saver";
import { useHistory } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { X, ZoomIn, ZoomOut, Mail, Download } from "react-feather";
import axios from "axios";
import { Copy, FileText, Image, Printer, Share2, Volume2 } from "react-feather";
import { VolumeX } from "react-feather";
import Modal from "react-modal";

import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import ShareIcon from "@material-ui/icons/Share";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import PrintIcon from "@material-ui/icons/Print";
import "../Fonts/LibreBaskerville-Regular.ttf";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import { XCircle } from "react-feather";
// context
import { AppContext } from "../contexts/AppContext";
import { displayToastr } from "../helper";
import { VolumeOff } from "@material-ui/icons";
import MailModalFeedBack from "./MailModalFeedBack";
import {
  articleEvents,
  downloadEvent,
  printEvents,
  shareEvents,
  zoomEvents,
} from "../functions/cleverTap";

Modal.setAppElement("#root");

const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});
export default function ArticleCard(props) {
  const history = useHistory();

  const [pageClickValues, setPageClickValues] = useState({});
  useEffect(() => {
    if (props.isSearch && Object.keys(props.isSearch).length > 0) {
      let path = `${s3Source}/PublicationData/${
        props?.defaultInitialPubCode?.publicationcode
      }/${props?.defaultInitialEditionName?.editionid}/${
        props?.dateArray[0]
      }/${props.isSearch.articleName
        .split("_")
        .slice(0, 3)
        .reverse()
        .join("/")}/PageThumb/${props.isSearch.articleName
        .split("_")
        .slice(0, 3)
        .join("_")}_${props?.defaultInitialEditionName?.editionid}.jpg?v=${
        filteredDate?.Timestamp
      }`;

      // if (
      //   props.isSearch.articleName.split("_").slice(0, 3).join("-") !==
      //   JSCookie.get("mteddate")
      // ) {
      //   props.updateDayIndex(
      //     props.isSearch.articleName.split("_").slice(0, 3).join("-")
      //   );
      // }
      setPageClickValues({ path, pageNumber: 0 });
    } else {
      setPageClickValues({});
    }
  }, [props.isSearch]);
  const [currentPublication, setCurrentPublication] = useState({});

  function googleAds() {
    return (
      <div>
        {/* <Ad
          path={`/1064661/Inside_Article_1st_Left`}
          width={300}
          height={250}
          divId={`div-gpt-ad-1640342494568-0`}
        /> */}
        <Ad
          path={initialPublication?.adInsideLeftArticle}
          width={300}
          height={250}
          divId={initialPublication?.adInsideLeftArticleId}
        />
      </div>
    );
  }
  const [loading, setArticleLoading] = useState(true);

  // global state

  const {
    articles,
    articleViewData,
    articleViewName,
    toggle,
    isOpen2,
    print,
    setIsOpen2,
    fit2page,
    s3Source,
    currArticleIndex,
    publicationJson,
    views,
    shares,
    totalShareCount,
    isRegional,
    setIsRegional,
    imageUrl,
    activeTabName,
    setArticleViewData,
    setArticleViewDataLinked,
    articleViewDataLinked,
    articleViewNameLinked,
    linkJsonURL,
    hasInfoZone,
    updateInsight,
    setTotalShareCount,
    setRelatedArticleData,
    relatedArticleData,
    TTS,
    setTTS,
    setHasInfoZone,
    setArticleViewName,
    linkJsonData,
    pageClick,
    setLoading,
    filteredDate,
    getPublicationsData,
    setCurrentPage,
    setItems,
    initialEdition,
    dayIndexData,
    currentPage,
    initialPublication,
    isEnableAds,
    isOpenFeedBackModal,
    setIsOpenFeedBackModal,
  } = useContext(AppContext);

  // GPTAdsManager.initializeAds(false, true);
  useEffect(() => {
    setTimeout(() => {
      window && window.googletag && window?.googletag?.pubads()?.refresh();
    }, 500);
  }, [articleViewData]);
  const [extractLocation, setExtractLocation] = useState("");
  const [linkextractLocation, setlinkExtractLocation] = useState("");
  const [articleBanner, setArticleBanner] = useState("");
  const [articleBannerMob, setArticleBannerMob] = useState("");

  const [speechRunning, setSpeechRunning] = useState(false);
  const [Player, setPlayer] = useState(null);
  const [sharingUrl, setSharingUrl] = useState("");
  useEffect(() => {
    if (
      articleViewData &&
      (Object.keys(articleViewData).length === 0 ||
        !articleViewData.ArticleBody)
    ) {
      setHasInfoZone(true);
    } else {
      setHasInfoZone(false);
    }
    if (articleViewData?.Information) {
      setHasInfoZone(true);
    }
  }, [articleViewData]);
  useEffect(() => {
    const generateTTS = async () => {
      let text_to_speech = "";
      if (articleViewData && Object.keys(articleViewData).length > 0) {
        text_to_speech = articleViewData?.tts;
        if (relatedArticleData?.length > 0) {
          for (let i = 0; i < relatedArticleData.length; i++) {
            const ele = relatedArticleData[i];
            text_to_speech += ele.tts;
          }
        }
        if (
          articleViewDataLinked &&
          Object.keys(articleViewDataLinked).length > 0
        ) {
          text_to_speech += articleViewDataLinked.tts;
        }
        setTTS(text_to_speech);
      }
    };
    generateTTS();
    return () => {
      setSpeechRunning(false);
      Player?.pause();
      setPlayer(null);
    };
  }, [articleViewData, articleViewDataLinked, relatedArticleData]);

  // useEffect(() => {
  //   // if (!speechRunning) {
  //   //   window.responsiveVoice.pause();
  //   // }
  //   // else {
  //   //   window.responsiveVoice.resume();
  //   // }
  // }, [speechRunning]);

  async function synthesizeSpeech(text_to_speech) {
    // microsoft
    if (speechRunning && Player) {
      return Player.pause();
    }
    if (!speechRunning && Player) {
      return Player.resume();
    }
    if (!Player) {
      const speechConfig = sdk.SpeechConfig.fromSubscription(
        process.env.REACT_APP_AZURE_SUB_KEY,
        process.env.REACT_APP_AZURE_SUB_REGION
      );
      const player = new sdk.SpeakerAudioDestination();
      // window.plz = player;
      setPlayer(player);
      const audioConfig = sdk.AudioConfig.fromSpeakerOutput(player);

      let synthesizer = new sdk.SpeechSynthesizer(speechConfig, audioConfig);
      if (!speechRunning && text_to_speech) {
        synthesizer.speakTextAsync(
          text_to_speech,
          (result) => {
            if (result) {
              synthesizer.close();
              setSpeechRunning(true);
              return result.audioData;
            }
          },
          (error) => {
            synthesizer.close();
            synthesizer = undefined;
            setSpeechRunning(false);
          }
        );
      } else {
        await synthesizer.close();
        synthesizer = undefined;
        setSpeechRunning(false);
      }
    }
    // responsive voice
    // let responsiveVoice = window.responsiveVoice;
    // if (responsiveVoice.isPlaying()) {
    //   // console.log("I hope you are listening");
    // } else {
    //   // console.log("I hope its not playing");
    // }

    // if (speechRunning && Player) {
    //   // console.log("should pause");
    //   return responsiveVoice.pause();
    // }
    // if (!speechRunning && Player) {
    //   // console.log("should resume");
    //   return responsiveVoice.resume();
    // }
    // if (!Player && text_to_speech) {
    //   setPlayer(responsiveVoice);
    //   responsiveVoice.speak(text_to_speech);
    // }
  }
  const drawerWidth = 310;
  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      // flexShrink: 0,
    },
    drawerPaper: {
      marginTop: 75,
      overflow: "auto",
      height: window.innerHeight - 110,
      // top: 100,
      width: drawerWidth,
      borderRight: "none",
    },
  }));
  const classes = useStyles();
  const theme = useTheme();
  const [openArticleAd, setOpenArticleAd] = React.useState(false);
  const handleDrawerAd = () => {
    setOpenArticleAd(!openArticleAd);
  };
  const [isPhotoError, setIsPhotoError] = useState(false);
  useEffect(() => {
    let currentPub = publicationJson[JSCookie.get("mtpubindex")];
    if (currentPub && Object.keys(currentPub).length > 0) {
      // if (currentPublication.type === "text") {
      //   setArticleTextView(true);
      // } else {
      //   setArticleTextView(false);
      // }
      setCurrentPublication(currentPub);
    }
  }, []);
  const [isSecondPageFit2page, setIsSecondPageFit2page] = useState(false);
  const advertisement = useRef("");
  useEffect(() => {
    let ad = props?.advertisement?.ZoneId?.split("_");
    if (ad && ad.length > 0) {
      ad.pop();
      advertisement.current = ad.join("_");
      setArticleViewName("");
    } else {
      advertisement.current = "";
    }
  }, [props.advertisement]);
  useEffect(() => {
    if (props.mostShared && props.mostShared.length > 0) {
      let findArticle = props.mostShared.filter(
        (v) => v.articlename === articleViewData?.ArticleName
      );
      if (findArticle?.length > 0 && findArticle[0].share_count) {
        let totalShareCount =
          findArticle[0].share_count?.["email"] ??
          0 + findArticle[0].share_count?.["facebook"] ??
          0 + findArticle[0].share_count?.["whatsapp"] ??
          0 + findArticle[0].share_count?.["twitter"] ??
          0;
        setTotalShareCount(
          totalShareCount && totalShareCount !== "NaN" ? totalShareCount : 0
        );
      }
    }
  }, [articleViewData, props]);

  const articleAuthorEmail = useRef(false);
  function saveImg() {
    // var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
    if (props.advertisement.length !== 0) {
      FileSaver.saveAs(
        `${s3Source}/PublicationData/${
          props?.defaultInitialPubCode?.publicationcode
        }/${props?.defaultInitialEditionName?.editionid}/${
          props?.dateArray[0]
        }/${props?.dateArray[1]}/${props?.dateArray[2]}/Advertisement/${
          advertisement.current?.split("_")[3]
        }/${advertisement.current}_${
          props?.defaultInitialEditionName?.editionid
        }.jpg`,
        "advertisement.jpg"
      );
    } else {
      FileSaver.saveAs(
        `${s3Source}/PublicationData/${
          props?.defaultInitialPubCode?.publicationcode
        }/${props?.defaultInitialEditionName?.editionid}/${
          props?.dateArray[0]
        }/${props?.dateArray[1]}/${props?.dateArray[2]}/Article/${
          articleViewName?.split("_")[3]
        }/${articleViewData?.ArticleName ?? articleViewName}.jpg`,
        `${
          articleViewData?.ArticleTitle
            ? articleViewData?.ArticleTitle
            : "article"
        }.jpg`
      );
    }
  }

  const extractEmail = (author) => {
    if (!author) return false;

    let matches = author?.match(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
    );
    if (matches && matches.length) {
      return matches[0];
    } else {
      return "";
    }
  };
  // useEffect(() => {
  //   try {
  //     //publication
  //     if (
  //       props.defaultInitialPubCode &&
  //       window.gtag &&
  //       localStorage.getItem("webAnalytics") === "true"
  //     )
  //       window.gtag("event", "page_view", {
  //         page_title: props?.defaultInitialPubCode?.publicationcode,
  //         page_location: window.location.origin,
  //         page_path: `/${props?.defaultInitialPubCode?.publicationname
  //           ?.toLowerCase()
  //           ?.split(" ")
  //           ?.join("-")}/${props?.defaultInitialEditionName?.editionname
  //           ?.toLowerCase()
  //           ?.split(" ")
  //           ?.join("-")}`,
  //         send_to: props?.defaultInitialPubCode?.trackingId,
  //       });
  //     //edition
  //     if (
  //       props.defaultInitialEditionName &&
  //       window.gtag &&
  //       localStorage.getItem("webAnalytics") === "true"
  //     )
  //       window.gtag("event", "page_view", {
  //         page_title: props?.defaultInitialEditionName?.editionid,
  //         page_location: window.location.origin,
  //         page_path: `/${props?.defaultInitialPubCode?.publicationname
  //           ?.toLowerCase()
  //           ?.split(" ")
  //           ?.join("-")}/${props?.defaultInitialEditionName?.editionname
  //           ?.toLowerCase()
  //           ?.split(" ")
  //           ?.join("-")}`,
  //         send_to: props?.defaultInitialEditionName?.trackingId,
  //       });
  //   } catch (error) {
  //     console.log("Error from the trackerPageView => ", error);
  //   }
  // }, [articleViewData, props.advertisement]);
  useEffect(() => {
    setArticleLoading(true);

    if (articleViewData?.Author) {
      if (extractEmail(articleViewData?.Author)) {
        articleAuthorEmail.current = true;
      } else {
        articleAuthorEmail.current = false;
      }
    }
    setTimeout(() => {
      setArticleLoading(false);
    }, 500);
  }, [articleViewData]);

  useEffect(() => {
    if (hasInfoZone) {
      setArticleTextView(false);
    } else {
      setArticleTextView(true);
    }
  }, [hasInfoZone]);

  let desktopView = window.innerWidth > 700;
  const [isOpenMobArticle, setIsOpenMobArticle] = useState(false);
  const [articleTextView, setArticleTextView] = useState(true);
  const toggleShare = () => setIsOpenMobArticle(!isOpenMobArticle);
  const [articleBlurbFontsize, setArticleBlurbFontSize] = useState(
    JSCookie.get("articleBlurbFontsize")
      ? Number(JSCookie.get("articleBlurbFontsize"))
      : 13
  );
  const [articleBodyFontsize, setArticleBodyFontSize] = useState(
    JSCookie.get("articleBodyFontsize")
      ? Number(JSCookie.get("articleBodyFontsize"))
      : 17
  );
  const [articleHeadingFontsize, setArticleHeadingFontSize] = useState(
    JSCookie.get("articleHeadingFontsize")
      ? Number(JSCookie.get("articleHeadingFontsize"))
      : 43
  );
  const [articleHeadingMobFontsize, setArticleheadingMobFontSize] = useState(
    JSCookie.get("articleHeadingMobFontsize")
      ? Number(JSCookie.get("articleHeadingMobFontsize"))
      : 25
  );
  const [imgWidth, setImgWidth] = useState(80);
  const [imageDimension, setImageDimension] = useState({
    height: 0,
    width: 0,
    top: 0,
    left: 0,
  });
  //article share
  const [allData, setAllData] = useState("");

  const imageSizeRef = useRef(1);
  const imageViewRef = useRef(1);
  const imageViewLinkRef = useRef(1);

  const photographRef = useRef(1);

  const [imageSizes, setImageSizes] = useState({
    width: 1,
    height: 1,
  });
  const [imagesViewSize, setImageViewSize] = useState({
    width: 1,
    height: "auto",
  });
  const [imagesViewLinkSize, setImageViewLinkSize] = useState({
    width: 1,
    height: "auto",
  });
  const [photographSize, setPhotographSize] = useState({
    width: 100,
    height: 300,
  });
  const [textData, setTextData] = useState([""]);

  const pageNumber = useMemo(() => {
    let currentPageNumber = fit2page
      ? props?.twoPageImage1Name?.slice(11, 14)
      : props?.currentPage?.PageName?.slice(11, 14);
    if (props?.isSecondPageFit2page && fit2page) {
      currentPageNumber = props?.twoPageImage2Name?.slice(11, 14);
    }
    return currentPageNumber;
  }, [
    props.isSecondPageFit2page,
    props.twoPageImage1Name,
    props.twoPageImage2Name,
    props.currentPage,
    fit2page,
  ]);
  const increaseFontSize = () => {
    JSCookie.set("articleBlurbFontsize", articleBlurbFontsize + 2);
    JSCookie.set("articleBodyFontsize", articleBodyFontsize + 2);
    JSCookie.set("articleHeadingFontsize", articleHeadingFontsize + 2);
    JSCookie.set("articleHeadingMobFontsize", articleHeadingMobFontsize + 2);

    setArticleBlurbFontSize(articleBlurbFontsize + 2);
    setArticleBodyFontSize(articleBodyFontsize + 2);
    setArticleHeadingFontSize(articleHeadingFontsize + 2);
    setArticleheadingMobFontSize(articleHeadingMobFontsize + 2);
  };

  const decreaseFontSize = () => {
    JSCookie.set("articleBlurbFontsize", articleBlurbFontsize - 2);
    JSCookie.set("articleBodyFontsize", articleBodyFontsize - 2);
    JSCookie.set("articleHeadingFontsize", articleHeadingFontsize - 2);
    JSCookie.set("articleHeadingMobFontsize", articleHeadingMobFontsize - 2);

    setArticleBlurbFontSize(articleBlurbFontsize - 2);
    setArticleBodyFontSize(articleBodyFontsize - 2);
    setArticleHeadingFontSize(articleHeadingFontsize - 2);
    setArticleheadingMobFontSize(articleHeadingMobFontsize - 2);
  };

  useEffect(() => {
    setLoading(false);
    //   setImgWidth(80);
    //   setImageViewSize({
    //     width: imageViewRef.current.naturalWidth,
    //     height: "auto",
    //   });
  }, []);

  useEffect(() => {
    setImgWidth(80);
    setImageViewSize({
      width: imageViewRef?.current?.naturalWidth - 50,
      height: "auto",
    });
    if (articleViewDataLinked) {
      setImageViewLinkSize({
        width: imageViewLinkRef?.current?.naturalWidth - 50,
        height: "auto",
      });
    }
  }, [imageViewRef, imageViewLinkRef, articleViewDataLinked]);

  const zoomImageSize = (zoomBy, zoomImgView) => {
    setImgWidth((currState) => {
      const newWidth = currState + zoomBy;
      return newWidth;
    });

    setImageViewSize({ width: imagesViewSize.width + zoomImgView });
    if (articleViewDataLinked) {
      setImageViewLinkSize({ width: imagesViewLinkSize.width + zoomBy });
    }
  };
  const toggleView = () => {
    setArticleTextView(!articleTextView);
  };

  const parsedBody = useRef(articleViewData?.ArticleBody);
  const parsedLinkedBody = useRef(articleViewDataLinked?.ArticleBody);

  useEffect(() => {
    if (
      articleViewData &&
      articleViewData.ArticleBody &&
      articleViewData.ArticleBody?.length > 0
    ) {
      const htmlparser = (str) => {
        var input_str; //store input
        var text_input; //store input after beging trim()med
        var output_html = ""; //store output
        var counter;
        input_str = str ? str?.split("<br>").join("br_tag") : ""; //get input and store it in input_str

        //remove duplicate location
        if (articleViewData?.ArticleLocation) {
          let regex = new RegExp("^" + articleViewData?.ArticleLocation, "i");
          if (input_str.match(regex)) {
            let val = input_str.match(regex);
            let len = val.index + articleViewData?.ArticleLocation.length;
            input_str = input_str.slice(len);
          }
        }

        text_input = input_str.trim(); //trim() input
        if (text_input.length > 0) {
          output_html += `<p style='font-family:LibreBaskerville !important;'>`; //begin by creating paragraph
          let foundFullStop = false;
          let sentences = [];
          for (counter = 0; counter < text_input.length; counter++) {
            switch (text_input[counter]) {
              case "\n":
                if (text_input[counter + 1] === "\n") {
                  output_html += "</p>\n<p>";
                  counter++;
                } else {
                  output_html += "<br/>";
                }
                break;
              case ".":
                // if (sentences.length === 0) {
                //   foundFullStop = true;
                //   output_html += ".<br/>";
                // } else {
                output_html += ". ";
                // }
                break;
              case " ":
                if (
                  text_input[counter - 1] !== " " &&
                  text_input[counter - 1] !== "\t"
                )
                  output_html += " ";
                break;

              case "\t":
                if (text_input[counter - 1] !== "\t") output_html += " ";
                break;

              case "":
                output_html += "›";
                break;
              case "`":
                output_html += "₹";
                break;

              default:
                output_html += text_input[counter];
            }
            if (foundFullStop) {
              if (sentences.length === 0) {
                sentences.push(output_html.split("<br/>"));
                let style = `float:left;margin-right:20px;`;
                if (!desktopView) {
                  style = `margin:5px auto 15px;`;
                }

                output_html += `googleAds`;
              }
            }
          }

          output_html += "</p>"; //finally close paragraph
          output_html = output_html.split("br_tag").join("<br/>");
          output_html = output_html.split("&lt;").join("<");
          output_html = output_html.split("&gt;").join(">");
          output_html = output_html.split("").join("›");
          output_html = output_html.split("googleAdDivHere").join("googleAds");
          if (localStorage.getItem("contentRec") === "false") {
            output_html = output_html.split("googleAds").join(" ");
          }
        }

        if (props.isSearch?.search) {
          output_html = output_html
            .split(props.isSearch?.search.toLowerCase())
            .join(
              `<span style='background-color:yellow;font-family:var(--font);'>${props.isSearch?.search}</span>`
            );

          let text = `<span style='line-height:${
            Number(articleBodyFontsize) + 10
          }px;color:#4a4949;background-color:yellow;font-family:var(--font);font-size:${articleBodyFontsize}px;'>${props.isSearch?.search
            .charAt(0)
            .toUpperCase()}${props.isSearch?.search.slice(1)}</span>`;
          output_html = output_html
            .split(
              props.isSearch?.search.charAt(0).toUpperCase() +
                props.isSearch?.search.slice(1)
            )
            .join(text);
        }

        return output_html;
      };

      parsedBody.current = htmlparser(articleViewData?.ArticleBody);

      findLink(articleViewData);
      // htmlparser2(articleViewData?.ArticleBody);
      // } else {
      //   parsedBody.current = articleViewData?.ArticleBody;
      // }
    } else {
      parsedBody.current = "<p></p>";
    }
    if (
      props.isSearch?.from === "photographs" ||
      props.isSearch?.from === "advertisements"
    )
      setArticleTextView(false);
    if (props?.isSearch?.from !== "advertisements" && !props?.advertisement) {
      advertisement.current = "";
    }
  }, [props.isSearch, articleViewData]);
  useEffect(() => {
    if (
      articleViewDataLinked &&
      articleViewDataLinked.ArticleBody &&
      articleViewDataLinked.ArticleBody?.length > 0
    ) {
      const htmlparser = (str) => {
        var input_str; //store input
        var text_input; //store input after beging trim()med
        var output_html = ""; //store output
        var counter;
        // input_str = str ? str?.split("<br>").join(" ").split("&lt;br&gt;&lt;br&gt;").join("<br/><br/>").split("&lt;p&gt;").join("<p>").split("&lt;/p&gt;").join("</p>").split("&lt;b&gt;").join("<b>").split("&lt;/b&gt;").join("</b>") : ""; //get input and store it in input_str
        input_str = str ? str?.split("<br>").join("br_tag") : ""; //get input and store it in input_str

        text_input = input_str.trim(); //trim() input
        if (text_input.length > 0) {
          output_html += `<p style='font-family:LibreBaskerville !important;'>`; //begin by creating paragraph

          for (counter = 0; counter < text_input.length; counter++) {
            switch (text_input[counter]) {
              case "\n":
                if (text_input[counter + 1] === "\n") {
                  output_html += "</p>\n<p>";
                  counter++;
                } else {
                  output_html += "<br/>";
                }
                break;

              case " ":
                if (
                  text_input[counter - 1] !== " " &&
                  text_input[counter - 1] !== "\t"
                )
                  output_html += " ";
                break;

              case "\t":
                if (text_input[counter - 1] !== "\t") output_html += " ";
                break;

              case "`":
                output_html += "₹";
                break;

              case "":
                output_html += "›";
                break;
              default:
                output_html += text_input[counter];
            }
          }

          output_html += "</p>"; //finally close paragraph
          output_html = output_html.split("br_tag").join("<br/>");
          output_html = output_html.split("&lt;").join("<");
          output_html = output_html.split("&gt;").join(">");
          output_html = output_html.split("").join("›");
        }
        if (props.isSearch?.search) {
          output_html = output_html
            .split(props.isSearch?.search.toLowerCase())
            .join(
              `<span style='background-color:yellow;font-family:var(--font);'>${props.isSearch?.search}</span>`
            );
          let text = `<span style='line-height:${
            Number(articleBodyFontsize) + 10
          }px;color:#4a4949;background-color:yellow;font-family:var(--font);font-size:${articleBodyFontsize}px;'>${props.isSearch?.search
            .charAt(0)
            .toUpperCase()}${props.isSearch?.search.slice(1)}</span>`;
          output_html = output_html
            .split(
              props.isSearch?.search.charAt(0).toUpperCase() +
                props.isSearch?.search.slice(1)
            )
            .join(text);
        }
        return output_html;
      };
      // if (props.isSearch?.search) {

      parsedLinkedBody.current = htmlparser(articleViewDataLinked?.ArticleBody);
    }
  }, [articleViewDataLinked]);

  const getOpacity = (article, searchQuery) => {
    if (article.BlockType === "WORD") {
      const searchWords = searchQuery?.toLowerCase();

      if (article.Text) {
        if (searchWords?.length > article?.Text?.length) {
          if (searchWords?.includes(article?.Text?.toLowerCase())) {
            return 0.7;
          } else return 0;
        } else {
          if (
            article?.Text?.toLowerCase().includes(searchWords?.toLowerCase())
          ) {
            return 0.7;
          } else return 0;
        }
      }
    } else {
      return 0;
    }
  };
  useEffect(() => {
    if (
      imageUrl &&
      (activeTabName === "Photographs" || activeTabName === "Advertisement")
    ) {
      axios.get(imageUrl).then((response) => {
        setTextData(response.data);
        const fullImageDimension = response.data[0].Geometry.BoundingBox;
        setImageDimension({
          height: (fullImageDimension.Height * imageSizes.height) / 2,
          width: (fullImageDimension.Width * imageSizes.width) / 2,
          top: (fullImageDimension.Top * imageSizes.height) / 2,
          left: (fullImageDimension.Left * imageSizes.width) / 2,
        });
      });
    }
  }, [imageUrl, imageSizes, activeTabName]);

  useEffect(() => {
    if (props?.defaultInitialPubCode.type === "image") {
      return setIsRegional(true);
    } else {
      return setIsRegional(false);
    }
  }, [props?.defaultInitialPubCode]);
  // useEffect(() => {
  let toReplace = [];
  const searchLink = async (path, zone) => {
    let response = [];

    if (linkJsonData?.linkJson?.length > 0) {
      response = linkJsonData?.linkJson.filter(
        (v) => v.FromZoneID === zone.ZoneId
      );
    }

    return response;
  };
  const findLink = async (data) => {
    let linked_zones = [];

    if (data && Object.keys(data).length > 0) {
      const zones = articles?.PageContent?.filter(
        (v) => v.ArticleName === data.ArticleName
      );
      const linkToZones = zones?.[0]?.Zones?.filter(
        (v) => v.TagName === "LinkTo"
      );

      if (linkToZones?.length > 0) {
        for (let i = 0; i < linkToZones.length; i++) {
          const ele = linkToZones[i];
          let filtered_response = await searchLink(linkJsonURL, ele);

          if (filtered_response && filtered_response.length > 0) {
            linked_zones.push({
              zoneId: ele.ZoneId,
              pageLink: filtered_response[0].PageLink,
            });
          }
        }
        setTimeout(() => {
          const myFunc = (e) => {
            let jumpTo = props.dayIndexData.findIndex(
              (v) => v.PageName === e.target.className
            );
            let link = `${s3Source}/PublicationData/${props?.defaultInitialPubCode?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/PageThumb/${e.target?.className}.jpg?v=${filteredDate?.Timestamp}`;
            if (jumpTo !== -1) {
              pageClick(link, jumpTo);
            }
            props.toggleArticleViewModal();
            // window.responsiveVoice.cancel();
            Player?.pause();
            setPlayer(null);
            setSpeechRunning(false);
          };

          for (let i = 0; i < linked_zones.length; i++) {
            const element = linked_zones[i];
            let domElement = document.getElementById(`${element.zoneId}`);

            if (domElement) {
              domElement.addEventListener("click", myFunc);
            }
          }
        }, 800);
        // } // check
      }
    }
  };
  function myFunc(e) {
    let jumpTo = props.dayIndexData.findIndex(
      (v) => v.PageName === e.target.className
    );
    let link = `${s3Source}/PublicationData/${props?.defaultInitialPubCode?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/PageThumb/${e.target?.className}.jpg?v=${filteredDate?.Timestamp}`;
    if (jumpTo !== -1) pageClick(link, jumpTo);
    props.toggleArticleViewModal();
    // window.responsiveVoice.cancel();
    Player?.pause();

    setPlayer(null);
    setSpeechRunning(false);
  }
  useEffect(() => {
    setAllData(articleViewData);
  }, [props?.toggleArticleViewModal]);

  function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      displayToastr("Copied successfully", "Copied", 5000, "success");
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
      displayToastr("Could not copy", "Copy Link", 5000, "error");
    }

    document.body.removeChild(textArea);
  }
  const shareArticle = async (shareType) => {
    let path = "";
    // http://test.timesgroup.com/article-share/https://asset.harnscloud.com/PublicationData/TOI/toiac/2022/01/20/ArticleZoneJson/019/20_01_2022_019_010_toiac.json
    // let path_prefix = `${s3Source}/PublicationData/${props?.defaultInitialPubCode.publicationcode}/${props?.defaultInitialEditionName.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/ArticleZoneJson/`;

    // let searched = await getContinuedArticles(
    //   linkJsonPath ??
    //     `${s3Source}/PublicationData/${props?.defaultInitialPubCode?.publicationcode}/${props?.defaultInitialEditionName?.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/LinkJson/${props?.dateArray[2]}_${props?.dateArray[1]}_${props?.dateArray[0]}_${props?.defaultInitialEditionName?.editionid}.json`,
    //   articleName,
    //   path_prefix
    // );
    // let articleName = allData?.ArticleName;

    let articleName =
      articleViewData?.ArticleName ??
      `${advertisement.current}_${props?.defaultInitialEditionName?.editionid}`;
    let pageNumber = articleName?.split("_")?.[3];

    // path = `${path_prefix}${pageNumber}/${articleName}.json`;
    path =
      !isRegional || props.advertisement
        ? `${articleName}_${props?.defaultInitialPubCode?.publicationcode}`
        : `${articleViewData?.ArticleName ?? articleViewName}_${
            props?.defaultInitialPubCode?.publicationcode
          }`;

    switch (shareType) {
      case "copy":
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(
            `${window.location.origin}/maharashtratimes/article-share?article=${path}`
          );
          return;
        }
        navigator.clipboard.writeText(
          `${window.location.origin}/maharashtratimes/article-share?article=${path}`
        );
        displayToastr("Copied successfully", "Copied", 5000, "success");
        break;

      default:
        break;
    }
  };

  const pathForShare = useMemo(() => {
    let path = "";
    let path_prefix = `${s3Source}/PublicationData/${props?.defaultInitialPubCode.publicationcode}/${props?.defaultInitialEditionName.editionid}/${props?.dateArray[0]}/${props?.dateArray[1]}/${props?.dateArray[2]}/ArticleZoneJson/`;
    // let articleName = allData && allData.ArticleName;
    let articleName =
      articleViewData?.ArticleName ??
      `${advertisement.current}_${props?.defaultInitialEditionName?.editionid}`;
    let pageNumber = articleName?.split("_")[3];
    // path = `${path_prefix}${pageNumber}/${articleName}.json`;
    path =
      !isRegional || props.advertisement
        ? `${articleName}_${props?.defaultInitialPubCode?.publicationcode}`
        : `${articleViewData?.ArticleName ?? articleViewName}_${
            props?.defaultInitialPubCode?.publicationcode
          }`;
    setSharingUrl(
      `${window.location.origin}/maharashtratimes/article-share?article=${path}`
    );
    return `${window.location.origin}/maharashtratimes/article-share?article=${path}`;
  }, [
    props?.defaultInitialPubCode.publicationcode,
    props?.defaultInitialEditionName.editionid,
    props?.dateArray,
    allData,
  ]);

  const removeJunk = (str) => {
    return str
      ?.split("<br>`<br>")
      .join("₹")
      .split("<br>`")
      .join("₹")
      .split("`<br>")
      .join("₹")
      .split("`")
      .join("₹")
      .split("<br>¤<br>")
      .join("€")
      .split("<br>¤")
      .join("€")
      .split("¤<br>")
      .join("€")
      .split("¤")
      .join("€")
      ?.split("<br>")
      ?.join("<br/>")
      .split("&lt")
      .join("<")
      .split("&gt;")
      .join(">")
      .split("")
      .join("›");
  };
  const viewOnPage = async () => {
    if (props?.isSearch?.articleName) {
      let split = props.isSearch.articleName.split("_");
      let pageNumber = parseInt(split[3]);
      let publication = {};
      let edition = {};

      for (let i = 0; i < publicationJson.length; i++) {
        const ele = publicationJson[i];
        let edn = ele.editions.filter((v) => v.editionid == split[5]);
        if (edn.length > 0) {
          publication = { ...ele, index: i };
          edition = {
            ...edn[0],
            index: ele.editions.findIndex((v) => v.editionid == split[5]),
          };
        }
      }
      let pageName =
        props.isSearch.articleName.split("_").slice(0, 4).join(`_`) +
        `_${split[5]}`;
      await JSCookie.set("mtpubindex", publication.index, { expires: 3 });
      await JSCookie.set("mtedindex", edition.index, { expires: 3 });
      await JSCookie.set("mteddate", split.slice(0, 3).join("-"));
      await JSCookie.set("currPg", pageNumber);
      await props.closeModals();

      await getPublicationsData(false, pageName);
      history.push(
        `/${publication?.publicationname
          ?.split(" ")
          .join("-")
          .toLowerCase()}/${edition?.editionname
          ?.split(" ")
          .join("-")
          .toLowerCase()}`
      );
    }
  };
  useEffect(() => {
    let trimmedBody = articleViewData?.ArticleBody?.slice(0, 30);
    let location = trimmedBody?.split(":")[0];
    if (location?.length < 25) {
      setExtractLocation(location?.trim());
    } else {
      setExtractLocation("");
    }
  }, [articleViewData?.ArticleBody]);

  useEffect(() => {
    let trimmedBody = articleViewDataLinked?.ArticleBody?.slice(0, 30);
    let location = trimmedBody?.split(":")[0];
    if (location?.length < 25) {
      setlinkExtractLocation(location?.trim());
    } else {
      setlinkExtractLocation("");
    }
  }, [articleViewDataLinked?.ArticleBody]);

  useEffect(() => {
    const getBannerData = async () => {
      const url = `${process.env.REACT_APP_CMS_URL}/banner/epaper/${process.env.REACT_APP_CHANNEL_ID}`;
      await axios.get(url).then(async (res) => {
        res?.data?.data.map((data) => {
          data.img = `${process.env.REACT_APP_S3_IMAGE_PATH}/${process.env.REACT_APP_CHANNEL_NAME}${data.img}`;
        });

        await setArticleBanner(
          res?.data?.data.filter((v) => v.label === "Desktop Article")[0]
        );
        await setArticleBannerMob(
          res?.data?.data.filter((v) => v.label === "Mobile Article")[0]
        );
      });
    };
    getBannerData();
  }, []);

  useEffect(() => {
    ReactGA.event("page_view", {
      event: "page_view",
      category: "page_view",
      action: "page_view",
      label: "page_view",
      section_name: currentPage?.PageTitle ?? "", //required
      page_no: currentPage?.pageNumber ?? "", //required
      print_view: true,
      digital_view: false,
      publication_name: initialPublication?.publicationname,
      edition_name: initialEdition?.editionname,
      article_title: "",
      article_author: "",
    });
    window.dataLayer.push({
      event: "page_view",
      eventCategory: initialEdition?.editionname,
      eventAction: "page_view",
      eventLabel: "page_view",
    });
  }, []);
  return (
    <React.Fragment>
      <Container fluid="true" className="fff ff4">
        {desktopView ? (
          <OutsideClickHandler
            onOutsideClick={() => {
              props?.toggleArticleViewModal();
            }}
          >
            <div className="fff ff4">
              <ModalHeader
                className="pb-1 "
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "1000",
                  backgroundColor: "#fff",
                  display: "block",
                  border: "0px !important",
                }}
              >
                <Row>
                  {props.showViewOnPage && (
                    <Col className="p-0 ">
                      <div
                        className="badge p-1 mb-4"
                        style={{
                          textDecoration: "underline",
                          color: "var(--primary)",
                          cursor: "pointer",
                        }}
                        onClick={async () => {
                          viewOnPage();
                        }}
                      >
                        View on page
                      </div>
                    </Col>
                  )}
                  {/* <Col className="p-0">
                    {!props.advertisement && (
                      <>
                        <Tooltip title="All Views">
                          <div
                            className="badge p-1 text-white mb-4"
                            style={{
                              backgroundColor: "var(--primary)",
                            }}
                          >
                            Views: <span>{views}</span>
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </Col> */}
                  {/* <Col>
                    {!props.advertisement && (
                      <>
                        <Tooltip title="All Shares">
                          <div
                            className="badge p-1 text-white mb-4 ml-2"
                            style={{
                              backgroundColor: "var(--primary)",
                            }}
                          >
                            Shares: <span>{totalShareCount}</span>
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </Col> */}
                  <Col
                    className=" pr-4 pt-2 text-align-end"
                    onClick={() => {
                      articleEvents(
                        "article_previous",
                        "article_previous",
                        "article_previous",
                        "article_previous"
                      );
                      props.handlePrevArticle(
                        props.isSecondPageFit2page
                          ? props.twoPageImage2Name
                          : props.twoPageImage1Name ??
                              props.currentPage?.PageName
                      );
                      // window.responsiveVoice.cancel();
                      Player?.pause();
                      setPlayer(null);
                      setSpeechRunning(false);
                    }}
                  >
                    {props.currArticleIndex > 0 && !props?.showViewOnPage && (
                      <ArrowBackOutlinedIcon className="cursor-pointer " />
                    )}
                    {currArticleIndex > 0 && !props?.showViewOnPage && (
                      <Tooltip title="Previous Article">
                        <ArrowBackOutlinedIcon className="cursor-pointer " />
                      </Tooltip>
                    )}
                  </Col>
                  <Col sm="6" md="5" lg="5" xl="5" className="mx-2">
                    <Row className="justify-content-center pb-2 header-article-fixed  ">
                      <Row>
                        {currentPublication?.type !== "image" &&
                        !isRegional &&
                        !props.advertisement &&
                        !hasInfoZone ? (
                          <Col
                            style={{
                              color: props.themeColor,
                            }}
                            onClick={() => {
                              toggleView();
                            }}
                            className=" cursor-pointer2"
                          >
                            {articleTextView ? (
                              <div className="pt-2">
                                <Tooltip title="Image">
                                  <Image />
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="pt-2">
                                <Tooltip title="Text">
                                  <FileText />
                                </Tooltip>
                              </div>
                            )}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col
                          className="cursor-pointer2 pl-3, pt-2"
                          style={{ color: props.themeColor }}
                          onClick={() => {
                            zoomEvents(
                              "zoom",
                              "zoom_in",
                              "zoom",
                              "top_navigation_article"
                            );
                            articleTextView && increaseFontSize();
                            zoomImageSize(30, 90);
                          }}
                        >
                          <Tooltip title="Zoom In">
                            <ZoomIn />
                          </Tooltip>
                        </Col>
                        <Col
                          className="cursor-pointer2 , pt-2"
                          style={{ color: props.themeColor }}
                          onClick={() => {
                            zoomEvents(
                              "zoom",
                              "zoom_out",
                              "zoom",
                              "top_navigation_article"
                            );
                            articleTextView && decreaseFontSize();
                            zoomImageSize(-30, -90);
                          }}
                        >
                          <Tooltip title="Zoom Out">
                            <ZoomOut />
                          </Tooltip>
                        </Col>
                        <Col
                          className="pt-2 cursor-pointer2"
                          style={{
                            color: props?.themeColor,
                          }}
                        >
                          <Tooltip title="Save Article">
                            <Download
                              onClick={() => {
                                saveImg();
                                downloadEvent(
                                  "download",
                                  "download",
                                  "download",
                                  "top_navigation_article"
                                );
                              }}
                            />
                          </Tooltip>
                        </Col>
                        {/* for future use */}
                        {currentPublication?.type !== "image" &&
                          !isRegional &&
                          !props.advertisement && (
                            <Col
                              className="pt-2 cursor-pointer2"
                              style={{
                                color: props.themeColor,
                              }}
                              onClick={async () => {
                                await setSpeechRunning(!speechRunning);
                                await synthesizeSpeech(TTS);
                              }}
                            >
                              {!speechRunning ? <VolumeX /> : <Volume2 />}
                            </Col>
                          )}
                        {/* <Col
                          className="pt-2 cursor-pointer2"
                          style={{ color: props.themeColor }}
                        >
                          <Tooltip title="Bookmark">
                          
                          <Bookmark />
                          </Tooltip>
                        </Col> */}

                        <Col
                          className="pt-2 cursor-pointer2"
                          style={{ color: props.themeColor }}
                          onClick={() => {
                            printEvents("print", "print", "print", "print");
                            print("article-view-card");
                          }}
                        >
                          <Tooltip title="Print">
                            <PrintIcon />
                          </Tooltip>
                        </Col>
                        <Col
                          style={{ color: props.themeColor }}
                          className="pt-2 cursor-pointer2"
                          onClick={toggle}
                        >
                          <Tooltip title="Share">
                            <Share2 />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                  <Col className="text-align-start pl-4 pt-2">
                    {currArticleIndex !== props?.propsArticles?.length - 1 &&
                      !props?.showViewOnPage && (
                        <Tooltip title="Next Article">
                          <ArrowForwardOutlinedIcon
                            onClick={() => {
                              articleEvents(
                                "article_next",
                                "article_next",
                                "article_next",
                                "article_next"
                              );
                              Player?.pause();
                              props.handleNextArticle(
                                props.isSecondPageFit2page
                                  ? props.twoPageImage2Name
                                  : props.twoPageImage1Name ??
                                      props.currentPage?.PageName
                              );
                              // window.responsiveVoice.cancel();
                              setPlayer(null);
                              setSpeechRunning(false);
                            }}
                            className="cursor-pointer "
                          />
                        </Tooltip>
                      )}
                  </Col>
                  <Col
                    className=" col-md-1"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title="Close">
                      <XCircle
                        className=""
                        onClick={() => {
                          articleEvents(
                            "article_close",
                            "article_close",
                            "article_close",
                            "article_close"
                          );
                          props.toggleArticleViewModal();
                          // window.responsiveVoice.cancel();
                          Player?.pause();
                          setPlayer(null);
                          setSpeechRunning(false);

                          props.setAd();
                          setRelatedArticleData([]);
                          setArticleViewDataLinked({});
                          setArticleViewData({});
                        }}
                        fontSize="large"
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </ModalHeader>

              <Row
                id={"article-scroll-container"}
                style={{
                  height: window.innerHeight - 110,
                  width: articleTextView ? window.innerWidth - 100 : "auto",
                }}
              >
                <Col md="9">
                  <Row
                    className="pr-3 justify-content-start"
                    style={{
                      position: "relative",
                      overflow: "scroll",
                      height: window.innerHeight - 120,
                    }}
                  >
                    {/* for future use if we need left ad with drawer */}
                    {/* {window.innerWidth > 1365 && (
                      <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={openArticleAd}
                        classes={{
                          paper: classes.drawerPaper,
                        }}
                      >
                        <div className="text-center pt-2">
                          <img
                            height="250px"
                            width="300px"
                            src="https://tpc.googlesyndication.com/simgad/6257432643947340338?sqp=4sqPyQQrQikqJwhfEAEdAAC0QiABKAEwCTgDQPCTCUgAUAFYAWBfcAJ4AcUBLbKdPg&rs=AOga4qmSvtuaRjmm9oCUJgslJPWV3sun7Q"
                            alt="add"
                          />
                        </div>
                        <div className="text-center pt-2">
                          <img
                            height="250px"
                            width="300px"
                            src="https://tpc.googlesyndication.com/simgad/6257432643947340338?sqp=4sqPyQQrQikqJwhfEAEdAAC0QiABKAEwCTgDQPCTCUgAUAFYAWBfcAJ4AcUBLbKdPg&rs=AOga4qmSvtuaRjmm9oCUJgslJPWV3sun7Q"
                            alt="add"
                          />
                        </div>
                        <div className="text-center pt-2">
                          <img
                            height="250px"
                            width="300px"
                            src="https://tpc.googlesyndication.com/simgad/6257432643947340338?sqp=4sqPyQQrQikqJwhfEAEdAAC0QiABKAEwCTgDQPCTCUgAUAFYAWBfcAJ4AcUBLbKdPg&rs=AOga4qmSvtuaRjmm9oCUJgslJPWV3sun7Q"
                            alt="add"
                          />
                        </div>
                      </Drawer>
                    )} */}
                    <Col md="12" className="">
                      {/* {window.innerWidth > 1365 && (
                        <div
                          className={
                            openArticleAd === true
                              ? "artcl-ad-close-btn"
                              : "artcl-ad-open-btn"
                          }
                          style={{ backgroundColor: props?.themeColor }}
                          onClick={() => handleDrawerAd()
                          }
                        >
                          <b
                            className={
                              openArticleAd === true
                                ? "artcl-ad-close-btn-text"
                                : "artcl-ad-open-btn-text"
                            }
                          >
                            {openArticleAd === true ? "CLOSE" : "ADS"}
                          </b>
                        </div>
                      )} */}
                      <Row className="justify-content-center mt-2">
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setIsOpen2(false);
                          }}
                        >
                          <Collapse isOpen={isOpen2}>
                            <Row className="pl-0 collapse-fixed-desktop">
                              <Card className="social-share-desktop">
                                <CardBody>
                                  <Row
                                    className="cursor-pointer"
                                    style={{ justifyContent: "space-around" }}
                                  >
                                    <WhatsappShareButton
                                      title={
                                        articleViewData &&
                                        articleViewData?.ArticleTitle?.split(
                                          "`"
                                        )
                                          .join("₹")
                                          .split("<br>`<br>")
                                          .join("₹")
                                          .split("<br/>`<br/>")
                                          .join("₹")
                                          .split("<br/>₹<br/>")
                                          .join("₹")
                                          .split("<br>`")
                                          .join("₹")
                                          .split("`<br>")
                                          .join("₹")
                                          .split("`")
                                          .join("₹")
                                          .split("<br>¤<br>")
                                          .join("€")
                                          .split("<br>¤")
                                          .join("€")
                                          .split("¤<br>")
                                          .join("€")
                                          .split("¤")
                                          .join("€")
                                          ?.split("<br>")
                                          ?.join("<br/>")
                                          .split("&lt;")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">")
                                      }
                                      url={sharingUrl && sharingUrl}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "whatsapp"
                                        );
                                      }}
                                    >
                                      <Tooltip title="Whatsapp share">
                                        <WhatsAppIcon
                                          onClick={() =>
                                            shareEvents(
                                              "share",
                                              "share",
                                              "share",
                                              "whatsapp"
                                            )
                                          }
                                          fontSize="medium"
                                          style={{ color: "#25D366" }}
                                        />
                                      </Tooltip>
                                    </WhatsappShareButton>

                                    <FacebookShareButton
                                      quote={
                                        articleViewData &&
                                        articleViewData?.ArticleTitle?.split(
                                          "`"
                                        )
                                          .join("₹")
                                          .split("<br>`<br>")
                                          .join("₹")
                                          .split("<br/>`<br/>")
                                          .join("₹")
                                          .split("<br/>₹<br/>")
                                          .join("₹")
                                          .split("<br>`")
                                          .join("₹")
                                          .split("`<br>")
                                          .join("₹")
                                          .split("`")
                                          .join("₹")
                                          .split("<br>¤<br>")
                                          .join("€")
                                          .split("<br>¤")
                                          .join("€")
                                          .split("¤<br>")
                                          .join("€")
                                          .split("¤")
                                          .join("€")
                                          ?.split("<br>")
                                          ?.join("<br/>")
                                          .split("&lt;")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">")
                                      }
                                      url={sharingUrl && sharingUrl}
                                      beforeOnClick={() => {}}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "facebook"
                                        );
                                      }}
                                    >
                                      <Tooltip title="Facebook share">
                                        <FacebookIcon
                                          onClick={() =>
                                            shareEvents(
                                              "share",
                                              "share",
                                              "share",
                                              "facebook"
                                            )
                                          }
                                          fontSize="medium"
                                          style={{ color: "#4267B2" }}
                                        />
                                      </Tooltip>
                                    </FacebookShareButton>

                                    <TwitterShareButton
                                      title={
                                        articleViewData &&
                                        articleViewData?.ArticleTitle?.split(
                                          "`"
                                        )
                                          .join("₹")
                                          .split("<br>`<br>")
                                          .join("₹")
                                          .split("<br/>`<br/>")
                                          .join("₹")
                                          .split("<br/>₹<br/>")
                                          .join("₹")
                                          .split("<br>`")
                                          .join("₹")
                                          .split("`<br>")
                                          .join("₹")
                                          .split("`")
                                          .join("₹")
                                          .split("<br>¤<br>")
                                          .join("€")
                                          .split("<br>¤")
                                          .join("€")
                                          .split("¤<br>")
                                          .join("€")
                                          .split("¤")
                                          .join("€")
                                          ?.split("<br>")
                                          ?.join("<br/>")
                                          .split("&lt;")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">")
                                      }
                                      url={pathForShare}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "twitter"
                                        );
                                      }}
                                    >
                                      <Tooltip title="Twitter share">
                                        <TwitterIcon
                                          onClick={() =>
                                            shareEvents(
                                              "share",
                                              "share",
                                              "share",
                                              "twitter"
                                            )
                                          }
                                          fontSize="medium"
                                          style={{ color: "#1DA1F2" }}
                                        />
                                      </Tooltip>
                                    </TwitterShareButton>
                                    <EmailShareButton
                                      className="pb-0"
                                      subject={
                                        articleViewData &&
                                        articleViewData?.ArticleTitle?.split(
                                          "<br>`<br>"
                                        )
                                          .join("₹")
                                          .split("<br>`")
                                          .join("₹")
                                          .split("`<br>")
                                          .join("₹")
                                          .split("`")
                                          .join("₹")
                                          .split("<br>¤<br>")
                                          .join("€")
                                          .split("<br>¤")
                                          .join("€")
                                          .split("¤<br>")
                                          .join("€")
                                          .split("¤")
                                          .join("€")
                                          ?.split("<br>")
                                          ?.join("<br/>")
                                          .split("&lt;")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">")
                                      }
                                      url={pathForShare}
                                      onShareWindowClose={() => {
                                        updateInsight(
                                          "share",
                                          articleViewData,
                                          "email"
                                        );
                                      }}
                                    >
                                      <Tooltip title="E-mail share">
                                        <Mail
                                          onClick={() =>
                                            shareEvents(
                                              "share",
                                              "share",
                                              "share",
                                              "email"
                                            )
                                          }
                                          fontSize="small"
                                          style={{ color: props.themeColor }}
                                        />
                                      </Tooltip>
                                    </EmailShareButton>
                                    <Tooltip title="Copy Link">
                                      <Copy
                                        onClick={() => {
                                          shareEvents(
                                            "share",
                                            "share",
                                            "share",
                                            "copy_link"
                                          );
                                          shareArticle("copy");
                                        }}
                                        fontSize="small"
                                        style={{ color: props.themeColor }}
                                      />
                                    </Tooltip>
                                    <Tooltip title="Close">
                                      <X
                                        color="red"
                                        onClick={() => {
                                          setIsOpen2(!isOpen2);
                                        }}
                                      />
                                    </Tooltip>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Row>
                          </Collapse>
                        </OutsideClickHandler>
                      </Row>
                      <Row className="justify-content-center mt-2">
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            setIsOpenFeedBackModal(false);
                          }}
                        >
                          <Collapse isOpen={isOpenFeedBackModal}>
                            <Row className="pl-0 collapse-fixed-desktop">
                              <Card className="editorial-mail-collp">
                                <CardBody style={{ overflow: "auto" }}>
                                  <MailModalFeedBack
                                    to={extractEmail(articleViewData?.Author)}
                                    subject={
                                      articleViewData &&
                                      articleViewData?.ArticleTitle?.split(
                                        "<br>`<br>"
                                      )
                                        .join("₹")
                                        .split("<br>`")
                                        .join("₹")
                                        .split("`<br>")
                                        .join("₹")
                                        .split("`")
                                        .join("₹")
                                        .split("<br>¤<br>")
                                        .join("€")
                                        .split("<br>¤")
                                        .join("€")
                                        .split("¤<br>")
                                        .join("€")
                                        .split("¤")
                                        .join("€")
                                        ?.split("<br>")
                                        ?.join("<br/>")
                                        .split("&lt;")
                                        .join("<")
                                        .split("&gt;")
                                        .join(">")
                                    }
                                  />
                                </CardBody>
                              </Card>
                            </Row>
                          </Collapse>
                        </OutsideClickHandler>
                      </Row>
                      <Card id="article-view-card" className="border-0 ">
                        {loading ? (
                          <Spinner className="loaderArticleCard" />
                        ) : articleTextView && !isRegional ? (
                          <div className="article-view-desktop">
                            {articleViewData?.Blurb && (
                              <div
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                  width: "fit-content",
                                }}
                              >
                                <h6
                                  style={{
                                    fontSize: `${articleBlurbFontsize}px`,
                                  }}
                                  id="article-card-blurb"
                                  className="p-1"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData.Blurb?.split("<br>`<br>")
                                        .join("₹")
                                        .split("<br/>`<br/>")
                                        .join("₹")
                                        .split("<br/>₹<br/>")
                                        .join("₹")
                                        .split("<br>`")
                                        .join("₹")
                                        .split("`<br>")
                                        .join("₹")
                                        .split("`")
                                        .join("₹")
                                        .split("<br>¤<br>")
                                        .join("€")
                                        .split("<br>¤")
                                        .join("€")
                                        .split("¤<br>")
                                        .join("€")
                                        .split("¤")
                                        .join("€")
                                        ?.split("<br>")
                                        ?.join("<br/>")
                                        .split("&lt")
                                        .join("<")
                                        .split("&gt;")
                                        .join(">"),
                                  }}
                                ></h6>
                              </div>
                            )}
                            {relatedArticleData &&
                              articleViewData?.Blurb?.length < 1 &&
                              relatedArticleData?.length > 0 &&
                              relatedArticleData?.map((item, ind) => {
                                return (
                                  <>
                                    {item?.Blurb && (
                                      <div
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          width: "fit-content",
                                        }}
                                      >
                                        <h6
                                          style={{
                                            fontSize: `${articleBlurbFontsize}px`,
                                          }}
                                          id="article-card-blurb"
                                          className="p-1"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item &&
                                              item.Blurb?.split("<br>`<br>")
                                                .join("₹")
                                                .split("<br/>`<br/>")
                                                .join("₹")
                                                .split("<br/>₹<br/>")
                                                .join("₹")
                                                .split("<br>`")
                                                .join("₹")
                                                .split("`<br>")
                                                .join("₹")
                                                .split("`")
                                                .join("₹")
                                                .split("<br>¤<br>")
                                                .join("€")
                                                .split("<br>¤")
                                                .join("€")
                                                .split("¤<br>")
                                                .join("€")
                                                .split("¤")
                                                .join("€")
                                                ?.split("<br>")
                                                ?.join("<br/>")
                                                .split("&lt")
                                                .join("<")
                                                .split("&gt;")
                                                .join(">"),
                                          }}
                                        ></h6>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            <div>
                              <h1
                                id="article-card-title"
                                style={{
                                  fontWeight: "700",
                                  fontSize: `${articleHeadingFontsize}px`,
                                  fontFamily: "fff ff4 !important",
                                  lineHeight: `${
                                    articleHeadingFontsize + 18
                                  }px`,
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">"),
                                }}
                              ></h1>
                              {articleViewData?.ColumnTitle && (
                                <h6
                                  style={{
                                    fontSize: `${
                                      Number(articleBlurbFontsize) + 10
                                    }px`,
                                    fontWeight: "normal",
                                    fontStyle: "italic",
                                    color: "#4a4949",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData?.ColumnTitle?.split(
                                        "<br>`<br>"
                                      )
                                        .join("₹")
                                        .split("<br>`")
                                        .join("₹")
                                        .split("`<br>")
                                        .join("₹")
                                        .split("`")
                                        .join("₹")
                                        .split("<br>¤<br>")
                                        .join("€")
                                        .split("<br>¤")
                                        .join("€")
                                        .split("¤<br>")
                                        .join("€")
                                        .split("¤")
                                        .join("€")
                                        ?.split("<br>")
                                        ?.join("<br/>")
                                        .split("&lt")
                                        .join("<")
                                        .split("&gt;")
                                        .join(">"),
                                  }}
                                ></h6>
                              )}
                              {articleViewData && (
                                <hr style={{ borderTop: "2px solid black" }} />
                              )}
                              {articleViewData?.Author && (
                                <b
                                  style={{
                                    fontSize: `${articleBlurbFontsize}px`,
                                    fontStyle: "italic",
                                    color: articleAuthorEmail.current
                                      ? "#0000ee"
                                      : "#000",
                                    textDecoration: articleAuthorEmail.current
                                      ? "underline"
                                      : "none",
                                    fontWeight: articleAuthorEmail.current
                                      ? "bold"
                                      : "normal",
                                    fontFamily: "LibreBaskerville",
                                    cursor: articleAuthorEmail.current
                                      ? "pointer"
                                      : "auto",
                                  }}
                                  onClick={() => {
                                    // if (articleAuthorEmail.current) {
                                    // window.open(
                                    //   "mailto:" +
                                    //     extractEmail(articleViewData.Author),
                                    //   "mail"
                                    // );

                                    setIsOpenFeedBackModal(true);
                                    // }
                                  }}
                                  id="article-card-author"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData?.Author &&
                                      extractEmail(articleViewData.Author),
                                  }}
                                ></b>
                              )}
                              <br />
                              {articleAuthorEmail.current === true && <br />}

                              {articleViewData?.Author && (
                                <p
                                  style={{
                                    fontSize: `${articleBlurbFontsize}px`,
                                    fontStyle: "italic",
                                    color: "#000",
                                    textDecoration: "none",
                                    fontWeight: "normal",
                                    fontFamily: "LibreBaskerville",
                                    cursor: "auto",
                                    marginBottom: "0px",
                                  }}
                                  id="article-card-author"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData?.Author ===
                                        "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                        ? "TIMES NEWS NETWORK"
                                        : articleViewData?.Author?.replaceAll(
                                            extractEmail(
                                              articleViewData?.Author
                                            ),
                                            ""
                                          ),
                                  }}
                                ></p>
                              )}
                              {articleViewData?.ArticleEmail && (
                                <a
                                  href={
                                    "mailto:" + articleViewData?.ArticleEmail
                                  }
                                >
                                  <i
                                    style={{
                                      fontSize: `${
                                        Number(articleBodyFontsize) - 4
                                      }px`,
                                      color: "#0000ee",
                                      textDecoration: "underline",
                                      fontWeight: "bold",
                                      fontFamily: "LibreBaskerville",
                                    }}
                                    id="article-card-mail"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewData &&
                                        articleViewData?.ArticleEmail,
                                    }}
                                  ></i>
                                </a>
                              )}
                              {articleViewData &&
                                articleViewData?.ArticleSource && (
                                  <b
                                    style={{
                                      fontSize: `${articleBodyFontsize}px`,
                                      color: "#4a4949",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewData &&
                                        articleViewData.ArticleSource ===
                                          "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                          ? "TIMES NEWS NETWORK"
                                          : articleViewData?.Author?.split(
                                              "<br>"
                                            )
                                              ?.join(" ")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                    }}
                                  ></b>
                                )}
                              {articleViewData &&
                                articleViewData?.ArticleLocation && (
                                  <p>
                                    <br />
                                    <b
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        fontWeight: "bold",
                                        color: "#4a4949",
                                        fontFamily: "LibreBaskerville",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewData &&
                                          articleViewData?.ArticleLocation,
                                      }}
                                    ></b>
                                  </p>
                                )}
                              {/* <div className="ad-wrapper">
                              <CardImg src={article_ad} />
                            </div> */}

                              <p
                                style={{
                                  fontSize: `${articleBodyFontsize + 1}px `,
                                  lineHeight: `${
                                    Number(articleBodyFontsize) + 15
                                  }px`,
                                  color: "#4a4949",
                                }}
                                id="articlecardpara"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    parsedBody.current
                                      ?.replace(
                                        extractLocation + ":",
                                        `<b>${extractLocation} : </b>`
                                      )
                                      ?.split("")
                                      .join("›")
                                      .split("<br>`<br>")
                                      .join("₹")
                                      .split("<br/>`<br/>")
                                      .join("₹")
                                      .split("<br/>₹<br/>")
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                      .split("googleAds")?.[0],
                                }}
                                className="mt-2 mb-1"
                              />
                              {articleViewData &&
                                Object.keys(articleViewData)?.length > 0 &&
                                localStorage.getItem("contentRec") ===
                                  "true" && (
                                  <div
                                    style={{
                                      width: 300,
                                      height: 250,

                                      float: desktopView ? "left" : "none",
                                      margin: desktopView
                                        ? "auto"
                                        : "0px 20px 0px 0px",
                                    }}
                                    className={desktopView && "mr-3"}
                                  >
                                    {googleAds()}
                                  </div>
                                )}
                              <span
                                style={{
                                  fontSize: `${articleBodyFontsize + 1}px `,
                                  lineHeight: `${
                                    Number(articleBodyFontsize) + 15
                                  }px`,
                                  color: "#4a4949",
                                  fontFamily: "LibreBaskerville",
                                }}
                                id="articlecardpara"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    parsedBody.current
                                      .split("")
                                      .join("›")
                                      .split("<br>`<br>")
                                      .join("₹")
                                      .split("<br/>`<br/>")
                                      .join("₹")
                                      .split("<br/>₹<br/>")
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                      .split("googleAds")?.[1],
                                }}
                                className="mt-2 mb-1"
                              >
                                {/* November 7, 2020 */}
                                {/* <br /> */}
                              </span>
                              {relatedArticleData &&
                                relatedArticleData?.length > 0 &&
                                relatedArticleData?.map((item, ind) => {
                                  return (
                                    <div
                                      className={"greyBox"}
                                      key={item?.ArticleTitle}
                                    >
                                      <div>
                                        <h1
                                          id="article-card-title"
                                          style={{
                                            fontWeight: "700",
                                            fontSize: `${
                                              articleHeadingFontsize - 10
                                            }px`,
                                            fontFamily: "fff ff4 !important",
                                            lineHeight: `${
                                              articleHeadingFontsize + 10
                                            }px`,
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item &&
                                              item?.ArticleTitle?.split(
                                                "<br>`<br>"
                                              )
                                                .join("₹")
                                                .split("<br>`")
                                                .join("₹")
                                                .split("`<br>")
                                                .join("₹")
                                                .split("`")
                                                .join("₹")
                                                .split("<br>¤<br>")
                                                .join("€")
                                                .split("<br>¤")
                                                .join("€")
                                                .split("¤<br>")
                                                .join("€")
                                                .split("¤")
                                                .join("€")
                                                ?.split("<br>")
                                                ?.join("<br/>")
                                                .split("&lt;")
                                                .join("<")
                                                .split("&gt;")
                                                .join(">"),
                                          }}
                                        />
                                        {item?.ColumnTitle && (
                                          <h6
                                            style={{
                                              fontSize: `${
                                                Number(articleBlurbFontsize) +
                                                10
                                              }px`,
                                              fontWeight: "normal",
                                              fontStyle: "italic",
                                              color: "#4a4949",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item &&
                                                item?.ColumnTitle?.split(
                                                  "<br>`<br>"
                                                )
                                                  .join("₹")
                                                  .split("<br>`")
                                                  .join("₹")
                                                  .split("`<br>")
                                                  .join("₹")
                                                  .split("`")
                                                  .join("₹")
                                                  .split("<br>¤<br>")
                                                  .join("€")
                                                  .split("<br>¤")
                                                  .join("€")
                                                  .split("¤<br>")
                                                  .join("€")
                                                  .split("¤")
                                                  .join("€")
                                                  ?.split("<br>")
                                                  ?.join("<br/>")
                                                  .split("&lt")
                                                  .join("<")
                                                  .split("&gt;")
                                                  .join(">"),
                                            }}
                                          ></h6>
                                        )}
                                        {item && (
                                          <hr
                                            style={{
                                              borderTop: "2px solid black",
                                            }}
                                          />
                                        )}

                                        {item && item?.ArticleLocation && (
                                          <b
                                            style={{
                                              fontSize: `${articleBodyFontsize}px`,
                                              fontWeight: "bold",
                                              color: "#4a4949",
                                              fontFamily: "LibreBaskerville",
                                            }}
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item && item?.ArticleLocation,
                                            }}
                                          ></b>
                                        )}

                                        <p
                                          style={{
                                            fontSize: `${
                                              articleBodyFontsize + 1
                                            }px `,
                                            lineHeight: `${
                                              Number(articleBodyFontsize) + 15
                                            }px`,
                                            color: "#4a4949",
                                          }}
                                          id="articlecardpara"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              item &&
                                              item?.ArticleBody?.replace(
                                                extractLocation + ":",
                                                `<b>${extractLocation} : </b>`
                                              )
                                                .split("")
                                                .join("›")
                                                .split("<br>`<br>")
                                                .join("₹")
                                                .split("<br/>`<br/>")
                                                .join("₹")
                                                .split("<br/>₹<br/>")
                                                .join("₹")
                                                .split("<br>`")
                                                .join("₹")
                                                .split("`<br>")
                                                .join("₹")
                                                .split("`")
                                                .join("₹")
                                                .split("<br>¤<br>")
                                                .join("€")
                                                .split("<br>¤")
                                                .join("€")
                                                .split("¤<br>")
                                                .join("€")
                                                .split("¤")
                                                .join("€")
                                                ?.split("<br>")
                                                ?.join("<br/>")
                                                .split("&lt")
                                                .join("<")
                                                .split("&gt;")
                                                .join(">")
                                                ?.split("googleAdDivHere")
                                                .join(" "),
                                          }}
                                          className="mt-2"
                                        >
                                          {/* November 7, 2020 */}
                                          {/* <br /> */}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                })}
                              {/* end */}
                              {/* linked articleViewData */}
                              {articleViewDataLinked &&
                                Object.keys(articleViewDataLinked).length >
                                  0 && (
                                  <div className="my-3">
                                    <h1
                                      id="article-card-title"
                                      style={{
                                        fontWeight: "700",
                                        fontSize: `${articleHeadingFontsize}px`,
                                        fontFamily: "fff ff4 !important",
                                        lineHeight: `${
                                          articleHeadingFontsize + 18
                                        }px`,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked?.ArticleTitle?.split(
                                            "<br>`<br>"
                                          )
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">"),
                                      }}
                                    />

                                    {articleViewDataLinked?.ColumnTitle && (
                                      <h6
                                        style={{
                                          fontSize: `${
                                            Number(articleBlurbFontsize) + 10
                                          }px`,
                                          fontWeight: "normal",
                                          fontStyle: "italic",
                                          color: "#4a4949",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            articleViewDataLinked &&
                                            articleViewDataLinked?.ColumnTitle?.split(
                                              "<br>`<br>"
                                            )
                                              .join("₹")
                                              .split("<br>`")
                                              .join("₹")
                                              .split("`<br>")
                                              .join("₹")
                                              .split("`")
                                              .join("₹")
                                              .split("<br>¤<br>")
                                              .join("€")
                                              .split("<br>¤")
                                              .join("€")
                                              .split("¤<br>")
                                              .join("€")
                                              .split("¤")
                                              .join("€")
                                              ?.split("<br>")
                                              ?.join("<br/>")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                        }}
                                      ></h6>
                                    )}
                                    {articleViewDataLinked && (
                                      <hr
                                        style={{ borderTop: "2px solid black" }}
                                      />
                                    )}

                                    {articleViewDataLinked &&
                                      articleViewDataLinked?.ArticleLocation && (
                                        <b
                                          style={{
                                            fontSize: `${articleBodyFontsize}px`,
                                            fontWeight: "bold",
                                            color: "#4a4949",
                                            fontFamily: "LibreBaskerville",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              articleViewDataLinked &&
                                              articleViewDataLinked?.ArticleLocation,
                                          }}
                                        ></b>
                                      )}

                                    <p
                                      style={{
                                        fontSize: `${
                                          articleBodyFontsize + 1
                                        }px `,
                                        lineHeight: `${
                                          Number(articleBodyFontsize) + 15
                                        }px`,
                                        color: "#4a4949",
                                      }}
                                      id="articlecardpara"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          parsedLinkedBody?.current
                                            ?.replace(
                                              linkextractLocation + ":",
                                              `<b>${linkextractLocation} :</b>`
                                            )
                                            ?.split("<br>`<br>")
                                            .join("₹")
                                            .split("<br/>`<br/>")
                                            .join("₹")
                                            .split("<br/>₹<br/>")
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">")
                                            ?.split("googleAdDivHere")
                                            .join(" "),
                                      }}
                                      className="mt-2"
                                    >
                                      {/* November 7, 2020 */}
                                      {/* <br /> */}
                                    </p>
                                  </div>
                                )}
                              <Row
                                className=" mb-3"
                                style={{
                                  // width: "fit-content",
                                  // overflowX: "scroll",
                                  justifyContent: "left",
                                  marginTop:
                                    articleViewData?.ArticleBody?.length > 0
                                      ? "1rem"
                                      : "0",
                                }}
                              >
                                {articleViewData &&
                                articleViewData?.ArticlePhotographs &&
                                articleViewData?.ArticlePhotographs.length
                                  ? articleViewData?.ArticlePhotographs.map(
                                      (item, index) => {
                                        let photo =
                                          item?.Photograph?.split("_");
                                        return (
                                          <Col
                                            sm="12"
                                            md="12"
                                            lg="12"
                                            xl="12"
                                            key={
                                              item?.Photograph + index + "ll"
                                            }
                                          >
                                            <img
                                              onError={(event) => {
                                                event.target.style.display =
                                                  "none";
                                                setIsPhotoError(true);
                                              }}
                                              onLoad={() => {
                                                if (item?.ImageCaption) {
                                                  setPhotographSize({
                                                    width:
                                                      photographRef?.current
                                                        ?.naturalWidth,
                                                    height: "auto",
                                                  });
                                                }
                                              }}
                                              crossOrigin="*"
                                              ref={
                                                item.ImageCaption !== undefined
                                                  ? photographRef
                                                  : undefined
                                              }
                                              src={
                                                photo[photo.length - 1] ===
                                                props?.defaultInitialEditionName
                                                  ?.editionid
                                                  ? `${s3Source}/PublicationData/${
                                                      props
                                                        ?.defaultInitialPubCode
                                                        ?.publicationcode
                                                    }/${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }/${props?.dateArray[0]}/${
                                                      props?.dateArray[1]
                                                    }/${
                                                      props?.dateArray[2]
                                                    }/Photographs/${
                                                      item?.Photograph?.split(
                                                        "_"
                                                      )[3]
                                                    }/${
                                                      item?.Photograph
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                                  : `${s3Source}/PublicationData/${
                                                      props
                                                        ?.defaultInitialPubCode
                                                        ?.publicationcode
                                                    }/${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }/${props?.dateArray[0]}/${
                                                      props?.dateArray[1]
                                                    }/${
                                                      props?.dateArray[2]
                                                    }/Photographs/${
                                                      item?.Photograph?.split(
                                                        "_"
                                                      )[3]
                                                    }/${item?.Photograph}_${
                                                      props
                                                        ?.defaultInitialEditionName
                                                        ?.editionid
                                                    }.jpg?v=${
                                                      filteredDate?.Timestamp
                                                    }`
                                              }
                                              alt={
                                                articleViewData?.ArticleTitle
                                              }
                                            />

                                            {item?.ImageCaption &&
                                              !isPhotoError && (
                                                <p
                                                  style={{
                                                    fontSize: `${articleBodyFontsize}px `,
                                                    width: photographSize?.width
                                                      ? photographSize?.width
                                                      : "auto",
                                                    // minWidth:
                                                    //   articleViewData?.ArticlePhotographs &&
                                                    //   articleViewData
                                                    //     ?.ArticlePhotographs
                                                    //     ?.length > 1 &&
                                                    //   "fit-content",
                                                  }}
                                                  className="image-caption m-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      item &&
                                                      removeJunk(
                                                        item?.ImageCaption
                                                      ),
                                                  }}
                                                />
                                              )}
                                          </Col>
                                        );
                                      }
                                    )
                                  : ""}
                                {props.advertisement &&
                                  advertisement?.current && (
                                    <div className="text-center">
                                      <img
                                        width={`${Number(imgWidth) + 30}%`}
                                        crossOrigin="*"
                                        className=""
                                        src={`${s3Source}/PublicationData/${
                                          props?.defaultInitialPubCode
                                            ?.publicationcode
                                        }/${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }/${props?.dateArray[0]}/${
                                          props?.dateArray[1]
                                        }/${
                                          props?.dateArray[2]
                                        }/Advertisement/${
                                          advertisement.current?.split("_")[3]
                                        }/${advertisement.current}_${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }.jpg?v=${filteredDate?.Timestamp}`}
                                        alt={articleViewData?.ArticleTitle}
                                      />
                                    </div>
                                  )}
                              </Row>
                            </div>
                            {articleViewDataLinked &&
                              Object.keys(articleViewDataLinked).length > 0 && (
                                <Row
                                  className="justify-content-center mt-3"
                                  style={{
                                    width: "fit-content",
                                    overflow: "auto",
                                  }}
                                >
                                  {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticlePhotographs &&
                                  articleViewDataLinked?.ArticlePhotographs
                                    .length
                                    ? articleViewDataLinked?.ArticlePhotographs.map(
                                        (item, index) => {
                                          let photo =
                                            item?.Photograph?.split("_");
                                          return (
                                            <Col
                                              xs="12"
                                              sm="12"
                                              md="12"
                                              lg="12"
                                              xl="12"
                                              key={
                                                item?.Photograph + index + "ll"
                                              }
                                            >
                                              <img
                                                onLoad={() => {
                                                  if (item?.ImageCaption) {
                                                    setPhotographSize({
                                                      width:
                                                        photographRef?.current
                                                          ?.naturalWidth,
                                                      height: "auto",
                                                    });
                                                  }
                                                }}
                                                ref={
                                                  item.ImageCaption !==
                                                  undefined
                                                    ? photographRef
                                                    : undefined
                                                }
                                                crossOrigin="*"
                                                className=""
                                                src={
                                                  photo[photo.length - 1] ===
                                                  props
                                                    ?.defaultInitialEditionName
                                                    ?.editionid
                                                    ? `${s3Source}/PublicationData/${
                                                        props
                                                          ?.defaultInitialPubCode
                                                          ?.publicationcode
                                                      }/${
                                                        props
                                                          ?.defaultInitialEditionName
                                                          ?.editionid
                                                      }/${
                                                        props?.dateArray[0]
                                                      }/${
                                                        props?.dateArray[1]
                                                      }/${
                                                        props?.dateArray[2]
                                                      }/Photographs/${
                                                        item?.Photograph.split(
                                                          "_"
                                                        )[3]
                                                      }/${
                                                        item?.Photograph
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                    : `${s3Source}/PublicationData/${
                                                        props
                                                          ?.defaultInitialPubCode
                                                          ?.publicationcode
                                                      }/${
                                                        props
                                                          ?.defaultInitialEditionName
                                                          ?.editionid
                                                      }/${
                                                        props?.dateArray[0]
                                                      }/${
                                                        props?.dateArray[1]
                                                      }/${
                                                        props?.dateArray[2]
                                                      }/Photographs/${
                                                        item?.Photograph?.split(
                                                          "_"
                                                        )[3]
                                                      }/${item?.Photograph}_${
                                                        props
                                                          ?.defaultInitialEditionName
                                                          ?.editionid
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                }
                                                alt={
                                                  articleViewDataLinked?.ArticleTitle
                                                }
                                              />

                                              {item?.ImageCaption && (
                                                <p
                                                  style={{
                                                    fontSize: `${articleBodyFontsize}px `,
                                                    width:
                                                      photographSize.width > 20
                                                        ? photographSize.width
                                                        : "auto",
                                                    // minWidth:
                                                    //   articleViewData?.ArticlePhotographs &&
                                                    //   articleViewData
                                                    //     ?.ArticlePhotographs
                                                    //     ?.length > 1 &&
                                                    //   "fit-content",
                                                  }}
                                                  className="image-caption m-2"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      item &&
                                                      removeJunk(
                                                        item?.ImageCaption
                                                      ),
                                                  }}
                                                ></p>
                                              )}
                                            </Col>
                                          );
                                        }
                                      )
                                    : ""}
                                </Row>
                              )}
                          </div>
                        ) : (
                          <>
                            <Row
                              id={
                                !articleTextView
                                  ? "article-scroll-container"
                                  : ""
                              }
                              style={{
                                position: "relative",
                                // overflow: "auto",
                                // width: window.innerWidth - 100,

                                height: !isRegional && window.innerHeight - 120,
                              }}
                            >
                              <Col md="12" className="mb-5 pb-3">
                                {textData.length > 0 &&
                                  textData?.map((article, key) => {
                                    return (
                                      <React.Fragment key={article?.Text + key}>
                                        <div
                                          key={"articleName_" + article?.Text}
                                          className="mb-4"
                                          style={{
                                            backgroundColor: "#ffff0069",
                                            opacity: getOpacity(
                                              article,
                                              props?.isSearch?.search
                                            ),
                                            position: "absolute",
                                            top: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Top *
                                                  imageSizes.height) /
                                                2
                                              : " ",
                                            left: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Left *
                                                  imageSizes.width) /
                                                2
                                              : " ",
                                            height: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Height *
                                                  imageSizes.height) /
                                                2
                                              : " ",
                                            width: props?.isSearch?.search
                                              ? (article?.Geometry?.BoundingBox
                                                  .Width *
                                                  imageSizes.width) /
                                                2
                                              : " ",
                                          }}
                                        ></div>
                                      </React.Fragment>
                                    );
                                  })}
                                <img // need to add highlight
                                  // style={{ width: `${imgWidth}%` }}
                                  onLoad={() => {
                                    setImageSizes({
                                      width:
                                        !isRegional &&
                                        imageSizeRef?.current?.naturalWidth,
                                      height:
                                        !isRegional &&
                                        imageSizeRef?.current?.naturalHeight,
                                    });
                                    setImageViewSize({
                                      width:
                                        imageViewRef?.current?.naturalWidth,
                                      height: "auto",
                                    });
                                  }}
                                  style={{
                                    width:
                                      props?.isSearch?.from === "photographs" ||
                                      props?.isSearch?.from === "advertisements"
                                        ? imageDimension?.width
                                        : `${imagesViewSize?.width}px`,
                                    height:
                                      props?.isSearch?.from === "photographs" ||
                                      props?.isSearch?.from === "advertisements"
                                        ? imageDimension.height
                                        : "auto",
                                  }}
                                  ref={
                                    props?.isSearch?.from === "photographs" ||
                                    props?.isSearch?.from === "advertisements"
                                      ? imageSizeRef
                                      : imageViewRef
                                  }
                                  crossOrigin="*"
                                  src={
                                    props?.isSearch?.from === "advertisements"
                                      ? `${s3Source}/PublicationData/${
                                          props?.defaultInitialPubCode
                                            ?.publicationcode
                                        }/${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }/${props?.dateArray[0]}/${
                                          props?.dateArray[1]
                                        }/${
                                          props?.dateArray[2]
                                        }/Advertisement/${
                                          advertisement.current?.split("_")[3]
                                        }/${advertisement.current}_${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }.jpg?v=${filteredDate?.Timestamp}`
                                      : articleViewName ||
                                        articleViewData?.ArticleName
                                      ? `${s3Source}/PublicationData/${
                                          props?.defaultInitialPubCode
                                            ?.publicationcode
                                        }/${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }/${props?.dateArray[0]}/${
                                          props?.dateArray[1]
                                        }/${props?.dateArray[2]}/Article/${
                                          articleViewName?.split("_")[3]
                                        }/${
                                          articleViewData?.ArticleName ??
                                          articleViewName
                                        }.jpg?v=${filteredDate?.Timestamp}`
                                      : `${s3Source}/PublicationData/${
                                          props?.defaultInitialPubCode
                                            ?.publicationcode
                                        }/${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }/${props?.dateArray[0]}/${
                                          props?.dateArray[1]
                                        }/${
                                          props?.dateArray[2]
                                        }/Advertisement/${
                                          advertisement.current?.split("_")[3]
                                        }/${advertisement.current}_${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }.jpg?v=${filteredDate?.Timestamp}`
                                  }
                                  alt={articleViewData?.ArticleTitle}
                                />
                              </Col>
                              {articleViewDataLinked &&
                                Object.keys(articleViewDataLinked).length >
                                  0 && (
                                  <p className="mt-3">
                                    <img // need to add highlight
                                      // style={{ width: `${imgWidth}%` }}
                                      onLoad={() => {
                                        setImageSizes({
                                          width:
                                            !isRegional &&
                                            imageSizeRef?.current.naturalWidth,
                                          height:
                                            !isRegional &&
                                            imageSizeRef.current.naturalHeight,
                                        });
                                        setImageViewLinkSize({
                                          width:
                                            imageViewLinkRef.current
                                              .naturalWidth,
                                          height: "auto",
                                        });
                                      }}
                                      style={{
                                        width: props?.isSearch?.search
                                          ? imageDimension.width
                                          : articleViewDataLinked
                                          ? `${imagesViewLinkSize?.width}px`
                                          : "100%",

                                        height: props?.isSearch?.search
                                          ? imageDimension.height
                                          : "auto",
                                      }}
                                      ref={
                                        props?.isSearch?.from ===
                                          "photographs" ||
                                        props?.isSearch?.from ===
                                          "advertisements"
                                          ? imageSizeRef
                                          : imageViewLinkRef
                                      }
                                      crossOrigin="*"
                                      src={
                                        articleViewNameLinked &&
                                        `${s3Source}/PublicationData/${
                                          props?.defaultInitialPubCode
                                            ?.publicationcode
                                        }/${
                                          props?.defaultInitialEditionName
                                            ?.editionid
                                        }/${props?.dateArray[0]}/${
                                          props?.dateArray[1]
                                        }/${props?.dateArray[2]}/Article/${
                                          articleViewNameLinked?.split("_")[3]
                                        }/${
                                          articleViewDataLinked?.ArticleName
                                        }.jpg?v=${filteredDate?.Timestamp}`
                                      }
                                      alt={articleViewDataLinked?.ArticleTitle}
                                    />
                                  </p>
                                )}
                            </Row>
                          </>
                        )}
                        {localStorage.getItem("contentRec") === "true" && (
                          <Row
                            className="bg-white"
                            style={{
                              position: "fixed",
                              bottom: "5px",
                              paddingLeft: "10%",
                              width: "100%",
                            }}
                          >
                            <Ad
                              path={
                                "/1064661/Epaper_MTGOLD_Desktop/Epaper_MTGOLD_Desktop_ROS_Bottom_Horizontal"
                              }
                              width={728}
                              height={90}
                              divId={"div-gpt-ad-1684323958405-0"}
                            />
                          </Row>
                        )}
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md="3">
                  {/* //banner/ */}
                  {/* <div className="pb-3">
                    <a href={articleBanner?.url} target="_blank">
                      <img
                        width="300px"
                        height="250px"
                        src={articleBanner?.img}
                        alt="banner article"
                      />
                    </a>
                  </div> */}
                  <div className="pt-3">
                    <Ad
                      path={
                        "/1064661/Epaper_MTGOLD_Desktop/Epaper_MTGOLD_Desktop_ROS_Mrec4"
                      }
                      width={300}
                      height={250}
                      divId={"div-gpt-ad-1684323916257-0"}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </OutsideClickHandler>
        ) : (
          <OutsideClickHandler
            onOutsideClick={() => {
              props?.toggleArticleViewModal();
            }}
          >
            <Row>
              <Col sm="12">
                <Card className="px-2 border-0">
                  <CardBody className="pr-2">
                    <Row
                      className="justify-content-center pb-2 "
                      style={{ color: "#2b78a2" }}
                    >
                      <div className="share-mob">
                        <Row className=" flex-wrap-inherit">
                          <Col
                            style={{
                              color: "black",
                            }}
                            className=" pr-4 text-align-end"
                            onClick={() => {
                              articleEvents(
                                "article_previous",
                                "article_previous",
                                "article_previous",
                                "article_previous"
                              );
                              props.handlePrevArticle(
                                props.isSecondPageFit2page
                                  ? props.twoPageImage2Name
                                  : props.twoPageImage1Name ??
                                      props.currentPage?.PageName
                              );
                              Player?.pause();
                              // window.responsiveVoice.cancel();
                              setPlayer(null);
                              setSpeechRunning(false);
                            }}
                          >
                            {props.currArticleIndex > 0 &&
                              !props?.showViewOnPage && (
                                <ArrowBackOutlinedIcon className="cursor-pointer " />
                              )}
                            {currArticleIndex > 0 && !props?.showViewOnPage && (
                              <Tooltip title="Previous Article">
                                <ArrowBackOutlinedIcon className="cursor-pointer " />
                              </Tooltip>
                            )}
                          </Col>
                          {currentPublication?.type !== "image" &&
                          !isRegional &&
                          !props.advertisement &&
                          !hasInfoZone ? (
                            <Col
                              className="pt-0 px-4 cursor-pointer2"
                              style={{
                                color: props.themeColor,
                              }}
                              onClick={() => {
                                toggleView();
                              }}
                            >
                              {articleTextView ? (
                                <div className="pt-1">
                                  <Tooltip title="Image">
                                    <Image />
                                  </Tooltip>
                                </div>
                              ) : (
                                <div className="pt-1">
                                  <Tooltip title="Text">
                                    <FileText />
                                  </Tooltip>
                                </div>
                              )}
                            </Col>
                          ) : (
                            ""
                          )}
                          {articleTextView &&
                            props?.advertisement?.length === 0 &&
                            !isRegional && (
                              <Col
                                style={{
                                  color: props.themeColor,
                                }}
                                className="pt-1 px-1"
                                onClick={() => {
                                  zoomEvents(
                                    "zoom",
                                    "zoom_in",
                                    "zoom",
                                    "top_navigation_article"
                                  );
                                  articleTextView && increaseFontSize();
                                  zoomImageSize(10, 90);
                                }}
                              >
                                <Tooltip title="Zoom In">
                                  <ZoomIn />
                                </Tooltip>
                              </Col>
                            )}
                          {articleTextView &&
                            props?.advertisement?.length === 0 &&
                            !isRegional && (
                              <Col
                                style={{
                                  color: props?.themeColor,
                                }}
                                className="pt-1 px-1"
                                onClick={() => {
                                  zoomEvents(
                                    "zoom",
                                    "zoom_out",
                                    "zoom",
                                    "top_navigation_article"
                                  );
                                  articleTextView && decreaseFontSize();
                                  zoomImageSize(-10, -90);
                                }}
                              >
                                <Tooltip title="Zoom Out">
                                  <ZoomOut />
                                </Tooltip>
                              </Col>
                            )}

                          <Col
                            className="pt-1 px-1"
                            style={{
                              color: props?.themeColor,
                            }}
                          >
                            <Download
                              onClick={() => {
                                saveImg();
                                downloadEvent(
                                  "download",
                                  "download",
                                  "download",
                                  "top_navigation_article"
                                );
                              }}
                            />
                          </Col>

                          {/* <Col
                            style={{
                              color: props.themeColor,
                            }}
                            className="pt-1 px-1"
                          >
                          <Tooltip title="Bookmark">

                            <BookmarkBorderSharpIcon className="font-overview-icon" />
                            </Tooltip>
                        
                          </Col> */}
                          {currentPublication?.type !== "image" &&
                            !isRegional &&
                            !props.advertisement && (
                              <Col
                                style={{
                                  color: props.themeColor,
                                }}
                                className="pt-1 px-1"
                                onClick={async () => {
                                  await setSpeechRunning(!speechRunning);
                                  await synthesizeSpeech(TTS);
                                }}
                              >
                                {!speechRunning ? (
                                  <VolumeOff className="font-overview-icon" />
                                ) : (
                                  <VolumeUpIcon className="font-overview-icon" />
                                )}
                              </Col>
                            )}
                          <Col
                            style={{
                              color: props.themeColor,
                            }}
                            className="pt-1 px-1"
                            onClick={() => print("articleViewMob")}
                          >
                            <Tooltip title="Print">
                              <Printer color={props.themeColor} />
                            </Tooltip>
                          </Col>
                          <Col
                            style={{
                              color: props.themeColor,
                            }}
                            className="pt-1 px-1"
                          >
                            <Tooltip title="Share">
                              <ShareIcon
                                onClick={toggleShare}
                                className="font-overview-icon"
                              />
                            </Tooltip>
                          </Col>

                          <Col
                            style={{
                              color: props.themeColor,
                            }}
                            className="pt-1 px-1"
                          >
                            <Tooltip title="Close">
                              <XCircle
                                className=""
                                onClick={() => {
                                  articleEvents(
                                    "article_close",
                                    "article_close",
                                    "article_close",
                                    "article_close"
                                  );
                                  props.toggleArticleViewModal();
                                  // window.responsiveVoice.cancel();
                                  Player?.pause();
                                  setPlayer(null);
                                  setSpeechRunning(false);

                                  props.setAd();
                                  setRelatedArticleData([]);
                                  setArticleViewDataLinked({});
                                  setArticleViewData({});
                                }}
                                fontSize="large"
                              />
                            </Tooltip>
                          </Col>
                          <Col
                            style={{
                              color: "black",
                            }}
                            className="text-align-start pl-2"
                          >
                            {currArticleIndex !==
                              props?.propsArticles?.length - 1 &&
                              !props?.showViewOnPage && (
                                <Tooltip title="Next Article">
                                  <ArrowForwardOutlinedIcon
                                    color={props?.themeColor}
                                    onClick={() => {
                                      articleEvents(
                                        "article_next",
                                        "article_next",
                                        "article_next",
                                        "article_next"
                                      );
                                      props.handleNextArticle(
                                        props.isSecondPageFit2page
                                          ? props.twoPageImage2Name
                                          : props.twoPageImage1Name ??
                                              props.currentPage?.PageName
                                      );
                                      // window.responsiveVoice.cancel();
                                      Player?.pause();
                                      setPlayer(null);
                                      setSpeechRunning(false);
                                    }}
                                    className="cursor-pointer "
                                  />
                                </Tooltip>
                              )}
                          </Col>
                        </Row>
                      </div>
                    </Row>
                    <Row className="justify-content-center mt-2">
                      <Collapse
                        isOpen={isOpenMobArticle}
                        className="collapse-fixed"
                      >
                        <Row className="pl-0 justify-content-center">
                          <Card className="social-share">
                            <CardBody>
                              <Row
                                className=""
                                style={{ justifyContent: "space-evenly" }}
                              >
                                <WhatsappShareButton
                                  title={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "whatsapp"
                                    );
                                  }}
                                >
                                  <WhatsAppIcon
                                    onClick={() =>
                                      shareEvents(
                                        "share",
                                        "share",
                                        "share",
                                        "whatsapp"
                                      )
                                    }
                                    style={{
                                      color: "#25D366",
                                      fontSize: "30px",
                                    }}
                                  />
                                </WhatsappShareButton>

                                <FacebookShareButton
                                  quote={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "facebook"
                                    );
                                  }}
                                >
                                  <FacebookIcon
                                    onClick={() =>
                                      shareEvents(
                                        "share",
                                        "share",
                                        "share",
                                        "facebook"
                                      )
                                    }
                                    fontSize="large"
                                    style={{
                                      color: "#4267B2",
                                      fontSize: "30px",
                                    }}
                                  />
                                </FacebookShareButton>
                                <TwitterShareButton
                                  title={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "twitter"
                                    );
                                  }}
                                >
                                  <TwitterIcon
                                    onClick={() =>
                                      shareEvents(
                                        "share",
                                        "share",
                                        "share",
                                        "twitter"
                                      )
                                    }
                                    style={{
                                      color: "#1DA1F2",
                                      fontSize: "30px",
                                    }}
                                  />
                                </TwitterShareButton>
                                <EmailShareButton
                                  subject={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                  url={pathForShare}
                                  onShareWindowClose={() => {
                                    updateInsight(
                                      "share",
                                      articleViewData,
                                      "email"
                                    );
                                  }}
                                >
                                  <img
                                    style={{ width: "25px" }}
                                    src="https://img.icons8.com/color/24/000000/gmail-new.png"
                                    alt="gmail"
                                    onClick={() =>
                                      shareEvents(
                                        "share",
                                        "share",
                                        "share",
                                        "email"
                                      )
                                    }
                                  />
                                </EmailShareButton>

                                <div className="pt-2">
                                  <Copy
                                    onClick={() => {
                                      shareEvents(
                                        "share",
                                        "share",
                                        "share",
                                        "copy_link"
                                      );
                                      shareArticle("copy");
                                    }}
                                    fontSize="small"
                                    style={{ color: "black" }}
                                  />
                                </div>
                                <div className="pt-2">
                                  <X color="red" onClick={toggleShare} />
                                </div>
                              </Row>
                            </CardBody>
                          </Card>
                        </Row>
                      </Collapse>
                    </Row>

                    {/* Mail modal// */}
                    <Row className="justify-content-center mt-2">
                      <OutsideClickHandler
                        onOutsideClick={() => {
                          setIsOpenFeedBackModal(false);
                        }}
                      >
                        <Collapse isOpen={isOpenFeedBackModal}>
                          <Row className="pl-0 collapse-fixed-desktop">
                            <Card className="editorial-mail-collp">
                              <CardBody
                                className="p-0"
                                style={{ overflow: "auto" }}
                              >
                                <MailModalFeedBack
                                  to={extractEmail(articleViewData?.Author)}
                                  subject={
                                    articleViewData &&
                                    articleViewData?.ArticleTitle?.split(
                                      "<br>`<br>"
                                    )
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt;")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">")
                                  }
                                />
                              </CardBody>
                            </Card>
                          </Row>
                        </Collapse>
                      </OutsideClickHandler>
                    </Row>
                    <Row className="pt-4 ">
                      {loading ? (
                        <Spinner className="loaderArticleCard" size="sm" />
                      ) : articleTextView && !isRegional ? (
                        <Col className="text-center" id="articleViewMob">
                          {props.showViewOnPage && (
                            <div
                              className="badge p-1 mb-4 mr-2"
                              style={{
                                textDecoration: "underline",
                                color: "var(--primary)",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                viewOnPage();
                              }}
                            >
                              View on page
                            </div>
                          )}
                          {!props.advertisement && (
                            <>
                              <div
                                className="badge p-1 mb-3 text-white"
                                style={{
                                  backgroundColor: "var(--primary)",
                                }}
                              >
                                Views: <span>{views}</span>
                              </div>
                              <div
                                className="badge p-1 mb-3 text-white ml-2"
                                style={{
                                  backgroundColor: "var(--primary)",
                                }}
                              >
                                Shares: <span>{totalShareCount ?? 0}</span>
                              </div>
                            </>
                          )}
                          {articleViewData?.Blurb && (
                            <Row
                              className="text-center"
                              style={{
                                justifyContent: "center",
                                color: "white",
                              }}
                            >
                              <h6
                                style={{
                                  backgroundColor: "red",

                                  fontSize: `${articleBlurbFontsize}px`,
                                }}
                                id="article-card-blurb-mob"
                                className="p-1"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.Blurb?.split("<br>`<br>")
                                      .join("₹")
                                      .split("<br/>`<br/>")
                                      .join("₹")
                                      .split("<br/>₹<br/>")
                                      .join("₹")
                                      .split("<br>`")
                                      .join("₹")
                                      .split("`<br>")
                                      .join("₹")
                                      .split("`")
                                      .join("₹")
                                      .split("<br>¤<br>")
                                      .join("€")
                                      .split("<br>¤")
                                      .join("€")
                                      .split("¤<br>")
                                      .join("€")
                                      .split("¤")
                                      .join("€")
                                      ?.split("<br>")
                                      ?.join("<br/>")
                                      .split("&lt")
                                      .join("<")
                                      .split("&gt;")
                                      .join(">"),
                                }}
                              ></h6>
                            </Row>
                          )}
                          {relatedArticleData &&
                            articleViewData?.Blurb?.length < 1 &&
                            relatedArticleData?.length > 0 &&
                            relatedArticleData?.map((item, ind) => {
                              return (
                                <>
                                  {item?.Blurb && (
                                    <Row
                                      className="text-center"
                                      style={{
                                        justifyContent: "center",
                                        color: "white",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          backgroundColor: "red",

                                          fontSize: `${articleBlurbFontsize}px`,
                                        }}
                                        id="article-card-blurb-mob"
                                        className="p-1"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item &&
                                            item?.Blurb?.split("<br>`<br>")
                                              .join("₹")
                                              .split("<br/>`<br/>")
                                              .join("₹")
                                              .split("<br/>₹<br/>")
                                              .join("₹")
                                              .split("<br>`")
                                              .join("₹")
                                              .split("`<br>")
                                              .join("₹")
                                              .split("`")
                                              .join("₹")
                                              .split("<br>¤<br>")
                                              .join("€")
                                              .split("<br>¤")
                                              .join("€")
                                              .split("¤<br>")
                                              .join("€")
                                              .split("¤")
                                              .join("€")
                                              ?.split("<br>")
                                              ?.join("<br/>")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                        }}
                                      ></h6>
                                    </Row>
                                  )}
                                </>
                              );
                            })}
                          <h4
                            style={{
                              fontSize: `${articleHeadingMobFontsize}px`,
                            }}
                            id="article-card-title-mob"
                            className="font-weight-bold article-view-mob"
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                articleViewData?.ArticleTitle?.split(
                                  "<br>`<br>"
                                )
                                  .join("₹")
                                  .split("<br>`")
                                  .join("₹")
                                  .split("`<br>")
                                  .join("₹")
                                  .split("`")
                                  .join("₹")
                                  .split("<br>¤<br>")
                                  .join("€")
                                  .split("<br>¤")
                                  .join("€")
                                  .split("¤<br>")
                                  .join("€")
                                  .split("¤")
                                  .join("€")
                                  ?.split("<br>")
                                  ?.join("<br/>")
                                  .split("&lt;")
                                  .join("<")
                                  .split("&gt;")
                                  .join(">"),
                            }}
                          ></h4>
                          {articleViewData?.ColumnTitle && (
                            <h6
                              style={{
                                fontSize: `${articleBlurbFontsize}px`,
                                textAlign: "left",
                              }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData?.ColumnTitle?.split(
                                    "<br>`<br>"
                                  )
                                    .join("₹")
                                    .split("<br>`")
                                    .join("₹")
                                    .split("`<br>")
                                    .join("₹")
                                    .split("`")
                                    .join("₹")
                                    .split("<br>¤<br>")
                                    .join("€")
                                    .split("<br>¤")
                                    .join("€")
                                    .split("¤<br>")
                                    .join("€")
                                    .split("¤")
                                    .join("€")
                                    ?.split("<br>")
                                    ?.join("<br/>")
                                    .split("&lt")
                                    .join("<")
                                    .split("&gt;")
                                    .join(">"),
                              }}
                            ></h6>
                          )}
                          {articleViewData && (
                            <hr style={{ borderTop: "2px solid black" }} />
                          )}
                          {articleViewData?.Author && (
                            <p
                              // style={{
                              //   fontSize: `${articleBodyFontsize}px`,
                              // }}
                              style={{
                                fontSize: `${articleBlurbFontsize}px`,
                                fontStyle: "italic",
                                color: articleAuthorEmail.current
                                  ? "#0000ee"
                                  : "#000",
                                textDecoration: articleAuthorEmail.current
                                  ? "underline"
                                  : "none",
                                fontWeight: articleAuthorEmail.current
                                  ? "bold"
                                  : "normal",
                                fontFamily: "LibreBaskerville",
                                cursor: articleAuthorEmail.current
                                  ? "pointer"
                                  : "auto",
                                textAlign: "left",
                                marginBottom: "0px",
                              }}
                              onClick={() => {
                                // if (articleAuthorEmail.current) {
                                //   window.open(
                                //     "mailto:" +
                                //       extractEmail(articleViewData.Author),
                                //     "mail"
                                //   );
                                // }
                                setIsOpenFeedBackModal(true);
                              }}
                              id="article-card-author-mob"
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData?.Author &&
                                  extractEmail(articleViewData.Author),
                              }}
                            ></p>
                          )}

                          {articleViewData?.Author && (
                            <p
                              style={{
                                fontSize: `${articleBlurbFontsize}px`,
                                fontStyle: "italic",
                                color: "#000",
                                textDecoration: "none",
                                fontWeight: "normal",
                                fontFamily: "LibreBaskerville",
                                cursor: "auto",
                                textAlign: "left",
                                marginBottom: "0px",
                                marginTop: "10px",
                              }}
                              id="article-card-author-mob"
                              dangerouslySetInnerHTML={{
                                __html:
                                  articleViewData &&
                                  articleViewData?.Author ===
                                    "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                    ? "TIMES NEWS NETWORK"
                                    : articleViewData?.Author?.replaceAll(
                                        extractEmail(articleViewData?.Author),
                                        ""
                                      ),
                              }}
                            ></p>
                          )}
                          {articleViewData?.ArticleEmail && (
                            <a href={"mailto:" + articleViewData?.ArticleEmail}>
                              <i
                                style={{
                                  fontSize: `${articleBodyFontsize}px`,
                                  textAlign: "left",
                                }}
                                id="article-card-mail-mob"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.ArticleEmail,
                                }}
                              ></i>
                            </a>
                          )}
                          {articleViewData &&
                            articleViewData?.ArticleSource && (
                              <b
                                style={{
                                  fontSize: `${articleBodyFontsize}px`,
                                  textAlign: "left",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    articleViewData &&
                                    articleViewData?.ArticleSource ===
                                      "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                      ? "TIMES NEWS NETWORK"
                                      : articleViewData?.Author?.split("<br>")
                                          ?.join(" ")
                                          .split("&lt;")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">"),
                                }}
                              ></b>
                            )}
                          {articleViewData &&
                            articleViewData?.ArticleLocation && (
                              <p
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                <br />
                                <b
                                  style={{
                                    fontSize: `${articleBodyFontsize}px`,
                                    fontWeight: "bold",
                                    color: "#4a4949",
                                    textAlign: "left",
                                    fontFamily: "LibreBaskerville",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewData &&
                                      articleViewData?.ArticleLocation,
                                  }}
                                ></b>
                              </p>
                            )}
                          <p
                            style={{
                              fontSize: `${articleBodyFontsize - 2}px`,
                              lineHeight: `${articleBodyFontsize + 8}px`,
                              textAlign: "left",
                            }}
                            id="articlecardpara-mob"
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                parsedBody?.current
                                  ?.replace(
                                    extractLocation + ":",
                                    `<b>${extractLocation} : </b>`
                                  )
                                  .split("")
                                  .join("›")
                                  .split("<br>`<br>")
                                  .join("₹")
                                  .split("<br/>`<br/>")
                                  .join("₹")
                                  .split("<br/>₹<br/>")
                                  .join("₹")
                                  .split("<br>`")
                                  .join("₹")
                                  .split("`<br>")
                                  .join("₹")
                                  .split("`")
                                  .join("₹")
                                  .split("<br>¤<br>")
                                  .join("€")
                                  .split("<br>¤")
                                  .join("€")
                                  .split("¤<br>")
                                  .join("€")
                                  .split("¤")
                                  .join("€")
                                  ?.split("<br>")
                                  ?.join("<br/>")
                                  .split("&lt")
                                  .join("<")
                                  .split("&gt;")
                                  .join(">")
                                  ?.split("googleAds")?.[0],
                            }}
                            className="article-view-mob"
                          ></p>
                          {articleViewData &&
                            Object.keys(articleViewData)?.length > 0 &&
                            localStorage.getItem("contentRec") === "true" &&
                            googleAds()}
                          <p
                            style={{
                              fontSize: `${articleBodyFontsize - 2}px`,
                              lineHeight: `${articleBodyFontsize + 8}px`,
                              textAlign: "left",
                              fontFamily: "LibreBaskerville",
                            }}
                            id="articlecardpara-mob"
                            dangerouslySetInnerHTML={{
                              __html:
                                articleViewData &&
                                parsedBody?.current
                                  ?.replace(
                                    extractLocation + ":",
                                    `<b>${extractLocation} : </b>`
                                  )
                                  .split("")
                                  .join("›")
                                  .split("<br>`<br>")
                                  .join("₹")
                                  .split("<br/>`<br/>")
                                  .join("₹")
                                  .split("<br/>₹<br/>")
                                  .join("₹")
                                  .split("<br>`")
                                  .join("₹")
                                  .split("`<br>")
                                  .join("₹")
                                  .split("`")
                                  .join("₹")
                                  .split("<br>¤<br>")
                                  .join("€")
                                  .split("<br>¤")
                                  .join("€")
                                  .split("¤<br>")
                                  .join("€")
                                  .split("¤")
                                  .join("€")
                                  ?.split("<br>")
                                  ?.join("<br/>")
                                  .split("&lt")
                                  .join("<")
                                  .split("&gt;")
                                  .join(">")
                                  ?.split("googleAds")?.[1],
                            }}
                            className="article-view-mob"
                          />

                          {relatedArticleData &&
                            relatedArticleData?.length > 0 &&
                            relatedArticleData?.map((item, ind) => {
                              return (
                                <div
                                  className={"greyBox"}
                                  key={item?.ArticleTitle}
                                >
                                  {item?.Blurb && (
                                    <Row
                                      className="text-center"
                                      style={{
                                        justifyContent: "center",
                                        color: "white",
                                      }}
                                    >
                                      <h6
                                        style={{
                                          backgroundColor: "red",

                                          fontSize: `${articleBlurbFontsize}px`,
                                        }}
                                        id="article-card-blurb-mob"
                                        className="p-1"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item &&
                                            item?.Blurb?.split("<br>`<br>")
                                              .join("₹")
                                              .split("<br/>`<br/>")
                                              .join("₹")
                                              .split("<br/>₹<br/>")
                                              .join("₹")
                                              .split("<br>`")
                                              .join("₹")
                                              .split("`<br>")
                                              .join("₹")
                                              .split("`")
                                              .join("₹")
                                              .split("<br>¤<br>")
                                              .join("€")
                                              .split("<br>¤")
                                              .join("€")
                                              .split("¤<br>")
                                              .join("€")
                                              .split("¤")
                                              .join("€")
                                              ?.split("<br>")
                                              ?.join("<br/>")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                        }}
                                      ></h6>
                                    </Row>
                                  )}
                                  <div>
                                    <h1
                                      id="article-card-title"
                                      style={{
                                        fontWeight: "700",
                                        fontSize: `${
                                          articleHeadingFontsize - 10
                                        }px`,
                                        fontFamily: "fff ff4 !important",
                                        lineHeight: `${
                                          articleHeadingFontsize + 10
                                        }px`,
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item &&
                                          item?.ArticleTitle?.split("<br>`<br>")
                                            .join("₹")
                                            .split("<br/>`<br/>")
                                            .join("₹")
                                            .split("<br/>₹<br/>")
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt;")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">"),
                                      }}
                                    />
                                    {item?.ColumnTitle && (
                                      <h6
                                        style={{
                                          fontSize: `${
                                            Number(articleBlurbFontsize) + 10
                                          }px`,
                                          fontWeight: "normal",
                                          fontStyle: "italic",
                                          color: "#4a4949",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            item &&
                                            item?.ColumnTitle?.split(
                                              "<br>`<br>"
                                            )
                                              .join("₹")
                                              .split("<br>`")
                                              .join("₹")
                                              .split("`<br>")
                                              .join("₹")
                                              .split("`")
                                              .join("₹")
                                              .split("<br>¤<br>")
                                              .join("€")
                                              .split("<br>¤")
                                              .join("€")
                                              .split("¤<br>")
                                              .join("€")
                                              .split("¤")
                                              .join("€")
                                              ?.split("<br>")
                                              ?.join("<br/>")
                                              .split("&lt")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                        }}
                                      ></h6>
                                    )}
                                    {item && (
                                      <hr
                                        style={{
                                          borderTop: "2px solid black",
                                        }}
                                      />
                                    )}

                                    {item && item?.ArticleLocation && (
                                      <b
                                        style={{
                                          fontSize: `${articleBodyFontsize}px`,
                                          fontWeight: "bold",
                                          color: "#4a4949",
                                          fontFamily: "LibreBaskerville",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: item && item?.ArticleLocation,
                                        }}
                                      ></b>
                                    )}

                                    <p
                                      style={{
                                        fontSize: `${
                                          articleBodyFontsize + 1
                                        }px `,
                                        lineHeight: `${
                                          Number(articleBodyFontsize) + 15
                                        }px`,
                                        color: "#4a4949",
                                      }}
                                      id="articlecardpara"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item &&
                                          item?.ArticleBody?.split("")
                                            .join("›")
                                            .split("<br>`<br>")
                                            .join("₹")
                                            .split("<br/>`<br/>")
                                            .join("₹")
                                            .split("<br/>₹<br/>")
                                            .join("₹")
                                            .split("<br>`")
                                            .join("₹")
                                            .split("`<br>")
                                            .join("₹")
                                            .split("`")
                                            .join("₹")
                                            .split("<br>¤<br>")
                                            .join("€")
                                            .split("<br>¤")
                                            .join("€")
                                            .split("¤<br>")
                                            .join("€")
                                            .split("¤")
                                            .join("€")
                                            ?.split("<br>")
                                            ?.join("<br/>")
                                            .split("&lt")
                                            .join("<")
                                            .split("&gt;")
                                            .join(">")
                                            ?.split("googleAdDivHere")
                                            .join(" "),
                                      }}
                                      className="mt-2"
                                    >
                                      {/* November 7, 2020 */}
                                      {/* <br /> */}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}

                          {/* end */}

                          {/* second linked article*/}
                          {articleViewDataLinked &&
                            Object.keys(articleViewDataLinked).length > 0 && (
                              <>
                                <h4
                                  style={{
                                    fontSize: `${articleHeadingMobFontsize}px`,
                                  }}
                                  id="article-card-title2-mob"
                                  className="font-weight-bold article-view-mob mt-3"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewDataLinked &&
                                      articleViewDataLinked?.ArticleTitle?.split(
                                        "`"
                                      )
                                        .join("₹")
                                        .split("<br>`<br>")
                                        .join("₹")
                                        .split("<br/>`<br/>")
                                        .join("₹")
                                        .split("<br/>₹<br/>")
                                        .join("₹")
                                        .split("<br>`")
                                        .join("₹")
                                        .split("`<br>")
                                        .join("₹")
                                        .split("`")
                                        .join("₹")
                                        .split("<br>¤<br>")
                                        .join("€")
                                        .split("<br>¤")
                                        .join("€")
                                        .split("¤<br>")
                                        .join("€")
                                        .split("¤")
                                        .join("€")
                                        ?.split("<br>")
                                        ?.join("<br/>")
                                        .split("&lt;")
                                        .join("<")
                                        .split("&gt;")
                                        .join(">"),
                                  }}
                                ></h4>
                                {articleViewDataLinked?.ColumnTitle && (
                                  <h6
                                    style={{
                                      fontSize: `${articleBlurbFontsize}px`,
                                      textAlign: "left",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked?.ColumnTitle?.split(
                                          "<br>`<br>"
                                        )
                                          .join("₹")
                                          .split("<br>`")
                                          .join("₹")
                                          .split("`<br>")
                                          .join("₹")
                                          .split("`")
                                          .join("₹")
                                          .split("<br>¤<br>")
                                          .join("€")
                                          .split("<br>¤")
                                          .join("€")
                                          .split("¤<br>")
                                          .join("€")
                                          .split("¤")
                                          .join("€")
                                          ?.split("<br>")
                                          ?.join("<br/>")
                                          .split("&lt")
                                          .join("<")
                                          .split("&gt;")
                                          .join(">"),
                                    }}
                                  ></h6>
                                )}
                                <hr style={{ borderTop: "2px solid black" }} />
                                {articleViewDataLinked?.Author && (
                                  <b
                                    style={{
                                      fontSize: `${articleBodyFontsize}px`,
                                      textAlign: "left !mportant",
                                    }}
                                    id="article-card-author2-mob"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        articleViewDataLinked &&
                                        articleViewDataLinked?.Author ===
                                          "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                          ? "TIMES NEWS NETWORK"
                                          : articleViewDataLinked?.Author?.split(
                                              "<br>"
                                            )
                                              ?.join(" ")
                                              .split("&lt;")
                                              .join("<")
                                              .split("&gt;")
                                              .join(">"),
                                    }}
                                  ></b>
                                )}
                                {articleViewDataLinked?.ArticleEmail && (
                                  <a
                                    href={
                                      "mailto:" +
                                      articleViewDataLinked?.ArticleEmail
                                    }
                                  >
                                    <i
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        textAlign: "left",
                                      }}
                                      id="article-card-mail-mob"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked?.ArticleEmail,
                                      }}
                                    ></i>
                                  </a>
                                )}
                                {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticleSource && (
                                    <b
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        textAlign: "left",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked.ArticleSource ===
                                            "TIMES<br>N<br>EWS<br>N<br>ETWORK"
                                            ? "TIMES NEWS NETWORK"
                                            : articleViewDataLinked?.Author?.split(
                                                "<br>"
                                              )
                                                ?.join(" ")
                                                .split("&lt;")
                                                .join("<")
                                                .split("&gt;")
                                                .join(">"),
                                      }}
                                    ></b>
                                  )}
                                {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticleLocation && (
                                    <b
                                      style={{
                                        fontSize: `${articleBodyFontsize}px`,
                                        textAlign: "left",
                                        fontFamily: "LibreBaskerville",
                                        fontWeight: "bold",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          articleViewDataLinked &&
                                          articleViewDataLinked?.ArticleLocation,
                                      }}
                                    ></b>
                                  )}
                                <p
                                  style={{
                                    fontSize: `${articleBodyFontsize - 2}px`,
                                    lineHeight: `${articleBodyFontsize + 8}px`,
                                    fontFamily: "LibreBaskerville",
                                    textAlign: "left",
                                  }}
                                  id="articlecardpara-mob"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      articleViewDataLinked &&
                                      parsedLinkedBody?.current
                                        ?.replace(
                                          linkextractLocation + ":",
                                          `<b>${linkextractLocation} :</b>`
                                        )
                                        ?.split("<br>`<br>")
                                        .join("₹")
                                        .split("<br/>`<br/>")
                                        .join("₹")
                                        .split("<br/>₹<br/>")
                                        .join("₹")
                                        .split("<br>`")
                                        .join("₹")
                                        .split("`<br>")
                                        .join("₹")
                                        .split("`")
                                        .join("₹")
                                        .split("<br>¤<br>")
                                        .join("€")
                                        .split("<br>¤")
                                        .join("€")
                                        .split("¤<br>")
                                        .join("€")
                                        .split("¤")
                                        .join("€")
                                        ?.split("<br>")
                                        ?.join("<br/>")
                                        .split("&lt")
                                        .join("<")
                                        .split("&gt;")
                                        .join(">")
                                        ?.split("googleAdDivHere")
                                        .join(" "),
                                  }}
                                  className="article-view-mob"
                                />
                              </>
                            )}
                          <div>
                            {articleViewData &&
                            articleViewData?.ArticlePhotographs &&
                            articleViewData?.ArticlePhotographs.length
                              ? articleViewData?.ArticlePhotographs.map(
                                  (item, index) => {
                                    let photo = item?.Photograph?.split("_");
                                    return (
                                      <Col
                                        xs="12"
                                        sm="12"
                                        md="12 "
                                        lg="12"
                                        xl="12"
                                        className="text-center"
                                        style={{ overflow: "auto" }}
                                      >
                                        <img
                                          onError={(event) => {
                                            event.target.style.display = "none";
                                            setIsPhotoError(true);
                                          }}
                                          onLoad={() => {
                                            setPhotographSize({
                                              width:
                                                photographRef.current
                                                  .naturalWidth,
                                              height: "auto",
                                            });
                                          }}
                                          key={
                                            articleViewData?.ArticleName +
                                            index +
                                            "name"
                                          }
                                          ref={photographRef}
                                          crossOrigin="*"
                                          className=""
                                          src={
                                            photo[photo.length - 1] ===
                                            props?.defaultInitialEditionName
                                              ?.editionid
                                              ? `${s3Source}/PublicationData/${
                                                  props?.defaultInitialPubCode
                                                    ?.publicationcode
                                                }/${
                                                  props
                                                    ?.defaultInitialEditionName
                                                    ?.editionid
                                                }/${props?.dateArray[0]}/${
                                                  props?.dateArray[1]
                                                }/${
                                                  props?.dateArray[2]
                                                }/Photographs/${
                                                  articleViewData?.ArticleName?.split(
                                                    "_"
                                                  )[3]
                                                }/${item?.Photograph}.jpg?v=${
                                                  filteredDate?.Timestamp
                                                }`
                                              : `${s3Source}/PublicationData/${
                                                  props?.defaultInitialPubCode
                                                    ?.publicationcode
                                                }/${
                                                  props
                                                    ?.defaultInitialEditionName
                                                    ?.editionid
                                                }/${props?.dateArray[0]}/${
                                                  props?.dateArray[1]
                                                }/${
                                                  props?.dateArray[2]
                                                }/Photographs/${
                                                  articleViewData?.ArticleName?.split(
                                                    "_"
                                                  )[3]
                                                }/${item?.Photograph}_${
                                                  props
                                                    ?.defaultInitialEditionName
                                                    ?.editionid
                                                }.jpg?v=${
                                                  filteredDate?.Timestamp
                                                }`
                                          }
                                          alt={articleViewData?.ArticleTitle}
                                        />

                                        {item?.ImageCaption && (
                                          <p
                                            style={{
                                              fontSize: `${articleBodyFontsize}px `,
                                              width: photographSize.width
                                                ? photographSize.width
                                                : "200px",
                                              minWidth:
                                                articleViewData?.ArticlePhotographs &&
                                                articleViewData
                                                  ?.ArticlePhotographs?.length >
                                                  1 &&
                                                "fit-content",
                                            }}
                                            className="image-caption"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item &&
                                                removeJunk(item?.ImageCaption),
                                            }}
                                          ></p>
                                        )}
                                      </Col>
                                    );
                                  }
                                )
                              : ""}
                          </div>
                          {props.advertisement && advertisement?.current && (
                            <Row style={{ overflow: "auto" }}>
                              <TransformWrapper
                                // limitToBounds={true}
                                // doubleClick={{ step: 0.5 }}
                                initialScale={1}
                                doubleClick={{ disabled: true }}
                                wheel={{ disabled: true }}
                                panning={{ disabled: false }}
                                // minPositionY={"1000px"}
                              >
                                <TransformComponent wrapperClass="react-transform-wrapper-1">
                                  <div
                                    className="text-center"
                                    style={{ overflow: "auto" }}
                                  >
                                    <img
                                      width={`${Number(imgWidth) + 20}%`}
                                      crossOrigin="*"
                                      className=""
                                      src={`${s3Source}/PublicationData/${
                                        props?.defaultInitialPubCode
                                          ?.publicationcode
                                      }/${
                                        props?.defaultInitialEditionName
                                          ?.editionid
                                      }/${props?.dateArray[0]}/${
                                        props?.dateArray[1]
                                      }/${props?.dateArray[2]}/Advertisement/${
                                        advertisement.current?.split("_")[3]
                                      }/${advertisement.current}_${
                                        props?.defaultInitialEditionName
                                          ?.editionid
                                      }.jpg?v=${filteredDate?.Timestamp}`}
                                      alt={articleViewData?.ArticleTitle}
                                    />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                  </div>
                                </TransformComponent>
                              </TransformWrapper>
                            </Row>
                          )}
                          {/* second linked article data */}
                          {articleViewDataLinked &&
                            Object.keys(articleViewDataLinked)?.length > 0 && (
                              <>
                                <div>
                                  {articleViewDataLinked &&
                                  articleViewDataLinked?.ArticlePhotographs &&
                                  articleViewDataLinked?.ArticlePhotographs
                                    .length
                                    ? articleViewDataLinked?.ArticlePhotographs.map(
                                        (item, index) => {
                                          let photo =
                                            item?.Photograph?.split("_");
                                          return (
                                            <React.Fragment className="text-center">
                                              <img
                                                onLoad={() => {
                                                  setPhotographSize({
                                                    width:
                                                      photographRef.current
                                                        .naturalWidth,
                                                    height: "auto",
                                                  });
                                                }}
                                                key={
                                                  articleViewDataLinked?.ArticleName +
                                                  index +
                                                  "name"
                                                }
                                                crossOrigin="*"
                                                className=""
                                                ref={photographRef}
                                                src={
                                                  photo[photo.length - 1] ===
                                                  props
                                                    ?.defaultInitialEditionName
                                                    ?.editionid
                                                    ? `${s3Source}/PublicationData/${
                                                        props
                                                          ?.defaultInitialPubCode
                                                          ?.publicationcode
                                                      }/${
                                                        props
                                                          ?.defaultInitialEditionName
                                                          ?.editionid
                                                      }/${
                                                        props?.dateArray[0]
                                                      }/${
                                                        props?.dateArray[1]
                                                      }/${
                                                        props?.dateArray[2]
                                                      }/Photographs/${
                                                        articleViewDataLinked?.ArticleName?.split(
                                                          "_"
                                                        )[3]
                                                      }/${
                                                        item?.Photograph
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                    : `${s3Source}/PublicationData/${
                                                        props
                                                          ?.defaultInitialPubCode
                                                          ?.publicationcode
                                                      }/${
                                                        props
                                                          ?.defaultInitialEditionName
                                                          ?.editionid
                                                      }/${
                                                        props?.dateArray[0]
                                                      }/${
                                                        props?.dateArray[1]
                                                      }/${
                                                        props?.dateArray[2]
                                                      }/Photographs/${
                                                        articleViewDataLinked?.ArticleName?.split(
                                                          "_"
                                                        )[3]
                                                      }/${item?.Photograph}_${
                                                        props
                                                          ?.defaultInitialEditionName
                                                          ?.editionid
                                                      }.jpg?v=${
                                                        filteredDate?.Timestamp
                                                      }`
                                                }
                                                alt={
                                                  articleViewDataLinked?.ArticleTitle
                                                }
                                              />

                                              {item?.ImageCaption && (
                                                <p
                                                  style={{
                                                    fontSize: `${articleBodyFontsize}px `,
                                                    width: photographSize.width,
                                                    minWidth:
                                                      articleViewData?.ArticlePhotographs &&
                                                      articleViewData
                                                        ?.ArticlePhotographs
                                                        ?.length > 1 &&
                                                      "fit-content",
                                                  }}
                                                  className="image-caption"
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      item &&
                                                      removeJunk(
                                                        item?.ImageCaption
                                                      ),
                                                  }}
                                                ></p>
                                              )}
                                            </React.Fragment>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </>
                            )}
                        </Col>
                      ) : (
                        <>
                          <Row
                            // className="article-view-desktop"
                            // height={"40rem"}
                            style={{
                              //   width: "1000px",
                              position: "relative",
                              overflowX: "scroll",
                              overflowY: "scroll",
                              //   transform:
                              //     isPopularDrawerOpen && isFitToHeight > 0 ? "translateX(-5vw)" : "",
                            }}
                            noGutters
                            // style={{ width: "fit-content" }}
                            // id="article-view-card"
                          >
                            <TransformWrapper
                              doubleClick={{ disabled: true }}
                              initialScale={1}
                              wheel={{ disabled: true }}
                            >
                              <TransformComponent wrapperClass="react-transform-wrapper-1">
                                <Col id="articleViewMob">
                                  {textData.length > 0 &&
                                    textData?.map((article, key) => {
                                      return (
                                        <React.Fragment
                                          key={key + article?.Text}
                                        >
                                          <div
                                            key={"llf" + key}
                                            className=""
                                            style={{
                                              backgroundColor: "#ffff0069",
                                              opacity: getOpacity(
                                                article,
                                                props?.isSearch?.search
                                              ),
                                              position: "absolute",
                                              top: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Top *
                                                    imageSizes.height) /
                                                  2
                                                : " ",
                                              left: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Left *
                                                    imageSizes.width) /
                                                  2
                                                : " ",
                                              height: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Height *
                                                    imageSizes.height) /
                                                  2
                                                : " ",
                                              width: props?.isSearch?.search
                                                ? (article?.Geometry
                                                    ?.BoundingBox.Width *
                                                    imageSizes.width) /
                                                  2
                                                : " ",
                                            }}
                                          ></div>
                                        </React.Fragment>
                                      );
                                    })}
                                  <img // need to add highlight
                                    // style={{ width: `${imgWidth}%` }}
                                    onLoad={() => {
                                      setImageSizes({
                                        width:
                                          !isRegional &&
                                          imageSizeRef.current.naturalWidth,
                                        height:
                                          !isRegional &&
                                          imageSizeRef.current.naturalHeight,
                                      });
                                      setImageViewSize({
                                        width:
                                          imageViewRef.current.naturalWidth,
                                        height: "auto",
                                      });
                                    }}
                                    style={{
                                      width:
                                        props?.isSearch?.from ===
                                          "photographs" ||
                                        props?.isSearch?.from ===
                                          "advertisements"
                                          ? imageDimension?.width
                                          : `${imagesViewSize?.width}px`, //will use later for highlation and original image size
                                      // `${Number(imgWidth) + 20}%`,

                                      // "inherit",
                                      height:
                                        props?.isSearch?.from ===
                                          "photographs" ||
                                        props?.isSearch?.from ===
                                          "advertisements"
                                          ? imageDimension.height
                                          : "auto",
                                      marginTop: "50px",
                                    }}
                                    ref={
                                      props?.isSearch?.from === "photographs" ||
                                      props?.isSearch?.from === "advertisements"
                                        ? imageSizeRef
                                        : imageViewRef
                                    }
                                    crossOrigin="*"
                                    src={
                                      props?.isSearch?.from === "advertisements"
                                        ? `${s3Source}/PublicationData/${
                                            props?.defaultInitialPubCode
                                              ?.publicationcode
                                          }/${
                                            props?.defaultInitialEditionName
                                              ?.editionid
                                          }/${props?.dateArray[0]}/${
                                            props?.dateArray[1]
                                          }/${
                                            props?.dateArray[2]
                                          }/Advertisement/${
                                            advertisement.current?.split("_")[3]
                                          }/${advertisement.current}_${
                                            props?.defaultInitialEditionName
                                              ?.editionid
                                          }.jpg?v=${filteredDate?.Timestamp}`
                                        : articleViewName ||
                                          articleViewData?.ArticleName
                                        ? `${s3Source}/PublicationData/${
                                            props?.defaultInitialPubCode
                                              ?.publicationcode
                                          }/${
                                            props?.defaultInitialEditionName
                                              ?.editionid
                                          }/${props?.dateArray[0]}/${
                                            props?.dateArray[1]
                                          }/${props?.dateArray[2]}/Article/${
                                            articleViewName?.split("_")[3]
                                          }/${
                                            articleViewData?.ArticleName ??
                                            articleViewName
                                          }.jpg?v=${filteredDate?.Timestamp}`
                                        : `${s3Source}/PublicationData/${
                                            props?.defaultInitialPubCode
                                              ?.publicationcode
                                          }/${
                                            props?.defaultInitialEditionName
                                              ?.editionid
                                          }/${props?.dateArray[0]}/${
                                            props?.dateArray[1]
                                          }/${
                                            props?.dateArray[2]
                                          }/Advertisement/${
                                            advertisement.current?.split("_")[3]
                                          }/${advertisement.current}_${
                                            props?.defaultInitialEditionName
                                              ?.editionid
                                          }.jpg?v=${filteredDate?.Timestamp}`
                                    }
                                    alt={articleViewData?.ArticleTitle}
                                  />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  {/* <p>hey i shoud be visible</p> */}

                                  {articleViewDataLinked &&
                                    Object.keys(articleViewDataLinked).length >
                                      0 && (
                                      <div style={{ overflow: "auto" }}>
                                        <img
                                          onLoad={() => {
                                            setImageSizes({
                                              width:
                                                !isRegional &&
                                                imageSizeRef.current
                                                  .naturalWidth,
                                              height:
                                                !isRegional &&
                                                imageSizeRef.current
                                                  .naturalHeight,
                                            });
                                            setImageViewLinkSize({
                                              width:
                                                imageViewLinkRef.current
                                                  .naturalWidth,
                                              height: "auto",
                                            });
                                          }}
                                          style={{
                                            width: props?.isSearch?.search
                                              ? imageDimension.width
                                              : articleViewDataLinked
                                              ? `${imagesViewLinkSize?.width}px`
                                              : "100%",

                                            height: props?.isSearch?.search
                                              ? imageDimension.height
                                              : "auto",
                                          }}
                                          className="mt-3"
                                          ref={
                                            props?.isSearch?.from ===
                                            "photographs"
                                              ? imageSizeRef
                                              : imageViewLinkRef
                                          }
                                          crossOrigin="*"
                                          src={`${s3Source}/PublicationData/${
                                            props?.defaultInitialPubCode
                                              ?.publicationcode
                                          }/${
                                            props?.defaultInitialEditionName
                                              ?.editionid
                                          }/${props?.dateArray[0]}/${
                                            props?.dateArray[1]
                                          }/${props?.dateArray[2]}/Article/${
                                            articleViewNameLinked?.split("_")[3]
                                          }/${
                                            articleViewDataLinked?.ArticleName
                                          }.jpg?v=${filteredDate?.Timestamp}`}
                                          alt={
                                            articleViewDataLinked?.ArticleTitle
                                          }
                                        />
                                      </div>
                                    )}
                                </Col>
                              </TransformComponent>
                            </TransformWrapper>
                          </Row>
                        </>
                      )}

                      {localStorage.getItem("contentRec") === "true" && (
                        <Row
                          className=" bottom-ad-article  px-0 bg-white mob-only align-items-center"
                          style={{
                            position: "fixed",
                            bottom: "0px",
                            width: "102%",
                            height: "60px",
                          }}
                        >
                          <Ad
                            path={
                              "/1064661/Epaper_MTGOLD_Mobile/Epaper_MTGOLD_Mobile_ROS_BOTTOM_Banner"
                            }
                            width={300}
                            height={50}
                            divId={"div-gpt-ad-1684127082907-0"}
                          />
                        </Row>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </OutsideClickHandler>
        )}
        {/* </div> */}
      </Container>
    </React.Fragment>
  );
}

if (typeof window !== "undefined") {
  DFPManager.load();
}
const Ad = ({ path, width, height, divId }) => {
  const id = React.useMemo(() => `${divId}`, []);
  // G-KQRTLHVQH8
  React.useEffect(() => {
    const slot = GPTAdsManager.createSlot(path, width, height, id);
    slot.display();

    // setTimeout(() => {
    //   slot.refresh();
    // }, 30000); // forces Ad reloading

    return () => {
      slot.destroy();
    };
  }, [path, width, height]);
  return <div style={{ display: "flex", justifyContent: "center" }} id={id} />;
};
