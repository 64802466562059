import React, { Fragment } from "react";
import { Container, Card, CardBody, Row, Col } from "reactstrap";
import TopHeader from "./TopHeader";
import { Helmet } from "react-helmet";

function PrivacyPolicyPage() {
  return (
    <Fragment>
      <Helmet>
        <link
          rel="canonical"
          href="https://epaper.timesgroup.com/maharashtratimes/privacy"
        />
      </Helmet>
      <TopHeader />
      <Container>
        <Card
          style={{
            marginTop:
              window.innerWidth > 1132
                ? "110px"
                : localStorage.getItem("contentRec") === "false" &&
                  window.innerWidth < 1133
                ? "60px"
                : localStorage.getItem("contentRec") === "true" &&
                  window.innerWidth < 701
                ? "60px"
                : window.innerWidth < 701
                ? "60px"
                : "110px",
          }}
        >
          <CardBody>
            <div className="d-flex justify-content-center align-items-center border-bottom p-2 mb-3">
              <h2 className="text-unmute">Privacy Policy</h2>
            </div>
            <div className="text-muted">
              <p>
                Bennett, Coleman &amp; Co. Ltd (“we”, “us”, “our”, “BCCL
                ”,”Company”) values the trust placed in us by data subject
                (“you”, “your”, “user”, “subscriber”) and therefore, we follow
                the highest standards of privacy guidelines to protect the
                information shared by you with us.
              </p>
              <p>
                This Privacy Policy describes the usage of information provided
                or collected, disclosed or transferred of your information by
                Bennett Coleman & Co. Ltd (hereinafter referred to as “BCCL”)
                and/ or its subsidiary (ies) and / or affiliate(s) (collectively
                referred to as the “Company”), which operates various websites/
                mobile applications & other services including but not limited
                to delivery of information and content via any mobile or
                internet connected device or otherwise (collectively the
                “Services”). We follow this privacy policy in accordance with
                applicable law in the places where we operate. In some cases, we
                may provide additional data privacy notices specific to certain
                services or regions. https://epaper.timesgroup.com is the
                registered domain and official website of Times Group, a BCCL
                Group Company.
              </p>
              <p>
                Please keep in mind that when you provide information to us on a
                third-party site or platform (for example, via our website or
                applications like social media login (for e.g. facebook, google+
                etc.) the information we collect is through those third-party
                sites linked with our websites and applications and is covered
                by this privacy policy, and the information the third-party site
                or platform collects is subject to the third-party site or
                platform’s privacy practices. Privacy choices you have made on
                the third-party site or platform will not apply to our use of
                the information we have collected directly through our websites
                or applications. Please also keep in observance that our
                websites and applications may contain links to other sites not
                owned or controlled by us and we are not responsible for the
                privacy practices of those sites. We encourage you to be aware
                when you leave our websites or applications to read the privacy
                policies of other sites they may collect your personal
                information.
              </p>
              <br />
              <div>
                <p className="m-0">
                  All capitalized terms that have not been specifically defined
                  herein shall have the same meaning as provided under the Terms
                  of Use.
                </p>
                <p>
                  This Privacy Policy should be read in conjunction and together
                  with the Terms of Use applicable to the BCCL services you are
                  using.
                </p>
              </div>
              <b>User Consent</b>
              <p className="m-0">
                By using BCCL website and its services (for example, when you
                visit our website and read news or browse through various other
                portions of our websites or register via social media or via the
                registration form), you agree to provide consent to our
                collection, use and sharing of your personal information as
                described in this policy.
              </p>
              <p>
                In some cases, particularly if you reside in a country governed
                by a data protection regulation, we may ask you to provide
                explicit consent to access our services before proceeding for
                further operations.
              </p>
              <br />
              <p className="m-0">What data is collected</p>
              <b>Collection and Use of Personal Information</b>
              <p className="m-0">
                ‘Personal Information’ or ‘PII’ is defined as any information
                that identifies (whether directly or indirectly) to a particular
                individual or natural person, such as the individual’s name,
                postal address, email address, mobile number and any other
                identifier indicating to that particular person. When anonymous
                information is directly or indirectly associated with personal
                information, the resulting information also is treated as
                personal information.
              </p>
              <p className="m-0">
                The Company respects the privacy of the users of the Services
                they use and is committed to reasonably protect it in all
                respects. The information about the user as collected by the
                Company are:
              </p>
              <div className="ml-5">
                <ol>
                  <li>Information supplied by users </li>
                  <li>Information automatically tracked while navigation</li>
                  <li>Inferred information through usage and log data </li>
                  <li>
                    Information collected from any other sources (like third
                    party providers or social media platforms)
                  </li>
                </ol>
              </div>
              <div>
                <ol type="A">
                  <b>
                    <li className="ml-3">
                      &nbsp;&nbsp;&nbsp;&nbsp;Information collected at the time
                      of registration
                    </li>
                  </b>
                  <br />
                  <p>
                    When you register on the website or applications for the
                    service, we ask that you provide basic personal information
                    from you to continue to interact with you and to continue
                    providing you the Services. Information that we collect
                    includes –
                  </p>
                  <ul className="ml-3">
                    <li>Name</li>
                    <li>Phone Number</li>
                    <li>Email Address</li>
                    <li>Home Address</li>
                    <li>IP address</li>
                    <li>Gender</li>
                  </ul>
                  <br />
                  <b>Subscription or paid service data:</b>
                  <span>
                    {" "}
                    When you chose any subscription or paid service, we or our
                    payment gateway provider may collect your personal
                    information such as address or billing information,
                    including your credit/debit card number (last 4 digits) and
                    expiration date etc. The subscriptions or paid Services may
                    be on auto renewal mode unless cancelled. If at any point
                    you do not wish to auto-renew your subscription, you may
                    cancel your subscription before the end of the subscription
                    term or contact us on our support e-mail.
                  </span>
                  <br />
                  <br />
                  <b>Information you provide to us voluntarily:</b>
                  <span>
                    We may collect additional information at other times,
                    including but not limited to, when you provide feedback to
                    BCCL or you change your content or email preferences,
                    respond to a survey, or communicate with us.
                  </span>
                  <br />
                  <br />
                  <b>
                    <li className="ml-3">
                      &nbsp;&nbsp;&nbsp;&nbsp;Information automatically
                      collected while Browsing/navigating through the website
                    </li>
                  </b>
                  <br />
                  <br />
                  <p className="m-0">
                    <b>Cookies</b>&nbsp; To improve the responsiveness of the
                    “website” for our users, we may use “cookies”, or similar
                    electronic tools to collect information to assign each
                    visitor a unique, random number as a User Identification
                    (User ID) to understand the user’s individual interests
                    using the identified computer. Unless you voluntarily
                    identify yourself (through registration, for example), we
                    will have no way of knowing who you are, even if we assign a
                    cookie to your computer. The only personal information a
                    cookie can contain is information you supply. A cookie
                    cannot read data off your hard drive. Our advertisers may
                    also assign their own cookies to your browser (if you click
                    on their ads), a process that we do not control. We receive
                    and store certain types of information whenever you interact
                    with us via the website. The detailed cookie policy is
                    located at epaper.timesgroup.com
                  </p>
                  <div className="ml-5 mb-3">
                    <ol type="1">
                      <li>Inferred Information through usage and log data</li>
                    </ol>
                  </div>
                  <p className="m-0">
                    We may track certain information about you based upon your
                    behaviour on using BCCL services. We use this information
                    for conducting internal research on our users' demographics,
                    devices, interests, and behaviour to better understand,
                    protect and serve our users.{" "}
                  </p>
                  <p>
                    We may also collect Your Information to track user behaviour
                    and preferences for internal analytics and research. We may
                    also use your information
                  </p>
                  <div className="ml-5">
                    <ol type="i">
                      <li>
                        &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; To
                        evaluate your interest for various genres of content,
                        services, officers;{" "}
                      </li>
                      <li>
                        &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To
                        perform analytics and conduct customer research, to
                        determine your interest, for identifying content that
                        generate sales and to analyse traffic patterns.{" "}
                      </li>
                    </ol>
                  </div>
                  <br />
                  <br />
                  <p>
                    If you choose to post messages on social media accounts,
                    message boards, or other message areas or leave feedback, we
                    will collect that information you provide to us. We retain
                    this information as necessary to resolve disputes, provide
                    customer support and troubleshoot problems as permitted by
                    law.{" "}
                  </p>
                  <br />
                  <p className="m-0">
                    The above information may also be captured by keeping
                    cookies on our websites by our affiliates for the purposes
                    mentioned above
                  </p>
                  <p>
                    If you send us personal correspondence, such as emails or
                    letters, or if other users or third parties send us
                    correspondence about your activities or postings on our
                    services, we may collect and store such information.
                  </p>
                  <br />
                  <b>
                    <li className="ml-3">
                      &nbsp;&nbsp;&nbsp;&nbsp;Information we collect from third
                      parties
                    </li>
                  </b>
                  <b>Information obtained from other sources</b>
                  <span>
                    We may receive information about you from other sources, add
                    it to our account information and treat it in accordance
                    with this policy. If you provide information to the platform
                    provider or other partner, whom we provide services, your
                    account information and order information may be passed on
                    to us. We may obtain updated contact information from third
                    parties in order to correct our records and fulfil the
                    Services or to communicate with you.
                  </span>
                  <br />
                  <br />
                  <b>Demographic and purchase information</b>
                  <br />
                  <p>
                    We may reference other sources of demographic and other
                    information in order to provide you with more targeted
                    communications and promotions. We use Google Analytics,
                    among others, to track the user behaviour on our website.
                    Google Analytics specifically has been able to support
                    display advertising towards helping us gain understanding of
                    our users’ Demographics and Interests. The reports are
                    anonymous and cannot be associated with any individual
                    personally identifiable information that you may have shared
                    with us.
                  </p>
                  <b>Links to third party websites</b>
                  <br />
                  <p>
                    The Application may include links to other websites or
                    applications. Such websites or applications are governed by
                    their respective privacy policies, which are beyond our
                    control. Once you leave our servers (you can tell where you
                    are by checking the URL in the location bar on your
                    browser), use of any information you provide is governed by
                    the privacy policy of the operator of the application,
                    website you are visiting. That policy may differ from ours.
                    If you can't find the privacy policy of any of these sites
                    via a link from the application's/website homepage, you
                    should contact the application/website owners directly for
                    more information.We do not provide any personally
                    identifiable information to third party websites /
                    advertisers / ad-servers without your explicit consent
                  </p>
                  <p className="m-0">How collected data is used</p>
                  <b>Purpose and lawfulness of processing</b>
                  <br />
                  <br />
                  <p className="m-0">
                    We will only collect and process personal data about you
                    where we have lawful basis. Lawful basis on which we would
                    process your personal information includes obtaining
                    explicit consent from you or processing for "legitimate
                    interests" where processing is necessary by us to provide
                    you with services (For e.g. processing your information by
                    our group companies/affiliates).
                  </p>
                  <p>
                    We use information to provide, analyse, administer, enhance
                    and personalize our service and marketing efforts, to
                    process your registration, to provide you services, and to
                    communicate with you related to below mentioned points. For
                    example, we use information to:
                  </p>
                  <div className="ml-3 mb-3">
                    <ul>
                      <li className="ml-5">
                        &nbsp;To help advertisers understand our audience and
                        confirm the value of advertising on our websites or
                        applications (however it is usually in the form of
                        aggregated statistics on traffic to various pages within
                        our site) and to provide you relevant ads
                      </li>
                      <li className="ml-5">
                        &nbsp;Communicate with you concerning our service (for
                        example by email, push notifications), so that we can
                        send you news about BCCL, details about new features and
                        content available on the BCCL Services, promotional
                        announcements, and surveys, and to assist you with
                        operational requests such as password reset requests.
                      </li>
                      <li className="ml-5">
                        &nbsp;Notify you about changes in terms of service or
                        privacy policy
                      </li>
                      <li className="ml-5">
                        &nbsp;Allow you to participate in interactive features
                        offered through our Services
                      </li>
                      <li className="ml-5">
                        &nbsp;Sharing with our customer support team to resolve
                        your queries/concerns related to your rights or services
                        provided to you
                      </li>
                    </ul>
                  </div>
                  <b>Information use by the company</b>
                  <br />
                  <br />
                  <p className="m-0">
                    The Information supplied by you enables us to improve our
                    services and provide you the most user-friendly
                    experience(s). In some cases for e.g. provision of certain
                    service(s) or utility (ies), we may require your contact
                    information as well. All required information is service
                    dependent and the Company may use the above said user
                    Information to, maintain, protect, and improve the Services
                    (including advertising) and for developing new services. Any
                    personally identifiable information provided by you will not
                    be considered as sensitive if it is freely available and /
                    or accessible in the public domain like any comments,
                    messages, blogs, scribbles available on social platforms
                    like Facebook, twitter etc.
                  </p>
                  <p>
                    Any posted/uploaded/conveyed/communication by users on the
                    public sections of the "application/websites" becomes
                    published content and is not considered personally
                    identifiable information subject to this Privacy Policy. In
                    case you choose to decline to submit personally identifiable
                    information on the application/websites, we may not be able
                    to provide certain services on the application/websites to
                    you. We will make reasonable efforts to notify you of the
                    same at the time of opening your account. In any case, we
                    will not be liable and or responsible for the denial of
                    certain services to you for lack of you providing the
                    necessary personal information. When you register with our
                    Services, we may contact you from time to time about
                    updating your personal information to provide you features
                    that we believe may benefit / interest you
                  </p>
                  <br />
                  <p>How collected data is shared</p>
                  <br />
                  <b>Information sharing to third parties</b>
                  <br />
                  <br />
                  <p className="m-0">
                    The Company shares your information with any third parties
                    without obtaining the prior consent of User in the following
                    limited circumstances:
                  </p>
                  <div className="ml-5">
                    <ol type="1">
                      <li>
                        &nbsp;When it is requested or required by law or by any
                        court or governmental agency or authority to disclose,
                        for the purpose of verification of identity, or for the
                        prevention, detection, investigation including cyber
                        incidents, or for prosecution and punishment of
                        offences. These disclosures are made in good faith and
                        belief that such disclosure is reasonably necessary for
                        enforcing these Terms or for complying with the
                        applicable laws and regulations.
                      </li>
                      <br />
                      <li>
                        &nbsp;The Company proposes to share such information
                        within its group companies and officers and employees of
                        such group companies for the purpose of processing
                        personal information on its behalf. We also ensure that
                        these recipients of such information agree to process
                        such information based on our instructions and in
                        compliance with this Privacy Policy and any other
                        appropriate confidentiality and security measures.
                      </li>
                      <br />
                      <li>
                        &nbsp;The Company may present information to our
                        advertisers – to help them understand our audience and
                        confirm the value of advertising on our websites or
                        Applications – however it is usually in the form of
                        aggregated statistics on traffic to various pages within
                        our site.
                      </li>
                      <br />
                      <li>
                        &nbsp;The Company may share your information regarding
                        your activities on websites or applications with third
                        party social websites to populate your social wall that
                        is visible to other people however you will have an
                        option to set your privacy settings, where you can
                        decide what you would like to share or not to share with
                        others.
                      </li>
                      <br />
                    </ol>
                  </div>
                  <b>Your Controls and Choices</b>
                  <br />
                  <p className="m-0">
                    For any of the below request you may send us an e-mail to
                    our support team{" "}
                  </p>
                  <p>User control over collected data</p>
                </ol>
              </div>
              <div>
                <ol type="A">
                  <b>
                    <li className="ml-3 mb-2">
                      Accessing and Rectifying your personal information
                    </li>
                  </b>
                  <p>
                    When you use the Services (website/application or any of its
                    sub sites), we make good faith efforts to provide you, as
                    and when requested by you, with access to your personal
                    information and shall further ensure that any personal
                    information or sensitive personal data or information found
                    to be inaccurate or deficient shall be corrected or amended
                    as feasible. We have provided the user an option to
                    correct/update his personal information on their own by
                    clicking on ‘My Account’ settings post login and then they
                    may update their information.
                  </p>
                  <b>
                    <li className="ml-3 mb-2">Data Portability</li>
                  </b>
                  <p>
                    You may also be entitled to request copies of personal
                    information that you have provided to us in a structured,
                    commonly used, and machine-readable format; wherever
                    feasible. You may send your request by sending an e-mail to
                    our support team
                  </p>
                  <b>
                    <li className="ml-3 mb-2">Data Retention and Erasure</li>
                  </b>
                  <p className="m-0">
                    We retain your personal information as long as necessary for
                    us to provide services to you or you ask us to not retain
                    your data. If you no longer want us to use your information
                    then you can go to your ‘My Account’ settings and delete
                    your account and close your BCCL account. Please note that
                    if you delete your account
                  </p>
                  <div className="ml-5">
                    <ol type="1">
                      <li className="mb-1">
                        &nbsp;We may retain some of your personal information as
                        necessary for our legitimate business interests, such as
                        fraud detection and prevention and enhancing safety. For
                        example, if we suspend a BCCL account for fraud or
                        safety reasons, we may retain certain information from
                        that account to prevent that user from opening a new
                        account in the future.
                      </li>
                      <li className="mb-1">
                        &nbsp;We may retain and use your personal information to
                        the extent necessary to comply with our legal
                        obligations. For example, we may keep some of your
                        information for tax, legal reporting and auditing
                        obligations.
                      </li>
                    </ol>
                    <ul>
                      <li className="mb-1">
                        Information you have shared with others (e.g., Reviews,
                        forum postings) may continue to be publicly visible on
                        the BCCL, even after your account is cancelled. However,
                        attribution of such information to you will be removed.
                        Additionally, some copies of your information (e.g., log
                        records) may remain in our database, but are
                        disassociated from personal identifiers.
                      </li>
                    </ul>
                    <ol type="1">
                      <li className="mb-1">
                        Because of the way we maintain certain services, after
                        you delete your information, residual copies may take a
                        period of time before they are deleted from our active
                        servers and may remain in our backup systems.
                      </li>
                    </ol>
                  </div>
                  <b>
                    <li className="ml-3 mb-2">
                      Withdrawing Consent and Restriction of Processing
                    </li>
                  </b>
                  <p>
                    For withdrawing your consent at any time during the tenure
                    of your services with us, you may choose to do so by sending
                    us an e-mail. We shall review your request and may ask you
                    to verify your identity. Post verification we will withdraw
                    the consent for which request was made by you and stop any
                    further processing of your personal information. You may
                    also withdraw your consent for cookies and advertisement by
                    going to ‘Settings’ and click on consent and withdraw the
                    consent.
                  </p>
                  <b>
                    <li className="ml-3 mb-2">Complaints</li>
                  </b>
                  <p>
                    You can write to us in case of any grievance or compliant at
                    the support e-mail address. If you have any complaints
                    regarding processing of your personal information you may
                    contact our data protection officer or our grievance officer
                    at the below mentioned e-mail address. You have the right to
                    complaint about the data processing activities carried out
                    by BCCL before the competent data protection authorities.
                  </p>
                  <b>
                    <li className="ml-3 mb-2">Objection to Processing</li>
                  </b>
                  <p className="m-0">
                    Where your personal information is processed for direct
                    marketing purposes or any contests or promotions or other
                    services, you may, at any time ask us to cease processing
                    your data for these direct marketing purposes by sending an
                    e-mail to us
                  </p>
                  <p>
                    Please be aware that if you do not allow us to collect
                    personal information from you, we may not be able to deliver
                    certain experiences, and services to you, and some of our
                    services may not be able to take account of your interests
                    and preferences. If collection of personal information is
                    mandatory, we will make that clear at the point of
                    collection so that you can make an informed decision whether
                    to participate. If you have questions about the specific
                    personal information about you that we process or retain,
                    and your rights regarding that personal information, please
                    contact us at the below mentioned e-mail address.
                  </p>
                </ol>
              </div>
              <div className="mt-1 mb-2">
                <b>Children's Privacy</b>
                <br />
                <br />
                <p className="m-0">
                  BCCL is not intentionally designed for or directed at persons
                  less than 18 years of age. BCCL does not knowingly permit any
                  person who is under 18 years of age to register with the
                  services or to provide any other personally identifying
                  information. If BCCL becomes aware that any personally
                  identifiable information of persons less than 18 years of age
                  has been collected on BCCL Services without verified parental
                  consent, then BCCL will take the appropriate steps to delete
                  any such information and notify the parent.
                </p>
                <p>
                  However, we consider it the responsibility of parents to
                  monitor their children’s use of our services. Nevertheless, it
                  is our policy not to collect and process any personal
                  information from children below 18 years of age or offer to
                  send any promotional materials to persons in that category.
                  BCCL does not seek or intend to seek or receive any personal
                  information from children. Should a parent or guardian have
                  reasons to believe that a minor has provided BCCL with
                  personal information without their prior consent, please
                  contact our customer support team to ensure that the personal
                  information is removed from the BCCL services.
                </p>
              </div>
              <div className="mt-1 mb-3">
                <b>Data transfer, storage & processing globally</b>
                <br />
                <br />
                <p>
                  We operate globally and may transfer your personal information
                  to individual companies of the BCCL affiliated companies or
                  third parties in locations around the world for the purposes
                  described in this privacy policy. Wherever your personal
                  information is transferred, stored or processed by us, we will
                  take reasonable steps to safeguard the privacy of your
                  personal information. Additionally, when using or disclosing
                  personal information transferred from the European Union, we
                  use standard contractual clauses approved by the European
                  Commission, adopt other means under European Union law for
                  ensuring adequate safeguards
                </p>
              </div>
              <div className="mt-1 mb-2">
                <b>
                  Security and compliance with laws: How we protect your
                  personal data
                </b>
                <br />
                <br />
                <p className="m-0">
                  We take appropriate security measures to protect against
                  unauthorized access to or unauthorized alteration, disclosure
                  or destruction of data. These include internal reviews of our
                  data collection, storage and processing practices and security
                  measures, including appropriate encryption and physical
                  security measures to guard against unauthorized access to
                  systems where we store personal data. All information gathered
                  on the Site is securely stored within the BCCL controlled
                  database.
                </p>
                <p className="m-0">
                  he database is stored on servers secured behind a firewall;
                  access to the servers is password-protected and is strictly
                  limited. However, as effective as our security measures are,
                  no security system is impenetrable. If you know or have reason
                  to believe that your BCCL account credentials have been lost,
                  stolen, altered, or otherwise compromised or in case of any
                  actual or suspected unauthorized use of your account, please
                  contact us by contacting our customer support team.
                </p>
                <p className="m-0">Changes to privacy notice</p>
                <p className="m-0">
                  The internet is an ever evolving medium. We may alter our
                  privacy policy from time to time to incorporate necessary
                  changes in technology, applicable law or any other variant. In
                  any case, we reserve the right to change (at any point of
                  time) the terms of this Privacy Policy or the Terms of Use.
                  Any changes we make will be effective immediately on notice,
                  which we may give by posting the new policy on the “Site”.
                  Your use of the Site or services offered through the Site
                  after such notice will be deemed acceptance of such changes.
                  We may also make reasonable efforts to inform you via
                  electronic mail. In any case, you are advised to review this
                  Privacy Policy periodically on the “Site”) to ensure that you
                  are aware of the latest version.
                </p>
                <p>Resolving privacy issues</p>
              </div>
              <div className="m-1 mb-1">
                <b>Support</b>
                <br />
                <p className="mt-1">
                  If you require any information or clarification regarding the
                  use of your personal information or this privacy policy or
                  grievances with respect to use of your personal information,
                  please email us at tss@timesgroup.com
                </p>
              </div>
              <div className="mt-1 mb-1">
                <b>Data Controller Office</b>
                <br />
                <p className="mt-1 mb-0">
                  The data controller for our services is Bennett Coleman &amp;
                  Co. Ltd. with the business address of
                </p>
                <p className="m-0">
                  Times of India Office,
                  <br />
                  3rd Floor, Dr. D.N.Road,
                  <br />
                  Fort, Mumbai
                  <br />
                  Maharashtra – 400002
                  <br />
                </p>
                <p>
                  The data protection officer (DPO) appointed by BCCL is Mr.
                  Rajeev Batra who can be contacted at
                  rajeev.batra@timesgroup.com.
                </p>
              </div>
              <div className="m-1">
                <b>Complaints</b>
                <br />
                <p>
                  If you have any queries, complaints regarding the collecting,
                  processing, transfer of personal data/information or regarding
                  this policy please do contact our Data Protection Officer.
                </p>
              </div>
              <div className="mt-1 mb-1">
                <p className="m-0">
                  Any complaints, abuse or concerns with regards to the
                  processing of information provided by you or breach of these
                  terms shall be immediately informed to the designated
                  Grievance Officer as mentioned below via in writing or through
                  email signed with the electronic signature to
                  epaper.support@timesgroup.com or Mr. Sanio Farro ("Grievance
                  Redressal Officer")
                </p>
                <p className="m-0">
                  Mr. Sanio Farro,
                  <br />
                  Grievance Redressal Officer
                  <br />
                  Bennett, Coleman & Co. Ltd.
                  <br />
                  The Times of India Suburban Press,
                  <br />
                  Western Express Highway, Akurli Road,
                  <br />
                  Kandivali (E), Mumbai- 400 101, India
                  <br />
                  Ph: 91-22-67675386
                </p>
                <p className="m-0">
                  We request you to please provide the following information in
                  your complaint:-
                </p>
                <div className="ml-3">
                  <ol>
                    <li className="mb-1 ml-4">
                      Identification of the information provided by you;
                    </li>
                    <li className="mb-1 ml-4">
                      Clear statement as to whether the information is personal
                      information or sensitive personal information;
                    </li>
                    <li className="mb-1 ml-4">
                      Your address, telephone number or e-mail address;
                    </li>
                    <li className="mb-1 ml-4">
                      A statement that you have a good-faith belief that the
                      information has been processed incorrectly or disclosed
                      without authorization, as the case may be;
                    </li>
                    <li className="mb-1 ml-4">
                      A statement, under penalty of perjury, that the
                      information in the notice is accurate, and that the
                      information being complained about belongs to you;
                    </li>
                    <li className="mb-1 ml-4">
                      You may also contact us in case you have any questions /
                      suggestions about the Privacy Policy using the contact
                      information mentioned above.
                    </li>
                  </ol>
                </div>
                <p>
                  BCCL shall not be responsible for any communication, if
                  addressed, to any non-designated person in this regard.
                </p>
                <div className="mt-1 mb-1">
                  <b>Cookie Policy</b>
                  <br />
                  <br />
                  <p>
                    BCCL Group (“we”, “us”, “BCCL”) understands that your
                    privacy is important to data subject (“you”, “your”, “user”,
                    “subscriber”) and we are committed for being transparent
                    about the technologies we use. This Cookie Policy explains
                    how and why cookies and other similar technologies may be
                    stored on and accessed from your device when you use or
                    visit BCCL website or application that posts a link to this
                    Policy (collectively, “the Sites”). This Cookie Policy
                    should be read together with our Privacy Policy and Terms of
                    Use.
                  </p>
                </div>
                <div className="mt-2 mb-2">
                  <b>What are cookies and Other Tracking Technologies?</b>
                  <br />
                  <br />
                  <p>
                    A cookie is a small text file that a website saves on your
                    computer or mobile device when you visit the site. It
                    enables the website to remember your actions and preferences
                    (such as login, language, font size and other display
                    preferences) over a period of time, so you don’t have to
                    keep re-entering them whenever you come back to the site or
                    browse from one page to another. The other tracking
                    technologies work similarly to cookies and place small data
                    files on your devices or monitor your website activity to
                    enable us to collect information about how you use our
                    websites. This allows our websites to recognize your device
                    from those of other users of the websites. The information
                    provided below about cookies also applies to these other
                    tracking technologies.
                  </p>
                  <b>
                    How do our sites use cookies and other tracking
                    technologies?
                  </b>
                  <br />
                  <br />
                  <p className="m-0">
                    We use cookies to collect and store information when you
                    access our website and use other BCCL services. We use
                    cookies for various purposes such as:
                  </p>
                  <ul>
                    <li className="ml-5">To provide you BCCL Services</li>
                    <li className="ml-5">
                      To provide non-personalized or personalized advertisements
                    </li>
                    <li className="ml-5">To identify your location</li>
                    <li className="ml-5">
                      To identify your browser and device.
                    </li>
                    <li className="ml-5">For analytics and research,</li>
                  </ul>
                  <br />
                  <p className="m-0">
                    Measure and analyze the audience for each page in order to
                    subsequently improve the ergonomics, browsing, or visibility
                    of content;
                  </p>
                  <p className="m-0">
                    Measure the reliability of Website by analyzing the number
                    of visits to its pages in real time, and offer varied ads
                    that are adapted to users’ areas of interest;
                  </p>
                  <p>
                    Cookies may also be saved by social media tools if you use
                    these functionalities (e.g. Facebook, Twitter).
                  </p>
                </div>
                <div className="mt-2 mb-3">
                  <b>Types of Cookies</b>
                  <br />
                  <br />
                  <p className="m-0">
                    <b>"First party Cookies "</b>these are the cookies that
                    belong to us and which we place on your device or are those
                    set by a website that is being visited by the user at the
                    time (e.g., cookies placed by BCCL Group).
                  </p>
                  <p className="m-0">
                    <b>Domain</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Cookie name</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Expiration</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Description</b>
                  </p>
                  <p className="m-0">
                    <b>third party</b>
                  </p>
                  <p className="m-0">
                    <b>third party</b>
                  </p>
                </div>
                <div className="mt-3">
                  <b>We use the following types of cookies:</b>
                  <br />
                  <br />
                  <p className="m-0">
                    <b>Persistent Cookies. </b>We use persistent cookies to
                    improve your experience of using the sites. This includes
                    recording your acceptance of our Cookie Policy to remove the
                    cookie message which first appears when you visit the site.
                  </p>
                  <p className="m-0">
                    <b>Session Cookies. </b>Session Cookies are temporary and
                    deleted from your machine when your web browser closes. We
                    use session Cookies to help us track internet usage as
                    described above.
                  </p>
                  <p className="m-0">
                    You may refuse to accept browser Cookies by activating the
                    appropriate setting on your browser. However, if you select
                    this setting you may be unable to access certain parts of
                    the Sites. Unless you have adjusted your browser setting so
                    that it will refuse Cookies, our system will check if
                    Cookies can be captured when you direct your browser to our
                    Sites.
                  </p>
                  <p className="m-0">
                    The data collected by the websites and/or through cookies
                    that may be placed on your computer will not be kept for
                    longer than is necessary to fulfil the purposes mentioned
                    above. In any event, such information will be kept in our
                    database until we get explicit consent from you to remove
                    all the stored cookies.
                  </p>
                </div>
                <div className="mt-2">
                  <b>We categorize cookies as follows:</b>
                  <br />
                  <br />
                  <b>Strictly Necessary/Technical</b>
                  <br />
                  <p>
                    These Cookies are necessary to allow us to operate our Sites
                    so you may access them as you have requested. These Cookies,
                    for example, let us recognize that you have created an
                    account and have logged in/out to access Site content. They
                    also include Cookies that enable us to remember your
                    previous actions within the same browsing session and secure
                    our Sites.
                  </p>
                  <b>Analytical/Performance</b>
                  <br />
                  <p>
                    These Cookies are used by us or third-party service
                    providers to analyse how the Sites are used and how they are
                    performing. For example, these Cookies track what content
                    are most frequently visited, watch history and from what
                    locations our visitors come from. If you subscribe to a
                    newsletter or otherwise register with the Sites, these
                    Cookies may be correlated to you. These Cookies include, for
                    example, Google Analytics cookies, Comscore cookies.
                  </p>
                  <b>Functionality</b>
                  <br />
                  <p>
                    These Cookies let us operate the Sites in accordance with
                    the choices you make. These Cookies permit us to “remember”
                    you in-between visits. For instance, we will recognize your
                    user name and remember how you customized the Sites and
                    services, for example by adjusting text size, fonts,
                    languages and other parts of web pages that are alterable,
                    and provide you with the same customizations during future
                    visits.
                  </p>
                  <b>Targeting or Advertising Cookies.</b>
                  <br />
                  <p>
                    These cookies are used to deliver content that is more
                    relevant to you and your interests. They are also used to
                    deliver targeted advertising or limit the number of times
                    you see an advertisement as well as help measure the
                    effectiveness of the advertising campaigns on BCCL Group and
                    other websites. They remember that you have visited one of
                    our websites and this information is shared with other
                    parties, including advertisers and our agencies. These
                    cookies may also be linked to site functionality provided by
                    third-parties such as Google DFP and Affinity.
                  </p>
                </div>
                <div className="mt-2">
                  <b>Do these cookies collect personal data/identify me?</b>
                  <br />
                  <br />
                  <p className="m-0">
                    Most types of these cookies track consumers via their Device
                    ID or IP address therefore they may collect personal data.
                  </p>
                  <p>
                    Based on the type of cookies used by the relevant
                    third-party, the information these cookies collect may
                    include personal data but they would not be able to directly
                    identify you as an individual or have any of your personal
                    information such as Name, E-mail id or mobile number
                  </p>
                </div>
                <div className="mt-2">
                  <b>Do these cookies collect personal data/identify me?</b>
                  <br />
                  <br />
                  <p className="mt-1 mb-0">
                    Please note that if you want to opt out from receiving
                    targeted advertising, this does not mean that you will
                    receive less advertising when you use our Website. This just
                    means that the advertising you see will not be as customised
                    to you.{" "}
                  </p>
                  <p>
                    However if you still want to switch off third-party
                    advertising cookies, you can turn do this by visiting the
                    Internet Advertising Bureau’s consumer advice site,
                    Youronlinechoices.com This will give you a list of all
                    cookies that are currently set on your device and how to opt
                    out of each of them.
                  </p>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}

export default PrivacyPolicyPage;
