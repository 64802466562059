exports.bpnSubscribe = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};

exports.freeTrialSuccess = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.activeDaysEvents = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.dateArchiveEvent = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.updatesEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.TOCEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.downloadEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });
  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.homeEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.signOutEvent = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.myAccountEvent = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.articleEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.displayEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.editionEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.publicationEvents = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.pageEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.subscriptionEvents = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.loginEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.printEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.shareEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.zoomEvents = (eventName, eventCategory, eventAction, eventLabel) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.activeDaysEvents = (
  eventName,
  eventCategory,
  eventAction,
  eventLabel
) => {
  // cleverTap
  window.clevertap.event.push(eventName, {
    eventCategory: eventCategory,

    eventAction: eventAction,

    eventLabel: eventLabel,

    page_path: window.location.href,
  });

  // gtm event
  window.dataLayer.push({
    event: eventName,
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
  });
};
exports.profilePush = (profileInfo, type) => {
  // remove after checking
  // if (process.env.NEXT_PUBLIC_CLEAVERTAP_ID) {
  if (type === "createProfile") {
    window.clevertap.onUserLogin.push({
      Site: profileInfo,
    });
  } else if (type === "update") {
    window.clevertap.profile.push({
      Site: profileInfo,
    });
  }
  // }
};
