import React, { useContext, useState } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ReactModal from "react-modal";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { Modal } from "reactstrap";
import { Mail } from "react-feather";

import MenuItem from "@material-ui/core/MenuItem";

import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import MenuList from "@material-ui/core/MenuList";
import "../components/sidebar.css";
// import Modal from "react-modal";
import { makeStyles } from "@material-ui/core/styles";
import { HelpCircle, Settings } from "react-feather";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import MailModal from "./MailModal";
import { Steps } from "intro.js-react";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
// context
import { AppContext } from "../contexts/AppContext";
import Driver from "driver.js";
const contactModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "22px",
    justifyContent: "center",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function ToggleMenu4(props) {
  // global state
  const { setIsPopularDrawerOpen, isOpenContactModal, setIsOpenContactModal } =
    useContext(AppContext);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const [isOpenContactModal, setIsOpenContactModal] = React.useState(false);

  const [selectedValue, setSelectedValue] = React.useState("a");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const [activeTab, setActiveTab] = React.useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const [stepsEnabled, setstepsEnabled] = useState(false);
  const [initialStep, setinitialStep] = useState(0);
  const steps = [
    {
      element: "#date",
      title: "Date",
      intro: "You can view previous issues",
      position: "bottom",
    },

    {
      element: "#editions",
      title: "Editions",
      intro: "Select desired edition",
      position: "bottom",
    },
    {
      element: "#display",
      title: "Display",
      intro: "Toggle display",
      position: "bottom",
    },
    {
      element: "#options",
      title: "Options",
      intro: "Click to view Options",
      position: "bottom",
    },

    // {
    //   element: "#account",
    //   title: "Account",
    //   intro: "Click to see your account details",
    //   position: "bottom",
    // },
    {
      element: "#zoom-in",
      title: "Zoom In",
      intro: "Click to zoom-in",
      position: "bottom",
    },
    {
      element: "#zoomOut",
      title: "Zoom Out",
      intro: "Click to zoom-out",
      position: "bottom",
    },
    {
      element: "#page-numbers",
      title: "Page Numbers",
      intro: "You can see page numbers",
      position: "bottom",
    },
    {
      element: "#grid-view",
      title: "All Pages",
      intro: "Click to see all pages",
      position: "bottom",
    },
    // {
    //   element: '#previous-page',
    //   title:'Previous Page',
    //   intro:'Click to go to previous page',
    //   position:'bottom',
    // },
    {
      element: "#next-button",
      title: "Next Button",
      intro: "Click to go to next page",
      position: "bottom",
    },
  ];
  const onExit = () => {
    setstepsEnabled(false);
  };

  const startNavigate = () => {
    setstepsEnabled(!stepsEnabled);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <React.Fragment>
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        initialStep={initialStep}
        onExit={onExit}
        skipLabel="x"
        options={{ hideDone: false }}
      />
      <div className={classes.root}>
        <div>
          <Tooltip title="Options">
            <Settings
              className="feather-29"
              ref={anchorRef}
              // aria-controls={open ? "menu-list-grow" : undefined}
              // aria-haspopup="true"
              onClick={handleToggle}
            />
          </Tooltip>
          <Popper
            open={props.open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                  backgroundColor: "#f5f5f5",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={props.handleClose}>
                    <MenuList
                      autoFocusItem={props.open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      style={{ marginTop: "21px" }}
                    >
                      {/* <Tooltip title="Print"> */}
                      {/* <MenuItem
                          style={{
                            fontSize: "13px",
                            fontWeight: "500",
                            color: props.themeColor,
                          }}
                        >
                        
                          <PrintIcon className=" pr-1" />

                          <p className="menu-name">PRINT</p>
                        </MenuItem> */}
                      {/* </Tooltip> */}

                      {/* <MenuItem
                        style={{ fontSize: "13px", fontWeight: "500" }}
                        // onClick={handleClose}
                      >
                        <ShareIcon style={{color: props.themeColor}} />

                        <p className="menu-name">SHARE</p>
                      </MenuItem> */}
                      {/* <Tooltip title="Navigate">
                        <MenuItem
                          style={{ fontSize: "13px", fontWeight: "500" }}
                          onClick={startNavigate}
                        >
                          <HelpOutlineOutlinedIcon
                            style={{ color: props.themeColor }}
                          />

                          <p className="menu-name">नेविगेट</p>
                        </MenuItem>
                      </Tooltip> */}
                      <Tooltip title="Contact us">
                        <MenuItem
                          style={{ fontSize: "13px", fontWeight: "500" }}
                          onClick={() => {
                            // setIsOpenContactModal(!isOpenContactModal);
                            window.open("mailto:help@matagold.in");
                          }}
                        >
                          <Mail style={{ color: props.themeColor }} />

                          <p className="menu-name">संपर्क करा</p>
                        </MenuItem>
                      </Tooltip>

                      {/* <Tooltip title="FAQ's">
                        <MenuItem
                          style={{ fontSize: "13px", fontWeight: "500" }}
                          onClick={() => {
                            window.open("/maharashtratimes/faqpage");
                          }}
                        >
                          <LiveHelpIcon style={{ color: props.themeColor }} />

                          <p className="menu-name">FAQ's</p>
                        </MenuItem>
                      </Tooltip> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
      <Modal
        isOpen={isOpenContactModal}
        className="modal_container"
        centered
        size="lg"
        style={{ top: window.innerWidth < 701 && "60px" }}
      >
        <MailModal />
      </Modal>
    </React.Fragment>
  );
}
