//Note: Uncomment import lines during working with JSX Compiler.

import React from "react";
import ReactDOM from "react-dom";
import { Row } from "reactstrap";
const googletag = window.googletag || (window.googletag = { cmd: [] });

const createScope = (action) => action && action();

const GPTAdsManager = createScope(() => {
  let initialized = false;
  const initializeAds = (initialLoading = false, singleRequest = true) => {
    if (initialized) {
      return;
    }
    initialized = true;
    googletag.cmd.push(() => {
      const pubads = googletag.pubads();
      if (!initialLoading) {
        pubads.disableInitialLoad();
      }
      if (singleRequest) {
        pubads.enableSingleRequest();
      }
      googletag.enableServices();
    });
  };
  const createSlot = (adPath, adWidth, adHeight, elementId) => {
    initializeAds(); // only if not initialized yet
    let slot = null;
    googletag.cmd.push(() => {
      const size = adWidth & adHeight ? [adWidth, adHeight] : ["fluid"];
      const tmp = googletag.defineSlot(adPath, size, elementId);
      if (tmp) {
        slot = tmp;
        tmp.addService(googletag.pubads());
      }
    });
    const display = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const refresh = () => {
      if (slot) {
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          pubads.refresh([slot]);
        });
      }
    };
    const destroy = () => {
      if (slot) {
        const tmp = slot;
        googletag.cmd.push(() => {
          const pubads = googletag.pubads();
          googletag.destroySlots([tmp]);
        });
        slot = null;
      }
    };
    return { display, refresh, destroy };
  };
  return { initializeAds, createSlot };
});

let adCounter = 0;

const Ad = ({ path, width, height }) => {
  const id = React.useMemo(() => `div-gpt-ad-${++adCounter}`, []);
  React.useEffect(() => {
    const slot = GPTAdsManager.createSlot(path, width, height, id);
    slot.display();
    // slot.refresh(); // forces Ad reloading
    return () => {
      slot.destroy();
    };
  }, [path, width, height]);
  return <div style={{ border: "1px solid grey" }} id={id} />;
};

// Usage example:

// somewhere in the code...
GPTAdsManager.initializeAds(false, true);

// uncomment below 3 lines to open GPT Ads console
// window.googletag.cmd.push(() => {
//   window.googletag.openConsole();
// });

const App = () => (
  <div className="App d-flex flex-column align-items-center justify-content-center my-5">
    <h4>Google Sample Ad</h4>
    <Ad path="/6355419/Travel/Europe/France/Paris" width={300} height={250} />
    <div>
      <h4>Homepage 1st Right</h4>
      <Ad path="/1064661/HP_1st_Right" width={300} height={250} />
    </div>

    <div>
      <h4>Homepage 2nd Right</h4>
      <Ad path="/1064661/HP_2nd_Right" width={300} height={250} />
    </div>

    <div>
      <h4>Homepage Top Horizontal</h4>
      <Ad path="/1064661/HP_Top_Horizontal" width={728} height={90} />
    </div>

    <div>
      <h4>Homepage Bottom Horizontal</h4>
      <Ad path="/1064661/HP_Bottom_Horizontal" width={728} height={90} />
    </div>

    <div>
      <h4>TOI Top Horizontal</h4>
      <Ad path="/1064661/TOI_Top_Horizontal" width={728} height={90} />
    </div>

    <div>
      <h4>TOI 1st Right</h4>
      <Ad path="/1064661/TOI_1st_Right" width={300} height={250} />
    </div>

    <div>
      <h4>TOI 2nd Right</h4>
      <Ad path="/1064661/TOI_2nd_Right" width={300} height={250} />
    </div>

    <div>
      <h4>TOI 3rd Right</h4>
      <Ad path="1064661/TOI_3rd_Right" width={300} height={250} />
    </div>
  </div>
);
export default App;
