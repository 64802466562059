exports.pushNotifications = () => {
  try {
    //cleaverTap BPN
    window.clevertap.notifications.push({
      titleText: "Would you like to receive Push Notifications?",
      bodyText:
        "We promise to only send you relevant content and give you updates on your transactions",
      okButtonText: "Sign me up!",
      rejectButtonText: "No thanks",
      okButtonColor: "#f23046",
      skipDialog: true,
      serviceWorkerPath: "/serviceworker.js",
    });
  } catch (error) {
    console.error(error);
  }
};
