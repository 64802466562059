import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import JSCookie from "js-cookie";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import NotificationsPausedOutlinedIcon from "@material-ui/icons/NotificationsPausedOutlined";
import MenuList from "@material-ui/core/MenuList";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import "../components/sidebar.css";
import username_icon from "../assets/icons/Icons-52.png";
import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { makeStyles } from "@material-ui/core/styles";
import BookmarkBorderOutlinedIcon from "@material-ui/icons/BookmarkBorderOutlined";
import { User, FileText, LogOut, LogIn } from "react-feather";
import { Link, Redirect, useHistory } from "react-router-dom";
import { getCustomer } from "../helper";
import axios from "axios";
import jsonp from "jsonp";
import {
  signOutEvent,
  myAccountEvent,
  loginEvents,
} from "../functions/cleverTap";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "22px",
    justifyContent: "center",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function ToggleMenu4(props) {
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [loggedOut, setLoggedOut] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  const handleLogout = () => {
    let customer_details = localStorage.getItem("customer_details");
    if (customer_details) customer_details = JSON.parse(customer_details);
    axios
      .delete(
        `${process.env.REACT_APP_SUBSCRIPTION_URL}/customer/logout/${customer_details?.id}`
      )
      .then((res) => {
        console.log("logged out");
      });
    localStorage.clear();
    JSCookie.remove("token");
    jsonp(
      `https://jsso.indiatimes.com/sso/identity/profile/logout/external?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}`,
      null,
      function async(err, data) {
        if (err) {
          console.error(err.message);
        } else {
        }
      }
    );
    window.location.reload();
  };
  const handleLogin = () => {
    window.open(
      `https://jsso.indiatimes.com/sso/identity/login?channel=${process.env.REACT_APP_CHANNEL_SSO_NAME}&ru=${window.location.origin}/maharashtratimes/jsso`,
      "Ratting",
      "width=600,height=600,toolbar=0,status=0,"
    );
  };
  return (
    <div className={classes.root}>
      {loggedOut && <Redirect to="/login" />}
      <div style={{ alignSelf: props?.homepage && "center" }}>
        <Tooltip title="Account">
          {/* <User
            className="feather-29"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          /> */}

          <span>
            <button
              onClick={handleToggle}
              className={
                props?.homepage ? "btn-login-hpage" : "btn-login-tbpar"
              }
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              style={{
                border: `1px solid ${props.themeColor}`,
                width: "auto",
                padding: "0 5px",
                color: props.themeColor,
                backgroundColor: "white",
              }}
            >
              <p className="m-0"> नमस्कार: {props?.customerFirstName} </p>
            </button>
          </span>
        </Tooltip>
        <Popper
          open={props.open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                backgroundColor: "#f5f5f5",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={props.handleClose}>
                  <MenuList
                    autoFocusItem={props.open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    style={{ marginTop: "21px" }}
                  >
                    <MenuItem
                      style={{ fontSize: "13px", fontWeight: "500" }}
                      // onClick={handleClose}
                    >
                      {/* <DesktopWindowsOutlinedIcon /> */}
                      <FileText
                        style={{ color: props.themeColor }}
                        className=" pr-1"
                      />
                      {/* <Link
                        to="/my-account"
                        onClick={() => window.location.reload()}
                      > */}
                      <p
                        className="menu-name"
                        onClick={() => {
                          myAccountEvent(
                            "my_account",
                            "my_account",
                            "my_account",
                            "header"
                          );
                          window.open(
                            `${process.env.REACT_APP_CHANNEL_URL}/myaccount`
                          );
                        }}
                      >
                        माझे खाते
                      </p>
                      {/* </Link> */}
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "13px", fontWeight: "500" }}
                      // onClick={handleClose}
                      onClick={() => {
                        localStorage.getItem("customer_details")
                          ? handleLogout()
                          : handleLogin();
                      }}
                    >
                      {localStorage.getItem("customer_details") ? (
                        <>
                          <LogOut
                            onClick={() => {
                              signOutEvent(
                                "signout_click",
                                "signout",
                                "signout_click",
                                "header"
                              );
                            }}
                            style={{ color: props.themeColor }}
                          />
                          <p className="menu-name">लॉग आउट</p>
                        </>
                      ) : (
                        <>
                          <LogIn
                            onClick={() => {
                              loginEvents(
                                "login_initiated",
                                "login_initiated",
                                "login_initiated",
                                "topbar"
                              );
                              localStorage.setItem(
                                "login_source",
                                "top_catbar"
                              );
                            }}
                            style={{ color: props.themeColor }}
                          />
                          <p className="menu-name">लॉग इन</p>
                        </>
                      )}

                      {/* <MenuItem
                      style={{ fontSize: "13px", fontWeight: "500" }}
                    >
                      <NotificationsPausedOutlinedIcon className="text-icon-color" />
                      <b className="menu-name">KEYWORD ALERTS</b>
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "13px", fontWeight: "500" }}
                    >
                      <NotificationsNoneOutlinedIcon className="text-icon-color" />
                      <b className="menu-name">NOTIFICATIONS</b>
                    </MenuItem>
                    <MenuItem
                      style={{ fontSize: "13px", fontWeight: "500" }}
                    >
                      <BookmarkBorderOutlinedIcon className="text-icon-color" />
                      <b className="menu-name">MY BOOKMARKS</b> */}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
