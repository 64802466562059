import React, { useState, useContext, useEffect } from "react";
import "../components/cookies-choice.css";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Table,
} from "reactstrap";
import { AppContext } from "../contexts/AppContext";

import { MdSecurity } from "react-icons/md";
import { Helmet } from "react-helmet";

const CookiesChoice = () => {
  const {
    isEnableAds,
    setIsEnableAds, // content reccomendation
    isEnableAnalytics,
    setIsEnableAnalytics, // web analytics
  } = useContext(AppContext);
  const [showModal, setShowModal] = useState(true);
  function toggleModal() {
    setShowModal(!showModal);
  }

  // console.log("isEnableAds: ", isEnableAds);
  // console.log("isEnableAnalytics: ", isEnableAnalytics);
  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="canonical"
          href="https://epaper.timesgroup.com/maharashtratimes/check-users-cookie"
        />
      </Helmet>
      <div className="mainWidth">
        {/* <Button color="danger" onClick={() => toggleModal()}>
          Click Me
        </Button> */}
        <Modal
          centered
          isOpen={showModal}
          backdropClassName="back-cookie"
          // style={{ backgroundColor: "white" }}
          className="modalCss"
        >
          <ModalBody className="bodyCookie text-start p-2 m-2">
            <Form>
              <h5 className="blueClr bold">
                <MdSecurity className="pt-1" />
                Your Choices Regarding Cookies
              </h5>
              <p className="fs12 mb-1 ">
                We and third parties may deploy cookies and similar technologies
                when you use our site. Please review the information below and
                select the cookies that you want to enable. You can continue to
                the site without accepting these cookies but certain features
                may not be available or function property.
              </p>
              <Table borderless className="fs12 m-0">
                <thead>
                  <tr className="headerTable">
                    <th colSpan={1}>Category</th>
                    <th colSpan={1}>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Web Analytics</td>
                    <td>
                      We use cookies to analyze and measure traffic to the site
                      so that we know our audience and can improve our site,
                      determine what stories are read, where visitors come from,
                      and how long they stay. Opt-In to these analytics cookies
                      by clicking enable. Even if you disable the cookies, there
                      might be some essential cookies that may be captured for
                      the functioning of the website. Please read our{" "}
                      <a
                        href="https://epapers.timesgroup.com/policy"
                        className="bottomlinks"
                        target="_blank"
                        rel="noreferrer"
                      >
                        cookie policy
                      </a>{" "}
                      for more details.
                    </td>
                    <td>
                      <FormGroup check>
                        <Input
                          name="radio1"
                          value="Enable"
                          onChange={(e) => {
                            setIsEnableAnalytics(e.target.value);
                          }}
                          checked={
                            isEnableAnalytics === "Enable" ? true : false
                          }
                          type="radio"
                        />
                        <Label check>Enable</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="radio1"
                          value="Disable"
                          onChange={(e) => {
                            setIsEnableAnalytics(e.target.value);
                          }}
                          checked={
                            isEnableAnalytics === "Disable" ? true : false
                          }
                          type="radio"
                        />
                        <Label check>Disable</Label>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td>Content Recommendation and personalization</td>
                    <td>
                      {" "}
                      We use third-party services to provide content
                      recommendations and customize your user experience and
                      advertising. Opt-In to these content recommendation
                      cookies by clicking enable.
                    </td>
                    <td>
                      <FormGroup check>
                        <Input
                          name="radio2"
                          value="Enable"
                          onChange={(e) => {
                            setIsEnableAds(e.target.value);
                          }}
                          checked={isEnableAds === "Enable" ? true : false}
                          type="radio"
                        />
                        <Label check>Enable</Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          name="radio2"
                          value="Disable"
                          type="radio"
                          onChange={(e) => {
                            setIsEnableAds(e.target.value);
                          }}
                          checked={isEnableAds === "Disable" ? true : false}
                        />
                        <Label check>Disable</Label>
                      </FormGroup>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <button
                        type="button"
                        className="p-1 rounded"
                        style={{ width: "max-content" }}
                        onClick={() => {
                          setIsEnableAds("Enable");
                          setIsEnableAnalytics("Enable");
                        }}
                      >
                        Enable All
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p className="fs12 m-0">
                {" "}
                By clicking CONTINUE, I acknowledge I have reviewed and consent
                to the{" "}
                <a
                  href="https://epapers.timesgroup.com/terms"
                  className="bottomlinks"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions,
                </a>
                <a
                  href="https://epapers.timesgroup.com/policy"
                  className="bottomlinks"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Privacy and Cookie Policy{" "}
                </a>{" "}
                and I'm above 16 years of age.
              </p>
              <div
                onClick={() => {
                  localStorage.setItem(
                    "webAnalytics",
                    isEnableAnalytics === "Enable" ? true : false
                  );
                  localStorage.setItem(
                    "contentRec",
                    isEnableAds === "Enable" ? true : false
                  );
                  setShowModal(false);
                }}
                className="mouse blueClr fs20 textEnd"
              >
                Continue
              </div>
            </Form>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default CookiesChoice;
