import React, { Fragment } from "react";
import { Container, Card, CardBody } from "reactstrap";
import TopHeader from "./TopHeader";
import { Helmet } from "react-helmet";

function TermsAndConditionsPage() {
  return (
    <Fragment>
      <Helmet>
        <link
          rel="canonical"
          href="https://epaper.timesgroup.com/maharashtratimes/terms"
        />
      </Helmet>
      <TopHeader />
      <Container>
        <Card
          style={{
            marginTop:
              window.innerWidth > 1132
                ? "110px"
                : localStorage.getItem("contentRec") === "false" &&
                  window.innerWidth < 1133
                ? "60px"
                : localStorage.getItem("contentRec") === "true" &&
                  window.innerWidth < 701
                ? "60px"
                : window.innerWidth < 701
                ? "60px"
                : "110px",
          }}
        >
          <CardBody className="text-muted">
            <div className="d-flex justify-content-center align-items-center border-bottom p-2 mb-3">
              <h2>Terms of Use</h2>
            </div>
            <p>
              These Terms of Use, along with the Privacy Policy located at
              epaper.timesgroup.com (together referred to as “Terms of Use”), is
              a binding agreement between Bennett, Coleman &amp; Co. Ltd.
              (“BCCL”/“we”/“us”/“our”/”Company”) and the user
              (“you”/”User”/”your”) of the site epaper.timesgroup.com (“Site”).
              These Terms of Use are an electronic record under the Information
              Technology Act, 2000 and the rules there under as applicable and
              the amended provisions pertaining to electronic records in various
              statutes under applicable Indian laws.
            </p>
            <div className="ml-5">
              <ol>
                <li>
                  Your access to the Site is subject to the Terms of Use, as
                  modified/updated from time-to-time entirely at the discretion
                  of BCCL.
                </li>
                <li>The Site is owned, managed and operated by BCCL.</li>
                <li>
                  You must be 18 years of age or older to visit or use the Site
                  in any manner. If you are under the age of 18, you should
                  review these Terms of Use with your parent or legal guardian
                  to make sure that you and your parent or legal guardian
                  understand and agree to it and further if required you shall
                  perform or undertake such activities which will entitle you to
                  enter into a legally binding agreement with BCCL. By visiting
                  this Site or accepting these Terms of Use, you represent and
                  warrant to the Company that you are 18 years of age or older,
                  and that you have the right, authority and capacity to use the
                  Site and agree to and abide by these Terms of Use. You also
                  represent and warrant to the Company that you will use the
                  Site in a manner consistent with any and all applicable laws
                  and regulations.{" "}
                </li>
                <li>
                  By using the Site, you agree to follow and be bound by all
                  terms and conditions concerning your use of the Site. Certain
                  areas of the Site may have different terms of use, posted at
                  appropriate section. If there is a conflict between the Terms
                  of Use and terms of use posted for a specific area of the
                  Site, the latter shall have precedence with respect to your
                  use of that area of the Site.
                </li>
                <li>
                  In some territories, for availing the services under the Site,
                  registration by the User is mandatory, while in certain
                  territories, the User may or may not register for availing the
                  services under the Site. A valid mobile number would be
                  required to register on the Site, to complete the registration
                  process and a one-time password (“OTP”) would be sent to you
                  on the mobile number provided by you at the time of
                  registration for the purpose of user validation. You may also
                  be required to generate OTP for downloading any
                  content/material from the Site, in all cases, the OTP will be
                  sent to the mobile number provided at the time of
                  registration. BCCL in its sole discretion may make the
                  registration mandatory in such territories. You will receive a
                  password and account designation upon completing the
                  registration on the Site. You are responsible for maintaining
                  the confidentiality of the password and account and are fully
                  responsible for all activities that occur under your password
                  or account. You may change the password on your own. By
                  registering, you agree to the following terms in addition to
                  any other specific terms which shall be posted at an
                  appropriate location of the Site. Each registration is for a
                  single individual user only for personal use of the Site. To
                  access these services, you will be asked to enter your
                  individual User Name and Password. Therefore, given the
                  proprietary nature of the copyright vested in the Site
                  content, we do not permit and strictly prohibit any of the
                  following: -
                </li>
                <br />
                <div className="ml-3">
                  <p>
                    (a){""}Any other person sharing your User Name and Password.
                  </p>
                  <p>
                    (b) Any part of the Site being cached in proxy servers and
                    accessed by individuals who have not registered with Site as
                    users.
                  </p>
                  <p>
                    (c) Access through a single User Name and Password being
                    made available to multiple users on a network,
                    alternation/modification or distribution/circulation of the
                    Site content via any other communication media/social media
                    platform by any means on other platform which are in
                    existence or coming into existence at any later stage.
                  </p>
                  <p>
                    (d) Except as permissible by the options available on the
                    Site, no content from the Site shall be copied, downloaded,
                    merged, collated, compiled, aggregated, modified,
                    reproduced, republished, uploaded, transmitted, posted,
                    circulated, shared or distributed through any mobile or
                    internet connected device or otherwise in any format via any
                    communication media or social media platform without prior
                    written permission from BCCL.
                  </p>
                </div>
                <li>
                  If we reasonably believe that a User Name and Password are
                  being used/misused in any manner as stated above, we reserve
                  the right to terminate/suspend access rights immediately
                  without notice, and block access to all users from that IP
                  address.
                </li>
                <li>
                  You specifically agree that BCCL shall not be responsible for
                  unauthorized access to or alteration of your transmissions or
                  data, any material or data sent or received or not sent or
                  received through the Site.
                </li>
                <li>
                  Where any subscription fee is required to be paid for availing
                  the services, You acknowledge that BCCL reserves the
                  unrestricted and discretionary right to change, rearrange,
                  alter, add or delete services offerings, the selections in
                  those offerings, prices, manner and format of authorizing your
                  electronic access to the Site content and any other service
                  BCCL may offer, at any time. BCCL will endeavor to notify you
                  of any such change and its effective date. Further, BCCL may
                  in its sole discretion, and without any obligation or
                  liability towards you (except as provided herein)
                  withdraw/cease distribution of the publication fully and/or
                  partially and in such an event, the sole remedy available to
                  you shall be pro-rata refund of payment made by you on the
                  basis of subscribed publication received till the date of such
                  withdrawal or cessation. The refund shall be made in the
                  source account from which the payment was made.
                </li>
                <li>
                  The subscription fee for our publications and any other
                  charges, You may incur in connection with the use of Your
                  subscription will be charged to Your payment method on the
                  specific billing date indicated on Your account page. The
                  length of Your billing cycle will depend on the type of
                  subscription that You choose when You sign-up for the
                  subscription. To use the subscription service, You must
                  provide one or more payment methods. You hereby authorize BCCL
                  to charge any payment methods associated with Your
                  subscription through any of its third-party associates. You
                  shall remain liable for any uncollected amounts. If we do not
                  receive a payment authorization or if a payment is not
                  successfully settled, due to expiration, insufficient funds,
                  subsequent cancellation of payment authorization or otherwise,
                  we may suspend or terminate Your subscription immediately
                  until we have successfully charged a valid payment method.
                </li>
                <li>
                  Wherever applicable, in the event you accord Your consent for
                  auto-debited payments through third-party sites/payment
                  aggregators, You authorize our authorized partners to charge
                  the subscription fee for the next billing cycle to payment
                  method selected by You using the same card or other payment
                  methods that You previously used. Unless explicitly
                  unsubscribed, Your subscription will get renewed automatically
                  on a recurring basis upon expiry of the subscription period.
                  Hence, if You have signed up for our subscription by availing
                  auto-debit option of your credit card, debit card or an
                  account with a third-party as a payment method, You would have
                  to proactively revoke your auto-debit consent through such
                  third-party or through the option available on our website
                  before expiry of Your subscription term so as to avoid by
                  default billing of subscription fee for the renewal term.
                </li>
                <li>
                  You agree that unless otherwise specified, subscriptions fees
                  once paid are non-refundable, provided that in case of failed
                  transaction or duplicate transaction the subscription fees
                  deducted from the User’s account shall be refunded in the
                  source account from which the payment was made. BCCL uses
                  third party payment gateways for collection of credit/debit
                  card or other payment instruments. In case of payment gateway
                  failure, the same would be communicated to you by the payment
                  gateway provider. BCCL shall not be responsible for extra
                  currency/conversion charges/deductions/error issues from
                  payment gateway. In the case of failed online transactions,
                  which result in no services, BCCL offers no guarantees
                  whatsoever for the accuracy or timeliness of the refunds
                  reaching the customers card/bank accounts through the payment
                  gateway.
                </li>
                <li>
                  BCCL may suspend or terminate User's access to the Site or any
                  part thereof at any time and for any reason without any prior
                  notice. The provisions regarding disclaimer of warranty,
                  accuracy of information, software, database other system or
                  functionality of Site and indemnification shall survive such
                  termination. BCCL may monitor User’s access to the Site.{" "}
                </li>
                <li>
                  BCCL may, in its sole discretion, and without any obligation
                  or liability towards you (including refund or credits for any
                  subscription fee whether in full or in part thereof), add or
                  delete one or more Content/editions, or enable or disable any
                  feature of the Site (including the manner and format of
                  accessing content/material on the Site including download of
                  content from the site), or any of your rights or
                  authorizations at any time, without any prior notice to you.
                  You agree to abide by the changes made under this provision.
                  BCCL reserves the right to change the Terms of Use applicable
                  to use of the Site. Such changes shall be effective
                  immediately upon notice, which shall be placed on the Site.
                </li>
                <li>
                  All contents on the Site are the exclusive property of BCCL
                  except the third-party content which are licensed to BCCL
                  and/or its affiliates. The software, text, images, graphics,
                  video and audio used on the Site belong to or are licensed to
                  BCCL or its subsidiaries and affiliates. No material from the
                  Site may be copied, modified, reproduced, republished,
                  uploaded, transmitted, posted or distributed in any form
                  without prior written permission from BCCL. All rights not
                  expressly granted herein are reserved. Unauthorised use of the
                  materials appearing on the Site may violate copyright,
                  trademark and other applicable laws, and could result in
                  criminal or civil penalties. You are granted a non-exclusive,
                  non-transferable, personal, limited right to access the Site
                  and avail the services provided by BCCL on the Site.
                </li>
                <li>
                  BCCL does not make any warranties, express or implied, with
                  respect to any service, information, data, software, system,
                  functionality, statements, content or products made available
                  on the Site. The information contained in the Site has been
                  obtained from sources believed to be reliable. The Site, and
                  all content, materials, information, software and services
                  provided on the Site, are provided on an "as is" and "as
                  available" basis.{" "}
                </li>
                <li>
                  BCCL does not warrant that the Site will operate error-free or
                  that the Site and its servers are free of computer viruses or
                  other harmful mechanisms. If your use of the Site or content
                  therein results in the need for servicing or replacing
                  equipment or data, BCCL is not responsible for any such costs.
                  Site and content are provided on an "as-is" basis without any
                  warranties of any kind. To the fullest extent permitted by
                  law, BCCL disclaims all warranties, whether express or
                  implied, including the fitness for particular purpose and
                  non-infringement. BCCL makes no warranties about the accuracy,
                  reliability, completeness, or timeliness of the content,
                  services, software, text, graphics and links.
                </li>
                <li>
                  BCCL shall not be liable for any damage to User's computer
                  system or loss of data that result from the download of any
                  content, materials, and information from the Site or use of
                  any software, systems, functionality, or other services on the
                  Site. Under no circumstances will BCCL be liable in any way
                  for any content, including, but not limited to, inaccurate,
                  false, incomplete, threatening, defamatory, obscene,
                  offensive, illegal content, infringement of other’s rights
                  including intellectual property rights, for any errors or
                  omissions in any content, or for any loss or damage of any
                  kind incurred as a result of the use of any content posted or
                  uploaded. Further, under no circumstances, BCCL shall be
                  liable for any unlawful act of the User or its affiliates,
                  relatives, employees, agents including misuse of any data,
                  unfair trade practices, fraud, cybersquatting, hacking and
                  other cyber crimes. In no event shall BCCL be liable for
                  damages of any kind, including without limitation, direct,
                  incidental or consequential damages (including, but not
                  limited to, damages for lost profits, business interruption
                  and loss of programs or information) arising out of the use of
                  or inability to use Site, or any information provided thereon,
                  or any claim attributable to errors, omissions or other
                  inaccuracies in the Site.
                </li>
                <li>
                  You may not send, submit, post, or otherwise transmit,
                  material or messages that contain software virus, or any other
                  files that are designed to interrupt, destroy, and negatively
                  affect in any manner whatsoever, any electronic equipment in
                  connection with the use of the Site, or other user's ability
                  to use the Site.
                </li>
                <li>
                  The User agrees to indemnify and hold BCCL, its subsidiaries,
                  affiliates, officers and employees, harmless from any claim,
                  demand or damage, including reasonable attorney’s fees,
                  asserted by any third party due to or arising out of your use
                  of or conduct on the Site. BCCL reserves the right to disclose
                  any personal information about you and your use of the Site
                  including its contents, without your prior permission, if BCCL
                  has a good faith belief that such action is necessary to:
                </li>
                <div className="ml-2">
                  <p>
                    (a) conform to legal requirements or comply with legal
                    process;
                  </p>
                  <p>
                    (b) protect and defend the rights or property of BCCL or its
                    affiliated companies;
                  </p>
                  <p>(c) enforce the Terms of Use;</p>
                  <p>
                    (d) act to protect the interest of its members or others.
                  </p>
                </div>
                <li>
                  BCCL may, if you so choose, send direct mailers to you at the
                  address given by you. You have the option to 'opt-out' of this
                  direct mailer by way of links provided at the bottom of each
                  mailer. BCCL respect your privacy and in the event that you
                  choose to not receive such mailers, BCCL will take all steps
                  to remove you from the list.
                </li>
                <li>
                  BCCL recognizes the receipt, transmission or distribution of
                  spam emails (unsolicited bulk emails) as a major concern and
                  has taken reasonable measures to minimize the transmission and
                  effect of spam emails in its computing environment. All emails
                  received by BCCL, are subject to spam check. Any email
                  identified as spam will be rejected with sufficient
                  information to the Sender for taking necessary action. With
                  this measure, along with other technical spam reduction
                  measures, BCCL hopes to minimize the effect of spam emails.
                  BCCL reserves the right to reject and/or report any suspicious
                  spam emails, to the authorities concerned, for necessary
                  action, from time to time.
                </li>
                <li>
                  If the Site provides links to websites and access to content,
                  products and services from third parties, including users,
                  advertisers, affiliates and sponsors of the Site, you agree
                  that BCCL is not responsible for the availability of, and
                  content provided on, third party web sites or content. The
                  User is requested to peruse the policies posted by other
                  websites regarding privacy and other terms before use. BCCL is
                  not responsible for third-party content accessible through the
                  Site, including opinions, advice, statements and
                  advertisements, and the User shall bear all risks associated
                  with the use of such content. BCCL is not responsible for any
                  loss or damage of any sort which User may incur from dealing
                  with any third party.{" "}
                </li>
                <li>
                  By using any third-party content, User may leave the Site and
                  be directed to an external website, or to a website maintained
                  by an entity other than BCCL and/or its affiliates. If you
                  decide to visit any such site, you do so at your own risk and
                  it is your responsibility to take all protective measures to
                  guard against viruses or any other destructive elements. BCCL
                  makes no warranty or representation regarding, and does not
                  endorse, any linked websites or the information appearing
                  thereon or any of the products or services described thereon.
                  Links do not imply that BCCL or the Site sponsors, endorses,
                  is affiliated or associated with, or is legally authorized to
                  use any trademark, trade name, logo or copyright symbol
                  displayed in or accessible through the links, or that any
                  linked site is authorized to use any trademark, trade name,
                  logo or copyright symbol of BCCL or any of its affiliates or
                  subsidiaries. You hereby expressly acknowledge and agree that
                  the linked sites are not under the control of BCCL and BCCL is
                  not responsible for the contents of any linked site or any
                  link contained in a linked site, or any changes or updates to
                  such sites. BCCL is not responsible for webcasting or any
                  other form of transmission received from any linked site. BCCL
                  is providing these links to you only as a convenience, and the
                  inclusion of any link shall not be construed to imply
                  endorsement by BCCL in any manner of the website.
                </li>
                <li>Prohibited Activities:</li>
                <div className="ml-3" style={{ lineHeight: "18px" }}>
                  <p>
                    (a) You shall not scan, copy, capture, join, merge, collate,
                    compile and aggregate the Site content or the material
                    published in any of our print media in any electronic
                    storage friendly format (such as PDF/JPEG/RAR format or
                    otherwise) which allows piracy by unauthorized and unlawful
                    copying, downloading, storage/preservation, modification,
                    reproduction, uploading, transmission, post, circulation,
                    sharing and distribution of such content through any mobile
                    or internet connected device or otherwise in any format via
                    any communication media or social media platform etc.;
                  </p>
                  <p>
                    (b) Although the Site allows you to download content of
                    previous day’s edition in PDF format, it is strictly for
                    your personal use and is being provided at BCCL’s sole
                    discretion that may be withdrawn without any prior notice.
                    Post download, you shall not copy, modify, reproduce,
                    republish, upload, transmit, post, circulate, share or
                    distribute any such content through any mobile or internet
                    connected device or otherwise in any format via any
                    communication media or social media platform;
                  </p>
                  <p>
                    (c) In the event you discover or become aware of any
                    instance of copyright infringement of Site content or print
                    media content in the aforesaid manner, you shall forthwith
                    report the same to the designated Grievance Redressal
                    Officer identified in Clause 23 herein below.
                  </p>
                  <p>
                    (d) You shall not host, display, upload, modify, publish,
                    transmit, update or share any information on the Site, that
                    —
                  </p>
                  <br />
                  <br />
                  <p>
                    (i) belongs to another person and to which you do not have
                    any right to;
                  </p>
                  <p>
                    (ii) is grossly harmful, harassing, blasphemous, defamatory,
                    obscene, pornographic, paedophilic, libellous, invasive of
                    another's privacy, hateful, or racially, ethnically
                    objectionable, disparaging, relating or encouraging money
                    laundering or gambling, or otherwise unlawful in any manner
                    whatever;
                  </p>
                  <p>(iii) harm minors in any way;</p>
                  <p>
                    (iv) infringes any patent, trademark, copyright or other
                    proprietary rights;
                  </p>
                  <p>(v) violates any law for the time being in force;</p>
                  <p>
                    (vi) deceives or misleads the addressee about the origin of
                    such messages or communicates any information which is
                    grossly offensive or menacing in nature;
                  </p>
                  <p>(vii) impersonate another person;</p>
                  <p>
                    (viii) contains software viruses or any other computer code,
                    files or programs designed to interrupt, destroy or limit
                    the functionality of any computer resource;
                  </p>
                  <p>
                    (ix) threatens the unity, integrity, defence, security or
                    sovereignty of India, friendly relations with foreign
                    states, or public order or causes incitement to the
                    commission of any cognisable offence or prevents
                    investigation of any offence or is insulting any other
                    nation;
                  </p>
                </div>
                <li>
                  Any Content uploaded by you shall be subject to relevant laws
                  and may be disabled, or and may be subject to investigation
                  under appropriate laws. Furthermore, if you are found to be in
                  non-compliance with the laws and regulations, these terms, or
                  the Privacy Policy of the Site, we may terminate your
                  account/block your access to the Site and we reserve the right
                  to remove any non-compliant Content uploaded by you.
                </li>
                <br />
                <div className="ml-1">
                  <p>Notice of Copyright Infringement</p>
                  <br />
                  <p>
                    BCCL is not liable for any infringement of copyright arising
                    out of materials posted on or transmitted through the Site,
                    or items advertised on the Site, by end users or any other
                    third parties. In the event you have any grievance in
                    relation to any Content uploaded on the Site, you may
                    contact our Grievance Officer, at
                    infringement@timesgroup.com; or write at the following
                    address:
                  </p>
                  <address>
                    Bennett, Coleman &amp; Co. Ltd.
                    <br />
                    Times Of India Building,
                    <br />
                    D. N. Road,
                    <br />
                    Mumbai - 400 001.
                    <br />
                    Ph No. 91-22-66353535
                  </address>
                  <p>
                    We request you to please provide the following information
                    in your complaint:-
                  </p>
                  <p>
                    (a) A physical or electronic signature of a person
                    authorized to act on behalf of the copyright owner for the
                    purposes of the complaint.
                  </p>
                  <p>
                    (b) Identification of the copyrighted work claimed to have
                    been infringed.
                  </p>
                  <p>
                    (c) Identification of the material on our website that is
                    claimed to be infringing or to be the subject of infringing
                    activity.
                  </p>
                  <p>
                    (d) The address, telephone number or e-mail address of the
                    complaining party.
                  </p>
                  <p>
                    (e) A statement that the complaining party has a good-faith
                    belief that use of the material in the manner complained of
                    is not authorized by the copyright owner, its agent or the
                    law.
                  </p>
                  <p>
                    (f) A statement, under penalty of perjury, that the
                    information in the notice of copyright infringement is
                    accurate, and that the complaining party is authorized to
                    act on behalf of the owner of the right that is allegedly
                    infringed
                  </p>
                </div>
                <li>
                  Any complaints or concerns with regards to content or to
                  report any abuse of laws or breach of these terms may be taken
                  up with the designated grievance redressal officer as
                  mentioned below via post or by sending an email to
                  epaper.support@timesgroup.com or Mr. Sanio Farro ("Grievance
                  Redressal Officer") Grievance Redressal Officer{" "}
                </li>
                <li>
                  BCCL reserves the right in its sole discretion to investigate
                  and take legal action against anyone who engages in any
                  illegal or prohibited conduct or otherwise violates these
                  Terms of Use, including, without limitation, removing the User
                  content from the Site and/or terminating the offending User's
                  ability to access the Site and/or use services therein. BCCL
                  may take any other action with respect to User content or User
                  actions that it deems necessary or appropriate in its sole
                  discretion if it believes it may create liability for BCCL or
                  may cause BCCL to lose (in whole or in part) the services of
                  its ISPs or other suppliers.
                </li>
                <li>
                  The User hereby specifically agrees to comply with the
                  requirements of the Information Technology Act, 2000 as also
                  rules, regulations, guidelines, bye laws and notifications
                  made thereunder, while on the Site. The User also undertakes
                  that the content provided by him/her is not defamatory,
                  obscene, threatening, abusive or hateful. The User shall alone
                  be responsible for all his acts, deeds and things and that he
                  alone shall be liable for civil and criminal liability there
                  under or under any other law for the time being in force.
                </li>
                <li>
                  No failure or delay by BCCL in exercising any right, power or
                  privilege under the Terms of Use will operate as a waiver of
                  it, nor will any single or partial exercise of it preclude any
                  further exercise or the exercise of any right, power or
                  privilege under the Terms of Use.
                </li>
                <li>
                  The Site is controlled, operated and administered by BCCL from
                  its offices within India. BCCL makes no representation that
                  materials on this Site are appropriate or available for use at
                  any other location(s) outside India. Any access to this site
                  from territories where their contents are illegal is
                  prohibited. You may not use the site or export the materials
                  in violation of any applicable export laws and regulations. If
                  you access this site from a location outside India, you are
                  responsible for compliance with all local laws.
                </li>
                <li>
                  The Terms of Use shall be governed by the laws of India. The
                  courts of law at Mumbai shall have exclusive jurisdiction over
                  any disputes arising under the Terms of Use.
                </li>
                <li>
                  The Terms of Use contain the entire understanding and
                  supersedes all prior understandings between the parties
                  relating to the subject matter herein and the Terms of Use can
                  be changed by BCCL at any point in time in the manner as
                  provided in this Terms of Use.
                </li>
                <li>
                  If any portion of any of the Terms of Use is held invalid or
                  unenforceable, that portion will be construed consistent with
                  applicable laws as nearly as possible, and the remaining
                  portions will remain in full force and effect.
                </li>
                <li>
                  BCCL shall be under no liability whatsoever in the event of
                  non-availability of any publication opted by you occasioned by
                  act of God, war, disease, revolution, riot, civil commotion,
                  strike, lockout, flood, fire, failure of any public utility,
                  man-made disaster, pandemic, epidemic, infrastructure failure
                  or any other cause whatsoever beyond the control of BCCL
                  and/or other publishers whose publication is offered by BCCL.
                  In the event, the Force Majeure conditions continue beyond a
                  period of 30 days, the subscriber/you may reach out to the
                  customer care for pro-rata refund of the subscription fee paid
                  by you. Upon such request BCCL shall within 30 days refund the
                  subscription fee on pro-rata basis for the balance
                  subscription term. For avoidance of doubt, BCCL will not be
                  responsible, nor will you be entitled for any refunds of
                  subscription fees, for disruption, unavailability of the
                  publication opted by you during the aforestated period of 30
                  days and/or if no refund is claimed by you after the expiry 60
                  days, the refund shall be made from the date of the claim.
                </li>
              </ol>
            </div>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}

export default TermsAndConditionsPage;
