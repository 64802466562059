import { lineHeight } from "@mui/system";
import React, { Fragment } from "react";
import { Container, Card, CardBody, Table, Row, Col } from "reactstrap";
import "./Advertise.css";
import TopHeader from "./TopHeader";
function AdvertisePage() {
  return (
    <Fragment>
      <TopHeader />
      <Container>
        <Card
          style={{
            marginTop:
              window.innerWidth > 1132
                ? "110px"
                : localStorage.getItem("contentRec") === "false" &&
                  window.innerWidth < 1133
                ? "60px"
                : localStorage.getItem("contentRec") === "true" &&
                  window.innerWidth < 701
                ? "60px"
                : window.innerWidth < 701
                ? "60px"
                : "110px",
          }}
        >
          <CardBody className="text-muted">
            <div className="d-flex justify-content-center align-items-center border-bottom p-2">
              <h2>Advertise with us</h2>
            </div>
            <h5 className="mt-3">Welcome to e-paper Advertising Solutions.</h5>
            <p>
              We offer our clients the following advertising solutions. Here you
              would find details and specifications on all ad units present on
              the e-Paper site.
            </p>
            <Row>
              <Col md="4">
                <Table className="text-white">
                  <thead style={{ background: "gray", lineHeight: "8px" }}>
                    <th>Position</th>
                    <th>Dimensions</th>
                  </thead>
                  <tbody style={{ background: "lightGray", lineHeight: "8px" }}>
                    <tr>
                      <td>Top Horizontal</td>
                      <td>728 X 90</td>
                    </tr>
                    <tr>
                      <td>1st Right</td>
                      <td>300 X 250</td>
                    </tr>
                    <tr>
                      <td>2nd Right</td>
                      <td>300 x 250</td>
                    </tr>
                    <tr>
                      <td>3rd Right</td>
                      <td>300 X 250</td>
                    </tr>
                    <tr>
                      <td>Inner Panel</td>
                      <td>300 X 250</td>
                    </tr>
                    <tr>
                      <td>Inner Bottom Horizontal</td>
                      <td>728 X 90</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <p className="m-0">
                  Please <b>contact our sales team</b> or your account manager
                  for any details.
                </p>
                <p className="m-0">
                  email:{" "}
                  <a href="#" className="email__address">
                    Kiran.Kanchana@timesgroup.com
                  </a>{" "}
                </p>
                <p className="m-0">
                  email:{" "}
                  <a href="#" className="email__address">
                    Rajarshi.Sen@timesgroup.com
                  </a>{" "}
                </p>
                <b>Mechanical Details</b>
                <p>
                  Image may be either a GIF, PNG or JPG web viewable format.
                </p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
}

export default AdvertisePage;
