import React, { useEffect, useState } from "react";
const Message = (props) => {
  return (
    <>
      <div
        className={`${props.isVisible ? "visible" : "invisible"}`}
        style={{
          opacity: props?.opacity,
          zIndex: 9999,
          position: "fixed",
          top: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            background: "#e7a33d",
            padding: "6px 10px",
            display: "inline-block",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            fontSize: "16px",
          }}
        >
          {props?.message}
        </p>
      </div>
    </>
  );
};
export default Message;
