import React, { useState, useContext, useEffect } from "react";
import TopHeader from "./TopHeader";
import TopBar from "./TopBar";
import BottomBar from "./BottomBar";
import PopularRightDrawer from "./PopularRightDrawer";
import Gallery from "./Gallery";
import CookiesChoice from "./CookiesChoice";

export default function ReplicaView(props) {
  return (
    <div>
      {localStorage.getItem("continentCode") === "EU" &&
        (!localStorage.getItem("webAnalytics") ||
          !localStorage.getItem("contentRec")) && <CookiesChoice {...props} />}
      <TopHeader {...props} />
      <TopBar {...props} />
      <PopularRightDrawer {...props} />
      <Gallery {...props} />
      <BottomBar {...props} />
    </div>
  );
}
